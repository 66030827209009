import { observable, computed, action } from 'mobx';

export abstract class LoadingStoreBase {
	@observable.ref loadingKeys: string[] = [];

	@computed
	get isLoading(): boolean {
		return this.loadingKeys.length > 0;
	}

	@action startLoading(key: string) {
		this.loadingKeys = [...this.loadingKeys, key];
	}

	@action stopLoading(key: string) {
		const lastIndex = this.loadingKeys.lastIndexOf(key);
		this.loadingKeys = this.loadingKeys.filter((key, index) => index !== lastIndex);
	}
}