import { computed, action } from 'mobx';
import React from 'react';
import { StoreBase } from '../../../common/StoreBase';
import { IClearSearchAndFiltersConfiguration } from '../models';

export class ClearSearchAndFiltersStore extends StoreBase {
	static componentKey: 'clearSearchAndFilters' = 'clearSearchAndFilters';

	@computed
	get configuration(): IClearSearchAndFiltersConfiguration | undefined {
		if (this.storeContext && this.storeContext.appStore) {
			return this.storeContext.appStore.getComponentConfiguration(ClearSearchAndFiltersStore.componentKey);
		}
	}

	@computed
	get show(): boolean {
		return (this.configuration && this.configuration.show) || false;
	}

	@computed
	get buttonText(): string {
		return (this.configuration && this.configuration.buttonText) || 'Clear Search and Filters';
	}

	clearSearchAndFilters = () => {
		const { filtersStore, identifierSearchStore, facetedSearchStore } = this.storeContext;
		filtersStore.handleClearFilters();
		identifierSearchStore.clear();
		facetedSearchStore.clear();
	};

	@action
	async initialize(): Promise<void> {}
}
