import React, { Component } from 'react';
import {
	IBulkDownloadProps,
	IBulkDownloadState,
} from './models';
import { inject, observer } from 'mobx-react';
import { InjectedStyledComponent } from '../shared/InjectedStyledComponent';
import Wrapper from './styledComponents/Wrapper';
import Button from './styledComponents/Button';
import Badge from './styledComponents/Badge';
import Translate from '@kurtosys/ksys-app-components/dist/components/base/Translate';

@inject('appStore', 'bulkDownloadStore')
@observer
export class BulkDownload extends Component<IBulkDownloadProps, IBulkDownloadState> {
	static configurationKey: 'bulkDownload' = 'bulkDownload';
	static styleKey: 'bulkDownload' = 'bulkDownload';
	constructor(props: IBulkDownloadProps) {
		super(props);
	}
	render() {
		const { className, bulkDownloadStore } = this.props;

		if (!bulkDownloadStore || bulkDownloadStore.hide) {
			return null;
		}

		const { saveDocumentZip, buttonText, disabled, selectedDocumentCount, showBadge, badgeAlign } = bulkDownloadStore;

		return (
			<Wrapper className={ className }>
				<Button onClick={ saveDocumentZip } disabled={ disabled } suppressAnalytics={ true }>
					{ showBadge && badgeAlign === 'left' && <Badge>{ selectedDocumentCount }</Badge> }
					<Translate>{ buttonText }</Translate>
					{ showBadge && badgeAlign === 'right' && <Badge>{ selectedDocumentCount }</Badge> }
				</Button>
			</Wrapper>
		);
	}
}

export default InjectedStyledComponent(BulkDownload, 'bulkDownload');

