import { computed, action, observable, isArrayLike } from 'mobx';
import { StoreBase } from '../../../common/StoreBase';
import {
	TFacetGrouping,
	IDocumentSearchClause,
	ITransformOptions,
	IDocumentSearchRequest,
	IFormat,
	TDocumentGetFacetsRequest,
} from '../../../models/commonTypes';
import { IFacetedSearchConfiguration, ISearchDocumentsLimits } from '../models';
import { ISearchProps } from '@kurtosys/ksys-app-components/dist/components/base/Search/models/ISearchProps';
import { ISearchResult, TSearchPosition } from '@kurtosys/ksys-app-components/dist/components/base/Search/models';
import { utils, query } from '@kurtosys/ksys-app-template';
import { QueryString } from '../../../utils/QueryString';
import { IQueryStringObject } from '../../../models/app/IQueryStringObject';
import { IQueryStringOptions } from '../../../models/app/IQueryStringOptions';
import { IFacetedSearchDocumentRequest } from '../../../models/app/IFacetedSearchDocumentRequest';

export class FacetedSearchStore extends StoreBase {
	static componentKey: 'facetedSearch' = 'facetedSearch';

	@observable.ref hasLoadedQueryString = false;
	@observable facets: TFacetGrouping[] = [];
	@observable facetSearchDictionary: { [key: string]: IDocumentSearchClause } = {};
	@observable searchTerms: IDocumentSearchClause[] = [];
	@observable selectedResults: ISearchResult[] = [];

	@computed
	get configuration(): IFacetedSearchConfiguration | undefined {
		if (this.storeContext && this.storeContext.appStore) {
			return this.storeContext.appStore.getComponentConfiguration(FacetedSearchStore.componentKey);
		}
	}

	@computed
	get hide(): boolean {
		return !!(this.configuration && this.configuration.hide);
	}

	@computed
	get allowedFields(): string[] {
		const allowedFields = (this.configuration && this.configuration.allowedFields) || [];
		if (allowedFields.length > 0) {
			const { dateSearchStore } = this.storeContext;
			const dateFieldItems =
				dateSearchStore.dateRangeInputProps && dateSearchStore.dateRangeInputProps.dateFieldItems;
			if (dateFieldItems) {
				allowedFields.push(...dateFieldItems.map((d) => d.value));
			}
		}
		return allowedFields;
	}

	@computed
	get skipTranslations(): boolean {
		if (this.configuration) {
			const { translationStore } = this.storeContext;
			const { skipTranslations = false, skipTranslationsOnCultures = [] } = this.configuration;
			return skipTranslations || skipTranslationsOnCultures.includes(translationStore.culture);
		}
		return false;
	}

	@action
	async initialize(): Promise<void> {
		this.queryString = new QueryString(this.queryStringOptions, {
			enabled: false,
			prefix: 'facet_',
		});
		this.setInitialFacets();
	}

	@computed
	get queryStringOptions(): IQueryStringOptions {
		return (this.configuration && this.configuration.queryString) || {};
	}

	@action
	setInitialFacets = () => {
		if (
			this.allowedFields &&
			this.allowedFields.length > 0 &&
			!this.hasLoadedQueryString &&
			this.queryString &&
			this.queryString.enabled
		) {
			const facetsQueryStringValues = this.queryString.fetch(true);

			if (facetsQueryStringValues && Object.keys(facetsQueryStringValues).length > 0) {
				const searchRequest: IFacetedSearchDocumentRequest = {
					search: [],
				};
				const specialPropertiesMapping: TSpecialPermissionMapping = {
					filename: 'filenames',
					cultureCode: 'cultureCodes',
					title: 'titles',
				};
				for (const key of Object.keys(facetsQueryStringValues)) {
					let values = facetsQueryStringValues[key];
					const searchRequestKey = specialPropertiesMapping[key];
					if (searchRequestKey) {
						if (!isArrayLike(values)) {
							values = [values];
						}
						searchRequest[searchRequestKey] = values;
					} else {
						if (typeof values === 'string' && values.includes(',')) {
							values = values.split(',');
						}
						if (!isArrayLike(values)) {
							values = [values];
						}
						if (['created', 'lastModified'].includes(key)) {
							searchRequest.search.push({
								matchtype: 'RANGE',
								property: key,
								values,
								meta: false,
							});
						} else {
							searchRequest.search.push({
								matchtype: 'MATCH',
								property: key,
								values,
							});
						}
					}
				}
				this.hasLoadedQueryString = true;
				this.loadSelectedResults(searchRequest);
			}
		}
	}

	validateFacet(facet: TFacetGrouping) {
		const { appStore } = this.storeContext;
		if (this.allowedFields.length > 0 && !facet.propertyValues.some((p) => this.allowedFields.includes(p.code))) {
			return false;
		}
		facet.propertyValues = facet.propertyValues.filter((propValue) => {
			const { code, propertyValue } = propValue;
			return !appStore.configSearchFacets[code] || appStore.configSearchFacets[code].includes(propertyValue);
		});
		return facet.propertyValues.length > 0;
	}

	@computed get searchDocumentsLimits(): Required<ISearchDocumentsLimits> {
		const defaults: Required<ISearchDocumentsLimits> = {
			excludeConfig: false,
			excludeFacetedSearch: true,
			excludeFilters: false,
			forceIncludeDateFilters: true,
		};
		return { ...defaults, ...(this.configuration && this.configuration.searchDocumentsLimits) };
	}

	@computed
	get searchDocumentsBody(): IDocumentSearchRequest | undefined {
		const { appStore, dateSearchStore } = this.storeContext;
		const documentSearch = appStore.searchDocumentsBody;
		delete documentSearch.start;
		delete documentSearch.limit;
		delete documentSearch.sort;
		delete documentSearch.identifierSearches;
		documentSearch.search = appStore.getSearch(
			this.searchDocumentsLimits.excludeConfig,
			this.searchDocumentsLimits.excludeFacetedSearch,
			this.searchDocumentsLimits.excludeFilters,
			this.searchDocumentsLimits.forceIncludeDateFilters,
		);
		if (dateSearchStore.searchTerm) {
			documentSearch.search.push(dateSearchStore.searchTerm);
		}
		if (!documentSearch || !documentSearch.search || documentSearch.search.length === 0) {
			return undefined;
		}
		return documentSearch;
	}

	@action async getFacets(searchTerm: string) {
		const { kurtosysApiStore, translationStore } = this.storeContext;
		const documentSearch = this.searchDocumentsBody;

		const body: TDocumentGetFacetsRequest = {
			searchTerm,
		};

		if (documentSearch) {
			body.documentSearch = documentSearch;
		}
		if (!this.skipTranslations) {
			body.translation = {
				sourceCulture: translationStore.baseCulture,
				culture: translationStore.culture,
			};
		}

		const facets = await kurtosysApiStore.getFacets.execute({ body });
		this.facets = facets.filter((facet) => this.validateFacet(facet));
	}

	@computed
	get hasSelectedResults(): boolean {
		return this.selectedResults !== null && this.selectedResults.length > 0;
	}

	getFormattedProperty(property: string) {
		const mappings: { source: string; target: string }[] = [
			{
				source: 'cultureCode',
				target: 'culture',
			},
		];
		for (const mapping of mappings) {
			if (property === mapping.source) {
				property = mapping.target;
				break;
			}
		}
		const caseTransform: ITransformOptions = {
			transformOptionsType: 'case',
			caseType: 'title',
		};
		const transform = new query.transform.Transform(
			caseTransform,
			this.storeContext.translationStore.translationHelper,
		);
		return transform.execute(property, { queryOptionsType: 'response' }, {});
	}

	@computed
	get dateFormat(): IFormat {
		return (
			(this.configuration && this.configuration.dateFormat) || {
				type: 'date',
				options: {
					dateFormatString: 'DD MMM YYYY',
				},
			}
		);
	}

	getFormattedDate(date: string) {
		const caseTransform: ITransformOptions = {
			transformOptionsType: 'format',
			formatTransformOptions: {
				format: this.dateFormat,
			},
		};
		const transform = new query.transform.Transform(
			caseTransform,
			this.storeContext.translationStore.translationHelper,
		);
		return transform.execute(date, { queryOptionsType: 'response' }, {});
	}

	cleanSearchRequest(searchRequest: IFacetedSearchDocumentRequest) {
		if (this.allowedFields && this.allowedFields.length > 0) {
			searchRequest.search = searchRequest.search.filter((s) => this.allowedFields.includes(s.property));
		}
	}

	@action
	loadTerms = (collection: string[] | undefined, idPrefix: string, property: string) => {
		const results: IDocumentSearchClause[] = [];
		if (collection && collection.length > 0) {
			collection.forEach((value: string) => {
				const id = `${ idPrefix }_${ value }`;
				const searchTerm: IDocumentSearchClause = {
					property,
					values: [value],
					matchtype: 'MATCH',
				};
				this.facetSearchDictionary[id] = searchTerm;
				results.push(searchTerm);
			});
		}
		return results;
	}

	@action loadSelectedResults = async (searchRequest: IFacetedSearchDocumentRequest) => {
		this.cleanSearchRequest(searchRequest);
		const loadingKey = 'loadSelectedResults';
		this.startLoading(loadingKey);
		const { appStore, pagingStore, filtersStore } = this.storeContext;
		const selectedResults: ISearchResult[] = [];
		const { search, filenames = [], titles = [], cultureCodes = [] } = searchRequest;
		const searchValues = [...search];
		searchValues.push(...this.loadTerms(filenames, 'filename', 'filename'));
		searchValues.push(...this.loadTerms(titles, 'title', 'title'));
		searchValues.push(...this.loadTerms(cultureCodes, 'cultureCode', 'cultureCode'));

		searchValues.forEach((searchTerm: IDocumentSearchClause) => {
			const { property, matchtype, values } = searchTerm;
			if (matchtype === 'RANGE' && values.length === 2) {
				const value = `${ this.getFormattedDate(values[0]) } - ${ this.getFormattedDate(values[1]) }`;
				const id = property;
				this.facetSearchDictionary[id] = searchTerm;
				selectedResults.push({
					id,
					value,
					group: this.getFormattedProperty(property),
				});
			} else {
				const results = values.map((searchValue) => {
					const id = `${ property }_${ searchValue }`;
					this.facetSearchDictionary[id] = {
						...searchTerm,
						values: [searchValue],
					};
					return {
						id,
						value: searchValue,
						group: this.getFormattedProperty(property),
					};
				});
				selectedResults.push(...results);
			}
		});
		this.selectedResults = selectedResults;
		this.searchTerms = searchValues;
		await filtersStore.loadSelectedResults(searchRequest);
		await this.onFacetsChange();
		this.stopLoading(loadingKey);
	}

	@action
	addDateSearchTerm(searchTerm: IDocumentSearchClause) {
		const { property, matchtype, values } = searchTerm;
		if (matchtype === 'RANGE' && values.length === 2) {
			const value = `${ this.getFormattedDate(values[0]) } - ${ this.getFormattedDate(values[1]) }`;
			const id = property;
			this.facetSearchDictionary[id] = searchTerm;
			this.selectedResults = this.selectedResults.filter((result) => result.id !== id);
			this.selectedResults.push({
				id,
				value,
				group: this.getFormattedProperty(property),
			});
			this.searchTerms = this.searchTerms.filter((term) => term.property !== searchTerm.property);
			this.searchTerms.push(searchTerm);
			this.onFacetsChange();
		}
	}

	clear = (): void => {
		this.onSelectedResultsChange([]);
	}

	@action
	onSelectedResultsChange = async (items: ISearchResult[]) => {
		const { appStore, pagingStore, tableStore, filtersStore } = this.storeContext;
		const loadingKey = 'updateSearchTerm';
		this.startLoading(loadingKey);
		const searchTerms: IDocumentSearchClause[] = [];
		items.forEach((item: ISearchResult) => {
			const searchTermFromDictionary = this.facetSearchDictionary[item.id];
			if (searchTermFromDictionary) {
				const searchTerm = JSON.parse(JSON.stringify(searchTermFromDictionary));
				const existingSearchTerm = searchTerms.find((term) => term.property === searchTerm.property);
				if (existingSearchTerm) {
					existingSearchTerm.values.push(item.value);
				} else {
					searchTerms.push(searchTerm);
				}
			}
		});
		this.searchTerms = searchTerms;
		this.selectedResults = items;

		tableStore.clearSelectedFields();
		await this.onFacetsChange();
		this.stopLoading(loadingKey);
	}

	@action
	onFacetsChange = async () => {
		const { appStore, filtersStore, pagingStore } = this.storeContext;
		pagingStore.resetToFirstPage();
		this.updateQueryString();
		const promises = [appStore.fetchDocuments(), filtersStore.getFilters()];
		await Promise.all(promises);
		// Log the updated search terms
		if (appStore.analyticsHelper) {
			const terms: IQueryStringObject = {};
			if (this.queryString && this.queryString.enabled) {
				const prefix = this.queryString.prefix;
				const current: IQueryStringObject = utils.url.getQueryStringAsObject(this.queryString.raw);
				for (const key in current) {
					if (key.startsWith(prefix)) {
						terms[key.substring(prefix.length)] = current[key];
					}
				}
			}
			appStore.analyticsHelper.logEvent({
				event: 'search',
				eventType: 'click',
				context: {
					searchTerms: terms,
				},
			});
		}
	}

	sleep = async (interval: number) => {
		return new Promise((resolve, reject) => {
			setTimeout(resolve, interval);
		});
	}

	@computed
	get items(): ISearchResult[] {
		const items: ISearchResult[] = [];
		if (this.facets) {
			this.facets.forEach((facet: TFacetGrouping) => {
				const { property, propertyValues, originalProperty, originalPropertyValues } = facet as any;
				const facetItems = (originalPropertyValues || propertyValues)
					.filter((propValue: any) => !utils.typeChecks.isNullOrEmpty(propValue.propertyValue))
					.map((propValue: any) => {
						const id = `${ propValue.property }_${ propValue.propertyValue }`;
						this.facetSearchDictionary[id] = {
							values: [propValue.originalPropertyValue || propValue.propertyValue],
							meta: propValue.facetType === 'META',
							property: propValue.code,
							matchtype: 'MATCH',
						};
						return {
							id,
							value: propValue.originalPropertyValue || propValue.propertyValue,
							group: property,
						};
					});
				items.push(...facetItems);
			});
		}
		return items;
	}

	searchItems = async (term: string) => {
		await this.getFacets(term);
		await this.sleep(300);
		const response: any = {
			term,
			records: this.items,
		};
		return response;
	}

	get defaultSearchProps(): ISearchProps {
		const { appStore } = this.storeContext;
		return {
			placeholder: 'Search for documents...',
			iconPosition: 'left' as TSearchPosition,
			minCharacterTrigger: 2,
			onChangeMillisecondTimeout: 600,
			groupSearchResults: true,
			showSelectedResults: true,
			ignoreAcceptanceKeys: true,
			pillProps: {
				pillPosition: 'right',
				value: `{total} documents`,
			},
		};
	}

	@computed
	get searchProps(): ISearchProps {
		const { appStore, translationStore } = this.storeContext;
		const searchProps = this.configuration && this.configuration.searchProps;
		const props: ISearchProps = { ...this.defaultSearchProps, ...(searchProps || {}) };
		props.searchCallback = this.searchItems;
		props.onSelectedResultsChange = this.onSelectedResultsChange;
		props.selectedResults = this.selectedResults;
		if (props.pillProps && props.pillProps.value) {
			props.pillProps.value = translationStore.translate(props.pillProps.value, { total: appStore.total });
		}
		return props;
	}

	queryString: QueryString | undefined;

	@action
	updateQueryString = () => {
		if (this.queryString && this.queryString.enabled) {
			const filtersQueryStringObject: IQueryStringObject = this.searchTerms.reduce(
				(acc: IQueryStringObject, searchTerm: IDocumentSearchClause) => {
					const { property, values } = searchTerm;
					if (!acc[property]) {
						acc[property] = [];
					}
					if (Array.isArray(acc[property])) {
						(acc[property] as string[]).push(...values);
					}
					return acc;
				},
				{},
			);
			this.queryString.update(filtersQueryStringObject);
		}
	}
}

type TSpecialPermissionMapping = {
	[key: string]: 'filenames' | 'titles' | 'cultureCodes';
};
