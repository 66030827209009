import React, { Component } from 'react';
import { IClearSearchAndFiltersProps, IClearSearchAndFiltersState } from './models';
import { inject, observer } from 'mobx-react';
import { InjectedStyledComponent } from '../shared/InjectedStyledComponent';
import Wrapper from './styledComponents/Wrapper';
import Button from './styledComponents/Button';

@inject('appStore', 'clearSearchAndFiltersStore')
@observer
export class ClearSearchAndFilters extends Component<IClearSearchAndFiltersProps, IClearSearchAndFiltersState> {
	static configurationKey: 'clearSearchAndFilters' = 'clearSearchAndFilters';
	static styleKey: 'clearSearchAndFilters' = 'clearSearchAndFilters';
	constructor(props: IClearSearchAndFiltersProps) {
		super(props);
	}
	render() {
		const { className, clearSearchAndFiltersStore } = this.props;

		if (!clearSearchAndFiltersStore) {
			return null;
		}

		const { clearSearchAndFilters, buttonText } = clearSearchAndFiltersStore;

		return (
			<Wrapper className={className}>
				<Button onClick={ clearSearchAndFilters } suppressAnalytics={ true }>{buttonText}</Button>
			</Wrapper>
		);
	}
}

export default InjectedStyledComponent(ClearSearchAndFilters, 'clearSearchAndFilters');
