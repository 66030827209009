import React, { Component, Fragment } from 'react';
import {
	IDateSearchProps,
	IDateSearchState,
} from './models';
import { inject, observer } from 'mobx-react';
import { InjectedStyledComponent } from '../shared/InjectedStyledComponent';
import Wrapper from './styledComponents/Wrapper';
import Window from './styledComponents/Window';
import ClickLayer from './styledComponents/ClickLayer';
import Button from './styledComponents/Button';
import DateRangeInput from '@kurtosys/ksys-app-components/dist/components/base/DateRangeInput';

@inject('appStore', 'dateSearchStore')
@observer
export class DateSearch extends Component<IDateSearchProps, IDateSearchState> {
	static configurationKey: 'dateSearch' = 'dateSearch';
	static styleKey: 'dateSearch' = 'dateSearch';
	constructor(props: IDateSearchProps) {
		super(props);
	}

	render() {
		const { className, dateSearchStore } = this.props;

		if (!dateSearchStore || dateSearchStore.hide) {
			return null;
		}

		const { dateRangeInputProps, showWindow, toggleWindow, iconProps } = dateSearchStore;

		return (
			<Wrapper className={ className }>
				<Button iconProps={ iconProps } onClick={ () => toggleWindow() } suppressAnalytics={ true }></Button>
				{
					showWindow && (
						<Fragment>
							<ClickLayer onClick={ () => toggleWindow(false) } />
							<Window>
								<DateRangeInput { ...dateRangeInputProps } />
							</Window>
						</Fragment>
					)
				}
			</Wrapper>
		);
	}
}

export default InjectedStyledComponent(DateSearch, 'dateSearch');

