import { computed, action, observable } from 'mobx';
import { StoreBase } from '../../../common/StoreBase';
import { IDocument } from '../../../models/commonTypes';
import { IDocumentPreviewConfiguration } from '../models';
import { DocumentPreviewType } from '../models/DocumentPreviewType';
import { IDocumentPreviewOptions } from '../models/IDocumentPreviewOptions';
import { NewTabUrlSource } from '../models/NewTabUrlSource';

export class DocumentPreviewStore extends StoreBase {
	static componentKey: 'documentPreview' = 'documentPreview';

	@observable selectedDocument: IDocument | undefined;
	@observable documentUrl: string | null = null;

	@observable.ref isOpen: boolean = false;

	@computed
	get configuration(): IDocumentPreviewConfiguration | undefined {
		if (this.storeContext && this.storeContext.appStore) {
			return this.storeContext.appStore.getComponentConfiguration(DocumentPreviewStore.componentKey);
		}
	}

	@action
	async initialize(): Promise<void> {

	}

	@computed
	get isPreviewable() {
		return this.selectedDocument && (this.selectedDocument.fileType === 'application/pdf' || /.pdf$/i.test(this.selectedDocument.URL || ''));
	}

	@computed
	get noPreviewMessage(): string {
		return (this.configuration && this.configuration.noPreviewMessage) || 'Preview is only available for PDF documents.';
	}

	@computed
	get type(): DocumentPreviewType {
		return (this.configuration && this.configuration.type) || DocumentPreviewType.modal;
	}

	@computed
	get options(): IDocumentPreviewOptions {
		return (this.configuration && this.configuration.options) || {
			newTab: {
				urlSource: NewTabUrlSource.copyUrl,
			},
		};
	}


	@action
	setDocumentPreview(documentId: number, row: any) {
		const { appStore } = this.storeContext;
		this.documentUrl = null;
		if (this.type === DocumentPreviewType.modal) {
			this.selectedDocument = appStore.documents.find(document => document.documentId === documentId);
			if (this.selectedDocument && this.selectedDocument.clientHosted) {
				this.documentUrl = this.selectedDocument.URL;
			}
			else {
				this.fetchInlineDocument();
			}
			this.isOpen = true;
		}
		if (this.type === DocumentPreviewType.newTab) {
			const href = this.getNewTabHref(row);
			Object.assign(document.createElement('a'), {
				href,
				target: '_blank',
			}).click();
		}
		if (this.isPreviewable && appStore && appStore.analyticsHelper) {
			this.logPreviewEvent(row);
		}
	}

	/**
	 * Create an analytics log entry to record the fact that a document has been previewed.
	 * NB this is only called (above) if appStore.analyticsHelper is defined
	 * @param row a row of the table corresponding to the document being saved
	 */
	logPreviewEvent = (row: any): void => {
		const { appStore, tableStore } = this.storeContext;
		let url;
		if (tableStore.copyUrlQuery) {
			url = tableStore.getQueryValue(tableStore.copyUrlQuery, row, {
				context: {
					response: row,
					document: row.document,
				},
			});
		}
		appStore.analyticsHelper.logEvent({
			event: 'document_preview',
			eventType: 'click',
			context: {
				document: {
					documentURL: url,
					documentType: row.documentType,
					documentTitle: row.title,
				},
			},
		});
	}


	getNewTabHref = (row: any): string | undefined => {
		if (this.options.newTab) {
			const { urlSource: source, query: explicitQuery, queryStringAdditions = [] } = this.options.newTab;
			const query = source === NewTabUrlSource.copyUrl ? this.storeContext.tableStore.copyUrlQuery : explicitQuery;
			if (query) {
				let url = this.getQueryValue(query, row, {
					context: {
						response: row,
						document: row.document,
					},
				});
				if (typeof url === 'string') {
					const queryStringPieces: string[] = [];
					for (let i = 0; i < queryStringAdditions.length; i++) {
						const queryStringAddition = queryStringAdditions[i];
						const { key, value } = queryStringAddition;

						if (typeof value === 'string') {
							queryStringPieces.push(`${ key }=${ encodeURIComponent(value) }`);
						}
					}
					if (queryStringPieces.length > 0) {
						const separator = !url.includes('?') ? '?' : '&';
						url += `${ separator }${ queryStringPieces.join('&') }`;
					}
					return url;
				}
			}
		}
		return undefined;
	}

	@action
	clearDocumentPreview = () => {
		this.isOpen = false;
		this.selectedDocument = undefined;
	}

	@action
	async fetchInlineDocument() {
		const { kurtosysApiStore } = this.storeContext;
		if (this.selectedDocument && !this.selectedDocument.clientHosted) {
			const inlineDocument = await kurtosysApiStore.retrieveDocument.execute({
				body: {
					clientCode: this.selectedDocument.clientCode || '',
					inline: true,
				},
			});
			this.documentUrl = URL.createObjectURL(inlineDocument as Blob | MediaSource);
		}
	}
}