import React, { Component } from 'react';
import {
	IFacetedSearchProps,
	IFacetedSearchState,
} from './models';
import { inject, observer } from 'mobx-react';
import { InjectedStyledComponent } from '../shared/InjectedStyledComponent';
import Wrapper from './styledComponents/Wrapper';
import Search from '@kurtosys/ksys-app-components/dist/components/base/Search';

@inject('appStore', 'facetedSearchStore')
@observer
export class FacetedSearch extends Component<IFacetedSearchProps, IFacetedSearchState> {
	static configurationKey: 'facetedSearch' = 'facetedSearch';
	static styleKey: 'facetedSearch' = 'facetedSearch';
	constructor(props: IFacetedSearchProps) {
		super(props);
	}
	render() {
		const { className, facetedSearchStore } = this.props;

		if (!facetedSearchStore || facetedSearchStore.hide) {
			return null;
		}

		const { searchProps } = facetedSearchStore;

		return (
			<Wrapper className={ className }>
				<Search { ...searchProps } />
			</Wrapper>
		);
	}
}

export default InjectedStyledComponent(FacetedSearch, 'facetedSearch');

