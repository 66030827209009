import React, { Component } from 'react';
import { IBulkUrlCopyProps, IBulkUrlCopyState } from './models';
import { inject, observer } from 'mobx-react';
import { InjectedStyledComponent } from '../shared/InjectedStyledComponent';

import Translate from '@kurtosys/ksys-app-components/dist/components/base/Translate';
import Wrapper from './styledComponents/Wrapper';
import Badge from './styledComponents/Badge';
import Button from './styledComponents/Button';

@inject('appStore', 'bulkUrlCopyStore')
@observer
export class BulkUrlCopy extends Component<IBulkUrlCopyProps, IBulkUrlCopyState> {
	static configurationKey: 'bulkUrlCopy' = 'bulkUrlCopy';
	static styleKey: 'bulkUrlCopy' = 'bulkUrlCopy';
	constructor(props: IBulkUrlCopyProps) {
		super(props);
	}
	render() {
		const { className, bulkUrlCopyStore } = this.props;

		if (!bulkUrlCopyStore) {
			return null;
		}

		const { disabled, buttonText, showBadge, badgeAlign, selectedDocumentCount, copyUrlsToClipboard } =
			bulkUrlCopyStore;
		// We handle analytics in the copyUrlsToClipboard method, so we don't want the button to log anything
		return (
			<Wrapper className={className}>
				<Button onClick={copyUrlsToClipboard} disabled={disabled} suppressAnalytics={true}>
					{showBadge && badgeAlign === 'left' && <Badge>{selectedDocumentCount}</Badge>}
					<Translate>{buttonText}</Translate>
					{showBadge && badgeAlign === 'right' && <Badge>{selectedDocumentCount}</Badge>}
				</Button>
			</Wrapper>
		);
	}
}

export default InjectedStyledComponent(BulkUrlCopy, 'bulkUrlCopy');
