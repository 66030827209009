import { computed, action, observable } from 'mobx';
import { StoreBase } from '../../../common/StoreBase';
import { IPagingConfiguration } from '../models';
import { IPagingProps } from '@kurtosys/ksys-app-components/dist/components/base/Paging/models';
import { utils } from '@kurtosys/ksys-app-template';

export class PagingStore extends StoreBase {
	static componentKey: 'paging' = 'paging';

	@computed
	get configuration(): IPagingConfiguration | undefined {
		if (this.storeContext && this.storeContext.appStore) {
			return this.storeContext.appStore.getComponentConfiguration(PagingStore.componentKey);
		}
	}

	@action
	async initialize(): Promise<void> {
		this.limitValue = this.initialPageSize;
	}

	@computed
	get initialPageSize(): number {
		return (this.configuration && this.configuration.initialPageSize) || 10;
	}

	@computed
	get limit(): number {
		return this.limitValue;
	}

	set limit(value: number) {
		if (this.limitValue !== value) {
			this.limitValue = value;
			this.resetToFirstPage(false);
			this.fetchDocuments();
		}
	}

	@observable
	private limitValue: number = 10;

	@computed
	get start(): number {
		return this.startValue;
	}

	set start(value: number) {
		if (this.startValue !== value) {
			this.startValue = value;
			this.fetchDocuments();
		}
	}

	@observable
	private startValue: number = 0;

	@action
	resetToFirstPage = (mustFetchDocuments: boolean = true) => {
		if (mustFetchDocuments) {
			this.start = 0;
		} else {
			this.startValue = 0;
		}
	};

	@action
	fetchDocuments = () => {
		const { appStore } = this.storeContext;
		appStore.fetchDocuments();
	};

	@computed
	get rawPagingComponentProps(): Partial<IPagingProps> {
		return (this.configuration && this.configuration.paging) || {};
	}

	@computed
	get pagingComponentProps(): IPagingProps {
		const { appStore } = this.storeContext;
		const props: IPagingProps = utils.object.deepMergeObjects(this.rawPagingComponentProps, {
			totalRows: appStore.total || 0,
			onChange: (start: number, limit: number) => {
				this.startValue = start;
				this.limitValue = limit;
				this.fetchDocuments();
			},
			pageSize: this.limit,
			currentIndex: this.start,
		} as IPagingProps);
		return props;
	}

	@computed
	get showLoading(): boolean {
		const { facetedSearchStore, tableStore, appStore, filtersStore } = this.storeContext;
		return (
			this.isLoading ||
			facetedSearchStore.isLoading ||
			tableStore.isLoading ||
			appStore.isLoading ||
			filtersStore.isLoading
		);
	}

	@computed
	get currentPage(): number {
		return this.startValue / this.limit + 1;
	}
}
