import { getAppsRenderVariables } from '@kurtosys/ksys-app-template/dist/appsManager/AppsManager';
import { IStyles } from '../models/app/IStyles';

export const STYLES: IStyles = {
	components: {
		accordionFilter: {
			children: {
				selectedCheckboxWrapper: {
					border: {
						bottom: {
							_value: '1px solid #ddd',
						},
					},
					margin: {
						_value: '0px 10px',
					},
					overrides: [],
				},
				unselectedCheckboxWrapper: {
					margin: {
						_value: '0px 10px',
					},
				},
				wrapper: {
					overrides: [
						{
							selector: '[data-all="false"]',
							style: {
								border: {
									bottom: {
										_value: '1px solid #ddd',
									},
								},
							},
						},
					],
				},
			},
			overrides: [
				{
					selector: '[data-selector="base-checkbox-wrapper"]',
					style: {
						display: 'block',
					},
				},
			],
		},
		app: {
			children: {
				stickyWrapper: {
					background: { _value: 'white' },
					zIndex: '3',
				},
				searchWrapper: {
					display: 'flex',
				},
				searchWrapperInner: {
					padding: {
						_value: '3px',
					},
					selectors: {
						':first-of-type': {
							flex: {
								_value: '1',
							},
						},
					},
					overrides: [
						{
							selector: '[data-selector="base-button"]',
							style: {
								height: '36px',
							},
						},
					],
				},
				wrapper: {
					overrides: [
						{
							selector:
								'[data-selector="base-search-searchClear"], [data-selector="portal-toast-close"] button',
							style: {
								background: {
									color: 'unset',
								},
								border: {
									_value: 'none',
								},
								outline: {
									_value: 'none',
								},
							},
						},
					],
				},
			},
		},
		bulkDownload: {
			children: {
				badge: {
					background: {
						color: 'white',
					},
					border: {
						radius: '50%',
					},
					color: '#FC6A27',
					font: {
						size: '10px',
					},
					height: '16px',
					lineHeight: '16px',
					margin: {
						left: '2px',
						right: '2px',
					},
					text: {
						align: 'center',
					},
					width: '16px',
				},
				button: {
					background: {
						color: '#FC6A27',
					},
					color: 'white',
					height: '36px',
					outline: {
						_value: 'none',
					},
				},
			},
		},
		bulkUrlCopy: {
			children: {
				badge: {
					background: {
						color: 'white',
					},
					border: {
						radius: '50%',
					},
					color: '#FC6A27',
					font: {
						size: '10px',
					},
					height: '16px',
					lineHeight: '16px',
					margin: {
						left: '2px',
						right: '2px',
					},
					text: {
						align: 'center',
					},
					width: '16px',
				},
				button: {
					background: {
						color: '#FC6A27',
					},
					color: 'white',
					height: '36px',
					outline: {
						_value: 'none',
					},
				},
			},
		},
		dateFilter: {
			children: {
				clickLayer: {
					bottom: '0',
					left: '0',
					position: 'fixed',
					right: '0',
					top: '0',
					zIndex: '999',
				},
				window: {
					background: {
						color: 'white',
					},
					box: {
						shadow: '0px 0px 1px rgba(0, 0, 0, 0.5);',
					},
					display: 'flex',
					flex: {
						direction: 'row',
					},
					margin: {
						top: '2px',
					},
					min: {
						height: '200px',
					},
					right: '6px',
					zIndex: '999999999',
				},
				button: {
					overrides: [
						{
							selector: 'img',
							style: {
								margin: {
									left: '8px',
								},
							},
						},
					],
					border: {
						_value: 'solid 1px #c3c9d9',
					},
					color: '#69748e',
					display: 'inline-block',
					lineHeight: '30px',
					padding: {
						left: '5px',
					},
					position: 'relative',
					font: {
						size: 'inherit',
					},
				},
			},
		},
		dateSearch: {
			children: {
				button: {
					height: '36px',
				},
				clickLayer: {
					background: {
						color: 'transparent',
					},
					bottom: '0',
					left: '0',
					position: 'fixed',
					right: '0',
					top: '0',
					zIndex: '999',
				},
				window: {
					background: {
						color: 'white',
					},
					box: {
						shadow: '0px 0px 1px rgba(0, 0, 0, 0.5);',
					},
					display: 'flex',
					flex: {
						direction: 'row',
					},
					margin: {
						top: '2px',
					},
					min: {
						height: '200px',
					},
					position: 'absolute',
					right: '6px',
					zIndex: '999999999',
				},
			},
		},
		documentPreview: {
			children: {
				noPreview: {
					height: '100%',
					margin: {
						_value: '20px',
					},
					text: {
						align: 'center',
					},
					width: '100%',
				},
				preview: {
					height: '100%',
					width: '100%',
				},
			},
		},
		facetedSearch: {
			children: {
				wrapper: {
					margin: {
						bottom: '20px',
					},
				},
			},
		},
		filters: {
			children: {
				activeFilterPill: {
					background: {
						color: '#EFEFEF',
					},
					border: {
						_value: '1px solid #ccc',
						radius: '10px',
					},
					margin: {
						bottom: '5px',
						right: '5px',
					},
					overrides: [
						{
							selector: '[data-selector="base-button"]',
							style: {
								background: {
									_value: 'unset',
								},
								border: {
									_value: 'unset',
								},
								display: 'inline',
								height: 'unset !important',
								padding: {
									_value: 'unset',
								},
								selectors: {
									':focus': {
										_raw: '[data-selector="base-icon"] { filter: unset; }',
									},
									':hover': {
										_raw: '[data-selector="base-icon"] { filter: unset; }',
									},
								},
							},
						},
						{
							selector: '[data-selector="base-icon-wrapper"]',
							style: {
								cursor: 'pointer',
								margin: {
									left: '5px',
								},
							},
						},
						{
							selector: '[data-selector="base-icon"]',
							style: {
								height: '11px',
								width: '11px',
							},
						},
					],
					padding: {
						_value: '5px',
					},
				},
				activeFiltersHeading: {
					color: '#0000A0',
					display: 'block',
					font: {
						family: '"Nordea Sans Small", sans-serif',
						size: '14px',
					},
					margin: {
						bottom: '5px',
					},
					width: '100%',
				},
				activeFiltersWrapper: {
					display: 'flex',
					flex: {
						wrap: 'wrap',
					},
					margin: {
						bottom: '30px',
					},
				},
				childFilters: {
					_raw: 'row-gap: 21px; column-gap: 16px;',
					breakpoints: [
						{
							min: '480px',
							style: {
								_raw: 'row-gap: 30px; column-gap: 16px;',
							},
						},
					],
					display: 'flex',
					flex: {
						wrap: 'wrap',
					},
					margin: {
						bottom: '30px',
					},
				},
				filterIndicator: {
					breakpoints: [
						{
							min: '768px',
							style: {
								margin: {
									right: '20px',
								},
							},
						},
					],
					color: '#0000A0',
					font: {
						family: '"Nordea Sans Large", Helvetica, Arial, sans-serif',
						size: '12px',
						weight: '300',
					},
					lineHeight: '16px',
					margin: {
						right: 'auto',
					},
				},
				toolbar: {
					align: {
						items: 'center',
					},
					display: 'flex',
					margin: {
						bottom: '30px',
					},
				},
			},
		},
	},
	theme: {
		base: {
			noDataPlaceholder: {
				children: {
					wrapper: {
						font: {
							weight: 'bold',
						},
						text: {
							align: 'center',
						},
					},
				},
			},
			accordionPanel: {
				children: {
					content: {
						color: '#0000A0',
						font: {
							size: '14px',
							weight: '400',
						},
						margin: {
							bottom: '10px',
						},
					},
					header: {
						color: '#0000A0',
						cursor: 'pointer',
						display: 'flex',
						flex: {
							direction: 'row',
						},
						font: {
							size: '14px',
							weight: '400',
						},
						margin: {
							_value: '4px',
						},
						overrides: [
							{
								selector: '[data-selector="base-button"]',
								style: {
									box: {
										shadow: 'none',
									},
									padding: {
										_value: '0',
									},
								},
							},
						],
					},
					headerText: {
						display: 'flex',
						flex: {
							_value: '1',
						},
					},
					wrapper: {
						border: {
							bottom: {
								_value: '1px solid #ccc',
							},
						},
						overrides: [
							{
								selector: '[data-selector="base-pill-wrapper"]',
								style: {
									background: {
										color: '#F3F9FF',
									},
									border: {
										_value: '1px solid #0000A0',
										radius: '10px',
									},
									color: '#0000A0',
									font: {
										size: '11px',
									},
									margin: {
										bottom: '5px',
										left: '10px',
									},
									padding: {
										_value: '4px 10px',
									},
								},
							},
							{
								selector: '[data-selector="base-accordionPanel-toggle"][data-align="right"]',
								style: {
									flex: {
										grow: 'auto',
									},
								},
							},
							{
								selector: '[data-selector="base-input"]',
								style: {
									border: {
										radius: '10px',
									},
									padding: {
										_value: '10px',
									},
								},
							},
						],
					},
				},
			},
			actions: {
				children: {
					wrapper: {
						display: 'flex',
					},
				},
			},
			application: {
				font: {
					family: 'Arial',
				},
			},
			asOfDate: {},
			asset: {},
			button: {
				align: {
					items: 'center',
				},
				background: {
					color: 'white',
				},
				border: {
					color: 'initial',
					style: 'initial',
					width: '0px',
				},
				box: {
					shadow: '0px 0px 1px rgba(0,0,0,0.5)',
					sizing: 'inherit',
				},
				cursor: 'pointer',
				display: 'flex',
				flex: {
					shrink: '0',
				},
				font: {
					family: '"DM Sans", sans-serif',
					size: '13px',
					weight: '400',
				},
				justifyContent: 'center',
				lineHeight: '24px',
				margin: {
					_value: '0px auto',
				},
				overrides: [
					{
						selector: 'img',
						style: {
							margin: {
								right: '4px',
							},
						},
					},
				],
				padding: {
					_value: '5px 10px',
				},
			},
			calendarInput: {
				children: {
					body: {},
					calendar: {
						background: {
							color: 'white',
						},
						box: {
							shadow: '0px 0px 1px rgba(0, 0, 0, 0.5);',
						},
						position: 'relative',
						width: 'fit-content',
						zIndex: '1000',
					},
					clickLayer: {
						background: {
							color: 'transparent',
						},
						bottom: '0',
						left: '0',
						position: 'fixed',
						right: '0',
						top: '0',
						zIndex: '999',
					},
					datePickerDay: {
						cursor: 'pointer',
						display: 'inline-block',
						height: '34px',
						lineHeight: '34px',
						selectors: {
							':hover': {
								background: {
									color: '#FC6A27',
								},
								border: {
									radius: '50%',
								},
								color: 'white',
								opacity: '0.5',
							},
						},
						text: {
							align: 'center',
						},
						width: '34px',
					},
					datePickerDayDisabled: {
						cursor: 'not-allowed',
						selectors: {
							':hover': {
								background: {
									color: 'unset',
								},
							},
						},
					},
					datePickerDayPlaceholder: {
						cursor: 'default',
						selectors: {
							':hover': {
								background: {
									color: 'unset',
								},
							},
						},
					},
					datePickerDaySelected: {
						background: {
							color: '#FC6A27',
						},
						border: {
							radius: '50%',
						},
						color: 'white',
						selectors: {
							':hover': {
								opacity: '1',
							},
						},
					},
					datePickerDayToday: {
						color: '#FC6A27',
					},
					dayOfWeek: {
						display: 'inline-block',
						height: '34px',
						lineHeight: '34px',
						opacity: '0.5',
						text: {
							align: 'center',
						},
						width: '34px',
					},
					header: {},
					headerNav: {
						display: 'flex',
						flex: {
							direction: 'row',
						},
						font: {
							size: '14px',
							weight: '500',
						},
						lineHeight: '30px',
					},
					month: {
						flex: {
							_value: '1',
						},
						padding: {
							right: '3px',
						},
						text: {
							align: 'right',
						},
					},
					monthButtonNext: {
						background: {
							_value: 'none',
						},
						border: {
							_value: 'none',
						},
						box: {
							shadow: 'none',
						},
						outline: {
							_value: 'none',
						},
						width: '30px',
					},
					monthButtonPrevious: {
						background: {
							_value: 'none',
						},
						border: {
							_value: 'none',
						},
						box: {
							shadow: 'none',
						},
						outline: {
							_value: 'none',
						},
						width: '30px',
					},
					row: {},
					wrapper: {
						color: 'rgb(58, 64, 81)',
						font: {
							size: '12px',
						},
					},
					year: {
						flex: {
							_value: '1',
						},
					},
				},
			},
			card: {
				children: {
					subtitle: {
						color: '#69748E',
						font: {
							size: '18px',
							style: 'normal',
							weight: '400',
						},
						lineHeight: '24px',
					},
					title: {
						font: {
							size: '37px',
							style: 'normal',
							weight: '400',
						},
						lineHeight: '47px',
					},
				},
			},
			checkbox: {
				children: {
					icon: {
						verticalAlign: 'middle',
					},
				},
			},
			clickLayer: {
				background: {
					color: 'black',
				},
				bottom: '0',
				left: '0',
				opacity: '0.3',
				position: 'fixed',
				right: '0',
				top: '0',
				zIndex: '999',
			},
			contextMenu: {
				children: {
					button: {
						border: {
							style: 'none',
						},
						font: {
							size: '13px',
						},
						lineHeight: '34px',
						outline: {
							style: 'none',
						},
					},
					clickLayer: {
						background: {
							color: 'transparent',
						},
						bottom: '0',
						left: '0',
						position: 'absolute',
						right: '0',
						top: '0',
					},
					dropdownItem: {
						display: 'block',
						font: {
							size: '13px',
						},
						lineHeight: '34px',
					},
					icon: {
						verticalAlign: 'middle',
					},
				},
			},
			dateRangeInput: {
				children: {
					calendarWrapper: {
						overrides: [
							{
								selector: '[data-selector="base-calendarInput-calendar"]',
								style: {
									box: {
										shadow: 'none',
									},
								},
							},
						],
						width: '240px',
					},
					field: {
						margin: {
							bottom: '20px',
						},
						overrides: [
							{
								selector: '[data-selector="base-icon-wrapper"]',
								style: {
									bottom: '4px',
									cursor: 'pointer',
									position: 'absolute',
									right: '2px',
								},
							},
							{
								selector: '[data-selector="base-input"]',
								style: {
									background: {
										_value: 'none',
									},
									border: {
										_value: 'none',
										bottom: {
											_value: '1px solid rgb(58, 64, 81)',
										},
									},
									outline: {
										_value: 'none',
									},
									width: '100%',
								},
							},
							{
								selector: '[data-selector="base-input"].active-field',
								style: {
									border: {
										bottom: {
											_value: '2px solid #FC6A27',
										},
									},
								},
							},
							{
								selector: '[data-selector="base-dropdown-wrapper"]',
								style: {
									font: {
										size: '13px',
										weight: 'normal',
									},
								},
							},
							{
								selector: '[data-selector="base-dropdown-header"]',
								style: {
									border: {
										_value: 'none',
										bottom: {
											_value: '1px solid rgb(58, 64, 81)',
										},
									},
									width: '100%',
								},
							},
							{
								selector: '[data-selector="base-dropdown-headerTitle"]',
								style: {
									font: {
										size: '13px',
										weight: 'normal',
									},
								},
							},
							{
								selector: '[data-selector="base-dropdown-listItems"]',
								style: {
									width: '100%',
								},
							},
						],
						position: 'relative',
					},
					fieldLabel: {
						color: 'rgba(0, 0, 0, 0.3)',
						display: 'block',
						font: {
							size: '13px',
						},
						lineHeight: '22px',
					},
					fieldsWrapper: {
						background: {
							color: 'rgb(243, 244, 248)',
						},
						flex: {
							_value: '1',
							direction: 'column',
						},
						padding: {
							_value: '30px 40px 15px',
						},
					},
					selectedDate: {
						cursor: 'pointer',
						font: {
							size: '36px',
							weight: '500',
						},
						lineHeight: '36px',
					},
					selectedDateWrapper: {
						background: {
							color: '#FC6A27',
						},
						color: 'white',
						padding: {
							_value: '20px',
						},
						width: '165px',
					},
					selectedYear: {
						cursor: 'pointer',
						font: {
							size: '16px',
							weight: '500',
						},
						lineHeight: '16px',
						margin: {
							bottom: '10px',
						},
						opacity: '0.7',
						width: '144px',
					},
					wrapper: {
						zIndex: '1000',
						border: {
							radius: '2px',
						},
						box: {
							shadow: 'rgba(0, 0, 0, 0.19) 0px 10px 30px, rgba(0, 0, 0, 0.23) 0px 6px 10px',
						},
						display: 'flex',
						flex: {
							direction: 'row',
						},
						font: {
							family: '"DM Sans", sans-serif',
						},
						width: '800px',
					},
				},
			},
			dialog: {
				children: {
					backdrop: {
						background: {
							color: 'rgba(0, 0, 0, 0.5)',
						},
						bottom: '0',
						content: '',
						display: 'block',
						left: '0',
						margin: {
							_value: '-50vmax',
						},
						position: 'fixed',
						right: '0',
						top: '0',
						zIndex: '98',
					},
					base: {
						background: {
							color: 'white',
						},
						display: 'flex',
						flex: {
							direction: 'column',
						},
						left: '50%',
						margin: {
							_value: 'auto',
						},
						max: {
							height: 'calc(100% - 60px)',
							width: 'calc(100% - 60px)',
						},
						position: 'fixed',
						top: '50%',
						transform: 'translate(-50%, -50%)',
						transition: {
							_value: 'all 0.2s',
						},
						zIndex: '99',
					},
					body: {
						padding: {
							_value: '0 15px',
						},
					},
					close: {
						float: 'right',
						overrides: [
							{
								selector: 'button',
								style: {
									lineHeight: '16px',
									padding: {
										_value: '0',
									},
								},
							},
						],
					},
					titleBar: {
						border: {
							bottom: {
								_value: 'solid 1px #8a8a8a',
							},
						},
						padding: {
							_value: '10px',
						},
					},
				},
			},

			dropdown: {
				children: {
					clickLayer: {
						background: {
							color: 'transparent',
						},
						bottom: '0',
						left: '0',
						position: 'absolute',
						right: '0',
						top: '0',
						zIndex: '999',
					},
					header: {
						border: {
							_value: 'solid 1px #c3c9d9',
						},
						color: '#69748e',
						display: 'inline-block',
						lineHeight: '40px',
						padding: {
							left: '5px',
						},
						position: 'relative',
					},
					headerIcon: {
						float: 'right',
						padding: {
							left: '8px',
							right: '8px',
						},
					},
					headerTitle: {
						float: 'left',
					},
					listItem: {
						border: {
							bottom: {
								_value: '1px solid #E0E3ED',
							},
						},
						display: 'flex',
						flex: {
							direction: 'row',
						},
						justifyContent: 'space-between',
						padding: {
							bottom: '5px',
							left: '5px',
							right: '5px',
							top: '5px',
						},
						selectors: {
							':hover': {
								background: {
									color: '#0075BE',
								},
								color: '#FFF',
							},
						},
						text: {
							align: 'right',
						},
					},
					listItemCheckboxWrapper: {
						height: '25px',
						lineHeight: '15px',
					},
					listItemLabel: {
						color: '#bbbbbb',
						height: '25px',
						lineHeight: '25px',
					},
					listItemLabelHighlight: {
						color: 'black',
					},
					listItems: {
						background: {
							color: '#FFF',
						},
						border: {
							_value: '1px solid #E0E3ED',
						},
						display: 'inline-block',
						listStyle: {
							type: 'none',
						},
						margin: {
							_value: '0',
						},
						padding: {
							_value: '0',
						},
						position: 'absolute',
						zIndex: '1000',
					},
				},
			},
			icon: {
				children: {
					wrapper: {
						overrides: [
							{
								selector: '[data-selector="base-icon"]',
								style: {
									verticalAlign: 'middle',
								},
							},
						],
					},
				},
			},
			iconInput: {
				children: {
					iconComponent: {
						fill: 'none',
					},
					iconWrapper: {
						display: 'flex',
						left: '0.75em',
						position: 'absolute',
						top: '50%',
						transform: 'translateY(-50%)',
					},
					inputComponent: {
						border: {
							_value: 'none',
						},
						box: {
							sizing: 'border-box',
						},
						height: '40px',
						padding: {
							_value: '0 3em',
						},
						width: '100%',
					},
					standardWrapper: {
						border: {
							_value: '1px solid rgb(195, 201, 217)',
						},
						display: 'flex',
						overrides: [
							{
								selector: '[data-selector="base-pill-wrapper"]',
								style: {
									background: {
										color: '#cecece',
									},
									border: {
										radius: '10px',
									},
									font: {
										size: '11px',
									},
									margin: {
										_value: '8px',
									},
									padding: {
										_value: '5px',
									},
									text: {
										align: 'center',
									},
									width: '100px',
								},
							},
						],
					},
					wrapper: {
						position: 'relative',
					},
				},
			},
			image: {
				children: {
					styledImage: {
						verticalAlign: 'middle',
					},
				},
			},
			link: {},
			modal: {
				children: {
					modalWrapper: {
						top: '5%',
						right: '5%',
						left: '5%',
						bottom: '5%',
						position: 'fixed',
						display: 'flex',
						justifyContent: 'flex-start',
						flex: {
							direction: 'column',
						},
						background: {
							color: 'white',
						},
						box: {
							shadow: '0 5px 15px -5px',
						},
						border: {
							radius: '0.25em',
						},
						padding: {
							_value: '1em 0 0 0',
						},
						zIndex: '1001',
					},
					modalTitleBar: {
						display: 'flex',
						justifyContent: 'space-between',
						flex: {
							direction: 'row',
						},
						margin: {
							_value: '0 0 10px 10px',
						},
					},
					modalHead: {
						margin: {
							_value: '0 0 10px 0',
						},
					},
					modalOpenIcon: {
						height: '16px',
						width: '16px',
					},
					modalOpenButton: {
						display: 'inline-flex',
						justifyContent: 'space-between',
						flex: {
							direction: 'row',
						},
						border: {
							_value: 'none',
							bottom: {
								_value: '1px solid #dee2e6',
							},
						},
						background: {
							_value: 'none',
						},
						margin: {
							_value: '0 15px',
						},
						padding: {
							_value: '4px 2px',
						},
						cursor: 'pointer',
					},
					modalFooter: {
						margin: {
							_value: '10px 0 0 0',
						},
					},
					modalCloseIcon: {
						height: '16px',
						width: '16px',
						verticalAlign: 'unset',
					},
					modalCloseButton: {
						display: 'flex',
						justifyContent: 'space-between',
						flex: {
							direction: 'row',
						},
						border: {
							_value: 'none',
						},
						background: {
							_value: 'none',
						},
						cursor: 'pointer',
						box: {
							shadow: 'none',
						},
						margin: {
							_value: '0 10px',
						},
					},
					modalBody: {
						display: 'flex',
						margin: {
							_value: '0',
						},
						overflow: {
							y: 'auto',
						},
						flex: {
							grow: '1',
						},
					},
					modalActions: {
						display: 'flex',
						justifyContent: 'flex-end',
						flex: {
							direction: 'row',
						},
						margin: {
							_value: '10px 0 0 0',
						},
					},
					modalActionClose: {
						outline: {
							_value: 'none',
						},
						text: { align: 'center' },
						box: {
							shadow: '2px 2px 0 rgb(0 0 0 / 10%)',
						},
						border: {
							style: 'solid',
							color: '#0588c5',
							radius: '3px',
							width: '1px',
						},
						justifyContent: 'center',
						align: {
							items: 'center',
						},
						background: {
							_value: '#03a9f4',
						},
						min: {
							height: '24px',
						},
						padding: {
							top: '4px',
							bottom: '4px',
							left: '8px',
							right: '8px',
						},
						margin: {
							_value: '0 5px 0 0',
						},
						color: '#ffffff',
						cursor: 'pointer',
						selectors: {
							':hover': {
								border: {
									color: '#05a2eb',
								},
								background: {
									color: '#00aeff',
								},
							},
						},
					},
					modalAction: {
						outline: {
							_value: 'none',
						},
						text: { align: 'center' },
						box: {
							shadow: '2px 2px 0 rgb(0 0 0 / 10%)',
						},
						border: {
							style: 'solid',
							color: '#dee2e6',
							radius: '3px',
							width: '1px',
						},
						justifyContent: 'center',
						align: {
							items: 'center',
						},
						background: {
							_value: '#f8f9fa',
						},
						min: {
							height: '24px',
						},
						padding: {
							top: '4px',
							bottom: '4px',
							left: '8px',
							right: '8px',
						},
						margin: {
							_value: '0 5px 0 0',
						},
						cursor: 'pointer',
						selectors: {
							':hover': {
								border: {
									color: '#ebeef0',
								},
								background: {
									color: '#fcfcfc',
								},
							},
						},
					},
				},
			},
			numberPicker: {
				children: {
					selectedValue: {
						color: '#FC6A27',
						font: {
							size: '26px',
							weight: '500',
						},
						lineHeight: '35px',
						text: {
							align: 'center',
						},
						width: '100%',
					},
					value: {
						color: 'rgb(58, 64, 81)',
						font: {
							size: '17px',
						},
						lineHeight: '35px',
						selectors: {
							':hover': {
								color: '#FC6A27',
							},
						},
						text: {
							align: 'center',
						},
						width: '100%',
					},
					wrapper: {
						display: 'flex',
						flex: {
							direction: 'column',
						},
						max: {
							height: '300px',
						},
						overflow: {
							_value: 'auto',
						},
					},
				},
			},
			paging: {
				children: {
					pageBackwards: {
						width: '35px',
					},
					pageForwards: {
						width: '35px',
					},
					pageNavigation: {
						display: 'flex',
					},
					pageSelectorWrapper: {
						display: 'flex',
						flex: {
							direction: 'row',
						},
						lineHeight: '24px',
						overrides: [
							{
								selector: '[data-selector="base-dropdown-listItems"]',
								style: {
									margin: {
										top: '-8px',
									},
									width: '38px',
								},
							},
						],
						padding: {
							_value: '10px 0',
						},
					},
					pageSizeLabel: {
						padding: {
							_value: '0 10px',
						},
					},
					pageSizeWrapper: {
						display: 'flex',
						flex: {
							direction: 'row',
						},
						lineHeight: '24px',
						padding: {
							_value: '10px 0',
						},
					},
					pagingResultsStatus: {
						lineHeight: '24px',
						padding: {
							_value: '10px 0',
						},
					},
					wrapper: {
						display: 'flex',
						font: {
							family: 'BodyFont, "Source Sans Pro", sans-serif',
							size: '14px',
						},
						justifyContent: 'space-between',
						overrides: [
							{
								selector: '[data-selector="base-buttonFlat"]',
								style: {
									background: {
										_value: 'none',
									},
									border: {
										_value: 'none',
									},
									outline: {
										style: 'none',
									},
								},
							},
						],
					},
				},
				lineHeight: '24px',
				overrides: [
					{
						selector: '[data-selector="base-dropdown-header"]',
						style: {
							lineHeight: '24px',
						},
					},
				],
			},
			savedSearch: {
				children: {
					clickLayer: {
						background: {
							color: 'transparent',
						},
						bottom: '0',
						left: '0',
						position: 'fixed',
						right: '0',
						top: '0',
						zIndex: '999',
					},
					input: {
						border: {
							_value: 'none',
						},
						box: {
							shadow: '0px 0px 1px rgba(0,0,0,0.5)',
						},
						height: '42px',
						margin: {
							_value: '2px',
						},
						outline: {
							_value: 'none',
						},
					},
					listWrapper: {
						background: {
							color: 'white',
						},
						box: {
							shadow: '0px 0px 2px rgba(0, 0, 0, 0.5);',
						},
						margin: {
							right: '10px',
							top: '48px',
						},
						position: 'absolute',
						right: '0',
						width: 'fit-content',
						zIndex: '1000',
					},
					saveButton: {
						selectors: {
							':disabled': {
								cursor: 'not-allowed',
							},
						},
					},
					wrapper: {
						display: 'flex',
						overrides: [
							{
								selector:
									'[data-selector="base-savedSearch-saveButton"], [data-selector="base-savedSearch-toggleButton"], [data-selector="base-savedSearch-listButton"]',
								style: {
									background: {
										color: 'white',
									},
									box: {
										shadow: '0px 0px 1px rgba(0,0,0,0.5)',
									},
									height: '38px',
									outline: {
										_value: 'none',
									},
									padding: {
										_value: '4px 8px',
									},
								},
							},
						],
					},
				},
			},
			savedSearchList: {
				children: {
					notificationOff: {
						background: {
							color: '#F5F6FB',
						},
						color: '#000000',
					},
					notificationOn: {
						background: {
							color: '#E4F9EC',
						},
						color: '#00C761',
					},
					row: {
						border: {
							bottom: {
								_value: '1px solid #ccc',
							},
						},
						display: 'flex',
						flex: {
							direction: 'row',
						},
						font: {
							size: '14px',
						},
						lineHeight: '18px',
						padding: {
							_value: '10px',
						},
					},
					savedSearchName: {
						flex: {
							_value: '1',
						},
					},
					wrapper: {
						display: 'flex',
						flex: {
							direction: 'column',
						},
						overrides: [
							{
								selector: '[data-selector="base-pill-wrapper"]',
								style: {
									border: {
										radius: '40px',
									},
									font: {
										size: '11px',
									},
									lineHeight: '14px',
									padding: {
										_value: '4px 7px',
									},
									text: {
										align: 'right',
									},
								},
							},
							{
								selector: '[data-selector="base-iconInput-wrapper"]',
								style: {
									margin: {
										_value: 'none',
									},
									width: '100%',
								},
							},
							{
								selector: '[data-selector="base-iconInput"]',
								style: {
									border: {
										_value: 'none',
									},
									box: {
										shadow: '0px 0px 1px rgba(0,0,0,0.5)',
									},
									margin: {
										_value: 'none',
									},
									outline: {
										_value: 'none',
									},
								},
							},
						],
					},
				},
			},
			search: {
				children: {
					resultBackground: {
						background: {
							color: 'rgba(0,0,0,0.0)',
						},
						bottom: '0',
						left: '0',
						position: 'fixed',
						right: '0',
						top: '0',
						zIndex: '2',
					},
					resultCard: {
						background: {
							color: 'white',
						},
						border: {
							_value: '1px solid #ccc',
						},
						listStyle: {
							type: 'none',
						},
						margin: {
							_value: '0',
						},
						padding: {
							_value: '0',
						},
						position: 'absolute',
						top: '47px',
						width: '100%',
						zIndex: '3',
					},
					resultElement: {
						border: {
							bottom: {
								_value: '1px solid #ccc',
							},
						},
						cursor: 'pointer',
						padding: {
							_value: '10px',
						},
						selectors: {
							':nth-last-of-type': {
								_parameters: ['1'],
								border: {
									_value: 'none',
								},
							},
						},
						zIndex: '4',
					},
					resultGroup: {
						background: {
							color: '#F5F6FB',
						},
						border: {
							bottom: {
								_value: '1px solid #ccc',
							},
							top: {
								_value: '1px solid #ccc',
							},
						},
						font: {
							size: '10px',
							weight: 'bold',
						},
						padding: {
							_value: '10px',
						},
						selectors: {
							':first-of-type': {
								border: {
									top: {
										_value: 'none',
									},
								},
							},
						},
					},
					resultGroupElement: {
						background: {
							color: '#F5F6FB',
						},
						border: {
							radius: '15px',
						},
						flex: {
							basis: '1%',
						},
						font: {
							size: '12px',
						},
						height: '20px',
						margin: {
							_value: '4px',
						},
						padding: {
							_value: '4px 8px 0 8px',
						},
						text: {
							align: 'center',
						},
						whiteSpace: 'nowrap',
						width: 'auto',
					},
					resultGroupWrapper: {
						display: 'flex',
						flex: {
							wrap: 'wrap',
						},
						listStyle: {
							type: 'none',
						},
						margin: {
							_value: '8px',
						},
						padding: {
							_value: '0',
						},
					},
					searchBar: {
						box: {
							shadow: '0px 0px 1px rgba(0, 0, 0, 0.5);',
						},
						display: 'flex',
						overrides: [
							{
								selector: 'input[data-selector="base-input"]',
								style: {
									border: {
										_value: 'none',
									},
									flex: {
										basis: '98%',
									},
									font: {
										size: '13px',
									},
									height: '24px',
									lineHeight: '18px',
									margin: {
										_value: '0 5px',
									},
									outline: {
										_value: 'none',
									},
									selectors: {
										'::-ms-clear': {
											display: 'none',
										},
										'::-webkit-input-placeholder': {
											color: '#bbbbbb',
										},
									},
								},
							},
						],
						padding: {
							_value: '10px 0',
						},
						width: '100%',
						zIndex: '3',
					},
					searchClear: {
						cursor: 'pointer',
						min: {
							width: '24px',
						},
					},
					searchIcon: {
						flex: {
							basis: '1%',
						},
						margin: {
							_value: '0 10px',
						},
						min: {
							width: '24px',
						},
					},
					searchPill: {
						background: {
							color: 'rgba(252, 106, 39, 0.3)',
						},
						border: {
							radius: '15px',
						},
						flex: {
							basis: '1%',
						},
						height: '24px',
						margin: {
							_value: '0 10px',
						},
						overrides: [
							{
								selector: 'span[data-selector="base-pill-value"]',
								style: {
									color: '#FC6A27',
									font: {
										size: '11px',
									},
									lineHeight: '14px',
									padding: {
										_value: '0',
									},
								},
							},
						],
						padding: {
							_value: '2px 8px 0 8px',
						},
						text: {
							align: 'center',
						},
						whiteSpace: 'nowrap',
						width: 'auto',
					},
					selectedResultClear: {
						margin: {
							left: '10px',
						},
					},
					selectedResultGroup: {
						font: {
							weight: 'bold',
						},
						padding: {
							right: '4px',
						},
					},
					selectedResultPill: {
						background: {
							color: '#E4F9EC',
						},
						border: {
							_value: 'none',
							radius: '40px',
						},
						color: '#00C761',
						cursor: 'pointer',
						flex: {
							basis: '1%',
						},
						font: {
							size: '11px',
						},
						height: '20px',
						lineHeight: '14px',
						margin: {
							_value: '0 5px',
						},
						padding: {
							_value: '6px 2px 6px 10px',
						},
						text: {
							align: 'center',
						},
						whiteSpace: 'nowrap',
						width: 'auto',
					},
					selectedResultValue: {
						padding: {
							right: '4px',
						},
					},
					selectedResultsWrapper: {
						display: 'inline-flex',
					},
					wrapper: {
						height: '44px',
						position: 'relative',
					},
				},
			},
			table: {
				children: {
					body: {
						color: '#001928',
						font: {
							size: '16px',
						},
						lineHeight: '24px',
					},
					card: {
						border: {
							_value: '1px solid #ddd',
						},
						font: {
							size: '11px',
							style: 'normal',
						},
						margin: {
							bottom: '10px',
						},
						overrides: [
							{
								selector: 'ul[data-selector="base-list"]',
								style: {
									listStyle: {
										type: 'none',
									},
									padding: {
										_value: '0',
									},
								},
							},
							{
								selector: '[data-selector="base-list-multipartListItem"]',
								style: {
									display: 'flex',
									flex: {
										direction: 'row',
									},
									margin: {
										bottom: '10px',
									},
								},
							},
							{
								selector:
									'[data-selector="base-list-multipartListItem"] [data-selector="base-list-firstContent"]',
								style: {
									flex: {
										_value: '1',
									},
									text: {
										align: 'left',
										transform: 'uppercase',
									},
								},
							},
							{
								selector:
									'[data-selector="base-list-multipartListItem"] [data-selector="base-list-lastContent"]',
								style: {
									flex: {
										_value: '1',
									},
									overflow: {
										_value: 'auto',
									},
									text: {
										align: 'right',
									},
								},
							},
						],
						padding: {
							_value: '4px',
						},
					},
					cardGrouping: {
						border: {
							radius: '10px',
						},
						display: 'flex',
						margin: {
							bottom: '10px',
							top: '10px',
						},
						overrides: [
							{
								selector: '[data-selector="base-toggleButton-wrapper"] [data-selector="base-button"]',
								style: {
									background: {
										color: 'unset',
									},
									border: {
										_value: 'unset',
									},
								},
							},
							{
								selector: 'label',
								style: {
									flex: {
										_value: '1',
									},
								},
							},
						],
						padding: {
							_value: '10px',
						},
					},
					cell: {
						border: {
							bottom: {
								_value: '1px solid #ddd',
							},
						},
						color: '3A4051',
						font: {
							size: '13px',
							style: 'normal',
							weight: '400',
						},
						lineHeight: '18px',
						padding: {
							_value: '0px 4px',
						},
					},
					disclaimersWrapper: {
						margin: {
							top: '20px',
						},
					},
					headCell: {
						border: {
							top: {
								_value: '1px solid #ddd',
							},
							bottom: {
								_value: '1px solid #ddd',
							},
						},
						font: {
							size: '13px',
							style: 'normal',
							weight: '700',
						},
						lineHeight: '40px',
						padding: {
							_value: '0px 4px',
						},
						text: {
							align: 'left',
						},
						background: {
							color: '#FFFFFF',
						},
					},
					headRow: {
						height: '40px',
					},
					row: {
						background: {
							color: '#FFFFFF',
						},
						height: '40px',
					},
					table: {
						border: {
							bottom: {
							_value: '1px solid #ddd',
							},
							right: {
							_value: '1px solid #ddd',
							},
							left: {
							_value: '1px solid #ddd',
							}
						},
						font: {
							family: 'Arial',
						},
						width: '100%',
					},
				},
				overrides: [
					{
						selector: '[data-selector="base-table-card"]:nth-child(even)',
						style: {
							background: {
								color: '#F3F9FF',
							},
						},
					},
					{
						selector: 'div[data-selector="base-tooltip-tooltipContent"]',
						style: {
							color: '#333333',
						},
					},
					{
						selector: 'th [data-selector="base-sortButton-wrapper"]',
						style: {
							float: 'right',
							height: '40px',
							overrides: [
								{
									selector: '[data-selector="base-button"]',
									style: {
										border: {
											_value: 'none',
										},
										box: {
											shadow: 'none',
										},
										height: '40px',
										overrides: [
											{
												selector: 'img',
												style: {
													height: '16px',
													margin: {
														_value: '0',
													},
													width: '16px',
												},
											},
										],
										padding: {
											_value: '0',
										},
									},
								},
							],
						},
					},
				],
				width: '100%',
			},
			tableGrouping: {
				children: {
					groupHeader: {
						border: {
							_value: 'solid 1px #eeeeee',
						},
						children: {
							toggleButtonWrapper: {
								flex: {
									grow: '0',
								},
							},
							value: {
								color: 'blue',
								flex: {
									grow: '1',
								},
								lineHeight: '30px',
								padding: {
									left: '10px',
									right: '10px',
								},
							},
						},
						display: 'flex',
						flex: {
							direction: 'row',
						},
						padding: {
							_value: '10px',
						},
					},
				},
			},
			text: {},
			tooltip: {
				children: {
					arrowIcon: {
						fill: 'white',
						stroke: '#cccccc',
					},
					clickLayer: {
						background: {
							color: 'transparent',
						},
						bottom: '0',
						left: '0',
						position: 'fixed',
						right: '0',
						top: '0',
						zIndex: '3',
					},
					tooltipCharacter: {
						background: {
							color: '#1EA7FD',
						},
						border: {
							radius: '1.5em',
						},
						color: 'white',
						font: {
							size: '9px',
						},
						margin: {
							_value: '0 0.5em',
						},
						min: {
							width: '1.5em',
						},
						padding: {
							bottom: '0.2em',
							left: '0.5em',
							right: '0.5em',
							top: '0.2em',
						},
						text: {
							align: 'center',
						},
					},
					tooltipContent: {
						background: {
							color: 'white',
						},
						border: {
							color: '#cccccc',
							radius: '3px',
							style: 'solid',
							width: '1px',
						},
						box: {
							shadow: '2px 2px 0 rgba(0,0,0,0.1)',
							sizing: 'border-box',
						},
						font: {
							size: 'smaller',
						},
						margin: {
							_value: '3px',
							bottom: '2px',
						},
						max: {
							width: '250px',
						},
						padding: {
							_value: '4px',
						},
						text: {
							align: 'center',
						},
					},
					tooltipIcon: {
						height: '16px',
						width: '16px',
					},
					tooltipIconButton: {
						cursor: 'pointer',
						position: 'sticky',
						zIndex: '2',
					},
					wrapper: {
						display: 'inline-block',
						overrides: [
							{
								selector: '[data-selector="base-tooltip-arrowIconWrapper"]',
								style: {
									position: 'absolute',
								},
							},
							{
								selector: 'div[data-placement="top"] [data-selector="base-tooltip-arrowIconWrapper"]',
								style: {
									bottom: '-14px',
								},
							},
							{
								selector:
									'div[data-placement="bottom"] [data-selector="base-tooltip-arrowIconWrapper"]',
								style: {
									top: '-13px',
									transform: 'rotate(180deg)',
								},
							},
							{
								selector: 'div[data-placement="left"] [data-selector="base-tooltip-arrowIconWrapper"]',
								style: {
									right: '-11px',
									transform: 'rotate(-90deg)',
								},
							},
							{
								selector: 'div[data-placement="right"] [data-selector="base-tooltip-arrowIconWrapper"]',
								style: {
									left: '-11px',
									transform: 'rotate(90deg)',
								},
							},
							{
								selector: 'div[data-selector="base-tooltip-tooltipContentWrapper"]',
								style: {
									zIndex: '3',
								},
							},
						],
						verticalAlign: 'text-bottom',
					},
				},
			},
			translate: {},
			virtualizedList: {
				children: {
					wrapper: {
						height: '80px',
					},
				},
			},
		},
		portal: {
			toast: {
				children: {
					base: {
						border: {
							color: 'transparent',
							radius: '5px',
							style: 'solid',
							width: '1px',
						},
						padding: {
							_value: '10px',
						},
						position: 'relative',
					},
					close: {
						position: 'absolute',
						right: '0',
						top: '0',
					},
					error: {
						background: {
							color: '#f8d7da',
						},
						border: {
							color: '#f5c6cb',
						},
						color: '#721c24',
					},
					info: {
						background: {
							color: '#d1ecf1',
						},
						border: {
							color: '#bee5eb',
						},
						color: '#0c5460',
					},
					success: {
						background: {
							color: '#d4edda',
						},
						border: {
							color: '#c3e6cb',
						},
						color: '#155724',
					},
					warning: {
						background: {
							color: '#fff3cd',
						},
						border: {
							color: '#ffeeba',
						},
						color: '#856404',
					},
					wrapper: {
						bottom: '0',
						left: '0',
						padding: {
							_value: '4px',
						},
						position: 'fixed',
						right: '0',
					},
				},
			},
		},
	},
};
