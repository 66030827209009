import React, { Component, Fragment } from 'react';
import { createPortal } from 'react-dom';
import { inject, observer } from 'mobx-react';
import { computed, observable } from 'mobx';
import { utils } from '@kurtosys/ksys-app-template';
// models
import { IFiltersProps, IFiltersState, TFiltersMode, ToggleButtonTargetPosition, IFilterToggleButtonProps, IRenderLayoutOptions } from './models';
import { IFilterProps } from '../Filter/models';
import { AppStore } from '../App/stores/AppStore';
import { FiltersStore } from './stores/FiltersStore';
// components
import Filter from '../Filter';
import AccordionFilter from '../AccordionFilter';
import Breakpoint from '@kurtosys/ksys-app-components/dist/components/base/Breakpoint';
// styled components
import { InjectedStyledComponent } from '../shared/InjectedStyledComponent';
import Wrapper from './styledComponents/Wrapper';
import ClearButton from './styledComponents/ClearButton';
import CloseButton from './styledComponents/CloseButton';
import ChildFilters from './styledComponents/ChildFilters';
import Toolbar from './styledComponents/Toolbar';
import FilterIndicator from './styledComponents/FilterIndicator';
import DialogHeader from './styledComponents/DialogHeader';
import FiltersHeaderToggle from './styledComponents/FiltersHeaderToggle';
import FiltersDialog from './styledComponents/FiltersDialog';
import ActiveFiltersWrapper from './styledComponents/ActiveFiltersWrapper';
import ActiveFiltersHeading from './styledComponents/ActiveFiltersHeading';
import ActiveFilterPill from './styledComponents/ActiveFilterPill';


@inject('appStore', 'filtersStore')
@observer
export class Filters extends Component<IFiltersProps, IFiltersState> {
	static configurationKey: 'filters' = 'filters';
	static styleKey: 'filters' = 'filters';

	@observable.ref
	dialogNode: HTMLElement | null | undefined;

	constructor(props: IFiltersProps) {
		super(props);
	}

	componentDidMount() {
		if (this.appStore && this.appStore.dialogPlaceholderId) {
			this.dialogNode = document.getElementById(this.appStore.dialogPlaceholderId);
		}
	}

	@computed
	get appStore(): AppStore | undefined {
		return this.props.appStore;
	}

	@computed
	get filtersStore(): FiltersStore | undefined {
		return this.props.filtersStore;
	}

	@computed
	get childFilters(): IFilterProps[] {
		if (!this.filtersStore || !this.filtersStore.hasFilters) {
			return [];
		}
		return this.filtersStore.childFilters.filter(f => !utils.typeChecks.isNullOrEmpty(f.items));
	}

	showToggleButton(filtersHeaderToggle?: IFilterToggleButtonProps) {
		return filtersHeaderToggle && filtersHeaderToggle.showButton;
	}

	renderFiltersToggleButton(toggleButtonProps: IFilterToggleButtonProps | undefined, targetPosition: ToggleButtonTargetPosition) {
		if (!toggleButtonProps || !this.showToggleButton(toggleButtonProps)) {
			return null;
		}
		if (!this.filtersStore) {
			return null;
		}
		const { toggleShowFilters, filterToggleText } = this.filtersStore;
		const {
			selectedFilterCount,
			selectedFilterCountPosition,
			openFiltersIcon,
			openFiltersIconPosition,
			closeFiltersIcon,
			closeFiltersIconPosition,
			buttonPosition = ToggleButtonTargetPosition.FilterRow,
			mode = 'Modal',
		} = toggleButtonProps;

		let iconState: 'open' | 'closed' = 'open';
		let renderPosition: ToggleButtonTargetPosition | undefined;

		// Should the toggle button show within the modal? (if mode is modal)
		if (mode === 'Modal' && targetPosition === ToggleButtonTargetPosition.Modal) {
			renderPosition = ToggleButtonTargetPosition.Modal;
			iconState = 'closed';
		}
		// otherwise is the toggle button configured to show in the target position
		else if (targetPosition === buttonPosition) {
			renderPosition = targetPosition;
			// show open icon outside of modal
			if (mode === 'Modal') {
				iconState = 'open';
			}
			// if showing inline filters, the toggle button needs to update the icon its displaying
			else {
				iconState = this.filtersStore.showFilters ? 'closed' : 'open';
			}
		}
		// Validate if the component should show for the targetPosition
		if (!renderPosition) {
			return null;
		}

		// Icon Props
		const iconProps = iconState === 'open' ? openFiltersIcon : closeFiltersIcon;
		const iconPosition = iconState === 'open' ? openFiltersIconPosition : closeFiltersIconPosition;

		return (
			<FiltersHeaderToggle
				onClick={ toggleShowFilters }
				iconProps={ iconProps }
				iconPosition={ iconPosition }
			>
				{ (selectedFilterCount && selectedFilterCountPosition === 'left') && this.renderSelectedCount() }
				{ filterToggleText }
				{ (selectedFilterCount && selectedFilterCountPosition === 'right') && this.renderSelectedCount() }
			</FiltersHeaderToggle>
		);
	}

	renderSelectedCount() {
		if (!this.filtersStore) {
			return null;
		}
		const { totalFiltersSelected } = this.filtersStore;
		return (
			<FilterIndicator>{ totalFiltersSelected }</FilterIndicator>
		);
	}

	closeDialogIconProps(filtersHeaderToggle: IFilterToggleButtonProps) {
		if (filtersHeaderToggle && filtersHeaderToggle.closeDialogIcon) {
			return {
				...filtersHeaderToggle.closeDialogIcon,
			};
		}
		return {};
	}

	renderActiveFilters() {
		if (!this.filtersStore) {
			return null;
		}
		const { activeFiltersProps, activeFilterPills } = this.filtersStore;
		if (!activeFiltersProps.show || activeFilterPills.length <= 0) {
			return null;
		}
		return (
			<ActiveFiltersWrapper>
				<ActiveFiltersHeading>{ activeFiltersProps.heading }</ActiveFiltersHeading>
				{ activeFilterPills.map((pillProps) => {
					return <ActiveFilterPill { ...pillProps } />;
				}) }
			</ActiveFiltersWrapper>
		);
	}

	renderFilters(filterMode: TFiltersMode, toggleButtonProps?: IFilterToggleButtonProps) {
		if (!this.filtersStore) {
			return null;
		}

		// SHOW IF
		// !toggleButtonProps || !this.showToggleButton(toggleButtonProps)
		// OR
		// toggleButtonProps && this.showToggleButton(toggleButtonProps) && showFilters && toggleButtonProps.mode === 'InlineToggle'

		if (toggleButtonProps && this.showToggleButton(toggleButtonProps) && (!this.filtersStore.showFilters || toggleButtonProps.mode !== 'InlineToggle')) {
			return null;
		}

		return (
			<Fragment>
				{ filterMode === 'accordion' &&
					this.childFilters.map((filter) => {
						return <AccordionFilter { ...filter } />;
					}) }
				{ filterMode === 'dropdown' && (
					<ChildFilters>
						{ this.filtersStore.childFilters.map((filter) => {
							return <Filter key={ filter.code } { ...filter } />;
						}) }
					</ChildFilters>
				) }
			</Fragment>
		);
	}

	renderFiltersDialog(toggleButtonProps: IFilterToggleButtonProps | undefined, filterMode: TFiltersMode) {
		if (!this.filtersStore) {
			return null;
		}
		const { showFilters, hideIndicatorText, clearFilters, filterIndicatorText, handleClearFilters, toggleShowFilters } = this.filtersStore;

		// dialog cannot show when: there is no toggle button, we haven't toggled it or the toggle button mode is not Modal
		if (!this.dialogNode || !this.showToggleButton(toggleButtonProps) || !showFilters || !toggleButtonProps || toggleButtonProps.mode !== 'Modal') {
			return null;
		}

		return createPortal(
			(
				<FiltersDialog>
					<DialogHeader>
						{
							this.renderFiltersToggleButton(toggleButtonProps, ToggleButtonTargetPosition.Modal)
						}
						{ !hideIndicatorText && <FilterIndicator>{ filterIndicatorText }</FilterIndicator> }
						{ !clearFilters.hide && <ClearButton onClick={ handleClearFilters } text={ clearFilters.label } /> }
						<CloseButton
							onClick={ toggleShowFilters }
							iconProps={ this.closeDialogIconProps(toggleButtonProps || {}) }
						/>
					</DialogHeader>
					{ this.renderFilters(filterMode) }
				</FiltersDialog>
			),
			this.dialogNode as HTMLElement,
		);
	}

	renderLayout(options: IRenderLayoutOptions) {
		const {
			filterMode,
			filterToggleButton,
			hideIndicatorText,
			filterIndicatorText,
			clearFilters,
			handleClearFilters,
		} = options;
		return (
			<Fragment>
				<Toolbar>
					{ this.renderFiltersToggleButton(filterToggleButton, ToggleButtonTargetPosition.FilterRow) }
					{ !hideIndicatorText && <FilterIndicator>{ filterIndicatorText }</FilterIndicator> }
					{ !clearFilters.hide && <ClearButton onClick={ handleClearFilters } text={ clearFilters.label } suppressAnalytics={ true } /> }
				</Toolbar>
				{ this.renderActiveFilters() }
				{ this.renderFiltersDialog(filterToggleButton, filterMode) }
				{ this.renderFilters(filterMode, filterToggleButton) }
			</Fragment>
		);
	}

	render() {
		const { className, filtersStore } = this.props;

		if (!filtersStore || !filtersStore.hasFilters || filtersStore.hide) {
			return null;
		}

		const {
			mode: filterMode,
			filterIndicatorText,
			handleClearFilters,
			clearFilters,
			hideIndicatorText,
			filterToggleButton = {},
		} = filtersStore;

		return (
			<Wrapper className={ className }>
				{
					this.renderLayout({
						filterMode,
						filterIndicatorText,
						filterToggleButton,
						hideIndicatorText,
						clearFilters,
						handleClearFilters,
					})
				}
			</Wrapper>
		);
	}
}

export default InjectedStyledComponent(Filters, 'filters');
