import React, { Component } from 'react';
import {
	IIdentifierSearchProps,
	IIdentifierSearchState,
} from './models';
import { inject, observer } from 'mobx-react';
import { InjectedStyledComponent } from '../shared/InjectedStyledComponent';
import Wrapper from './styledComponents/Wrapper';
import IconInput from '@kurtosys/ksys-app-components/dist/components/base/IconInput';

@inject('appStore', 'identifierSearchStore')
@observer
export class IdentifierSearch extends Component<IIdentifierSearchProps, IIdentifierSearchState> {
	static configurationKey: 'identifierSearch' = 'identifierSearch';
	static styleKey: 'identifierSearch' = 'identifierSearch';
	constructor(props: IIdentifierSearchProps) {
		super(props);
	}
	render() {
		const { className, identifierSearchStore } = this.props;

		if (!identifierSearchStore) {
			return null;
		}

		const { inputProps } = identifierSearchStore;

		return (
			<Wrapper className={className}>
				<IconInput {...inputProps} />
		 	</Wrapper>
		);
	}
}

export default InjectedStyledComponent(IdentifierSearch, 'identifierSearch');

