import React, { Component, Fragment } from 'react';
import { IDateFilterProps, IDateFilterState } from './models';
import { inject, observer } from 'mobx-react';
import { InjectedStyledComponent } from '../shared/InjectedStyledComponent';
import Wrapper from './styledComponents/Wrapper';
import Window from './styledComponents/Window';
import Button from './styledComponents/Button';
import DateRangeInput from '@kurtosys/ksys-app-components/dist/components/base/DateRangeInput';
import ClickLayer from './styledComponents/ClickLayer';
import { computed } from 'mobx';
import { IDropdownItem } from '@kurtosys/ksys-app-components/dist/components/base/Dropdown/models';
import { utils } from '@kurtosys/ksys-app-template';

@inject('appStore', 'dateFilterStore', 'filtersStore')
@observer
export class DateFilter extends Component<IDateFilterProps, IDateFilterState> {
	static configurationKey: 'dateFilter' = 'dateFilter';
	static styleKey: 'dateFilter' = 'dateFilter';
	constructor(props: IDateFilterProps) {
		super(props);
	}

	render() {
		const { className, dateFilterStore, code, filtersStore } = this.props;

		if (!dateFilterStore) {
			return null;
		}
		const { dateRangeInputProps, iconProps, toggleWindow, currentCode } = dateFilterStore;

		return (
			<Wrapper className={className}>
				<Button iconProps={iconProps} onClick={() => toggleWindow(code)}>
					{(filtersStore &&
						filtersStore.dateSelectionsByCode &&
						filtersStore.dateSelectionsByCode[code] &&
						`${filtersStore.dateSelectionsByCode[code].startDate} - ${filtersStore.dateSelectionsByCode[code].endDate}`) ||
						'Select a Date'}
				</Button>
				{code === currentCode && (
					<Fragment>
						<ClickLayer onClick={toggleWindow} />
						<Window>
							<DateRangeInput {...dateRangeInputProps} />
						</Window>
					</Fragment>
				)}
			</Wrapper>
		);
	}
}

export default InjectedStyledComponent(DateFilter, 'dateFilter');
