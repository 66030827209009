import { computed, action, observable } from 'mobx';
import { StoreBase } from '../../../common/StoreBase';
import { ISavedSearchConfiguration, ISavedSearchFeedback, ISavedSearchLabels } from '../models';
import { ISavedSearchProps } from '@kurtosys/ksys-app-components/dist/components/base/SavedSearch/models';
import { ISavedSearchListProps } from '@kurtosys/ksys-app-components/dist/components/base/SavedSearchList/models';
import { IDocumentSavedSearch, IDocumentSearchClause, TNotificationFrequency } from '../../../models/commonTypes';
import { utils } from '@kurtosys/ksys-app-template';

const { replacePlaceholders } = utils;
const { isNullOrEmpty } = utils.typeChecks;

export class SavedSearchStore extends StoreBase {
	static componentKey: 'savedSearch' = 'savedSearch';

	@observable savedSearchName: string = '';
	@observable listFilter: string = '';
	@observable listPagingStart: number = 0;
	@observable listPagingTotal: number = 0;
	@observable savedSearches: IDocumentSavedSearch[] = [];
	@observable currentSearch: IDocumentSavedSearch | undefined;
	@observable showInput: boolean = false;

	@computed
	get configuration(): ISavedSearchConfiguration | undefined {
		if (this.storeContext && this.storeContext.appStore) {
			return this.storeContext.appStore.getComponentConfiguration(SavedSearchStore.componentKey);
		}
	}

	@computed
	get listPagingSize(): number {
		return (this.configuration && this.configuration.listPagingSize) || 5;
	}

	@computed
	get hide(): boolean {
		return !!(this.configuration && this.configuration.hide);
	}

	@computed
	get labels(): Required<ISavedSearchLabels> {
		const defaultLabels = {
			nameInputPlaceholder: 'Saved Search Name',
			pagingLabelFormat: '{firstItem} - {lastItem} / {totalRows}',
			listSearchPlaceholder: 'Filter Saved Searches',
		};
		const configLabels = this.configuration && this.configuration.labels;
		return {
			...defaultLabels,
			...(configLabels || {}),
		};
	}

	@computed
	get feedback(): Required<ISavedSearchFeedback> {
		const defaultLabels = {
			createSearchSuccess: `Successfully created search '{savedSearchName}'`,
			updateSearchSuccess: `Successfully updated search '{savedSearchName}'`,
			deleteSearchSuccess: `Successfully deleted search '{savedSearchName}'`,
			duplicateNameError: `A search with the name '{savedSearchName}' already exists`,
		};
		const configLabels = this.configuration && this.configuration.feedback;
		return {
			...defaultLabels,
			...(configLabels || {}),
		};
	}

	@action
	async initialize(): Promise<void> {
		await this.fetchSavedSearches();
	}

	@action
	fetchSavedSearches = async () => {
		const { kurtosysApiStore } = this.storeContext;
		try {
			const savedSearchResponse = await kurtosysApiStore.listSavedSearches.execute({
				body: {
					savedSearchName: this.listFilter,
				},
				disableCachableRequests: true,
				disableCache: true,
			});
			this.savedSearches = savedSearchResponse.results;
			this.listPagingTotal = savedSearchResponse.total;
		}
		catch (ex) {
			console.warn('Problem Fetching Saved Searches:', ex);
		}
	}

	@computed
	get savedSearchProps(): ISavedSearchProps {
		return {
			input: {
				placeholder: this.labels.nameInputPlaceholder,
				value: this.savedSearchName,
				onChange: this.onChangeNameInput,
			},
			onSave: this.onSave,
			onToggle: this.onToggle,
			showInput: this.showInput,
			saveButtonDisabled: this.saveButtonDisabled,
			savedSearchList: this.savedSearchListProps,
			suppressAnalytics: true,
		};
	}

	@computed
	get hasSearchValues(): boolean {
		const { filtersStore, facetedSearchStore } = this.storeContext;
		const hasFacets = facetedSearchStore.searchTerms.length > 0;
		const hasFilters = filtersStore.searchForFilters.length > 0;
		return hasFacets || hasFilters;
	}

	@computed
	get saveButtonDisabled(): boolean {
		return isNullOrEmpty(this.savedSearchName) || !this.hasSearchValues;
	}

	@computed
	get currentPage(): IDocumentSavedSearch[] {
		return [...this.savedSearches].splice(this.listPagingStart, this.listPagingSize);
	}

	@computed
	get savedSearchListProps(): ISavedSearchListProps {
		return {
			savedSearches: this.currentPage,
			paging: {
				currentIndex: this.listPagingStart,
				totalRows: this.listPagingTotal,
				pageSize: this.listPagingSize,
				pageBackward: (currentIndex: number) => (this.listPagingStart = currentIndex),
				pageForward: (currentIndex: number) => (this.listPagingStart = currentIndex),
				pagingLabelFormat: this.labels.pagingLabelFormat,
			},
			search: {
				placeholder: this.labels.listSearchPlaceholder,
				value: this.listFilter,
				iconProps: {
					asset: 'base.search.magnificationGlass',
				},
				onChange: this.onChangeListSearch,
			},
			onClick: this.onClick,
			onDelete: this.onDelete,
			onToggleFrequency: this.onToggleFrequency,
		};
	}

	@action
	onChangeNameInput = async (e: React.ChangeEvent<HTMLInputElement>) => {
		this.savedSearchName = e.target.value;
	}

	@action
	onChangeListSearch = async (e: React.ChangeEvent<HTMLInputElement>) => {
		this.listFilter = e.target.value;
		await this.fetchSavedSearches();
		this.listPagingStart = 0;
	}

	@action
	onSave = async () => {
		const { appStore, feedbackStore, translationStore } = this.storeContext;
		feedbackStore.clear();
		if (!isNullOrEmpty(this.savedSearchName) && this.hasSearchValues) {
			const savedSearch: IDocumentSavedSearch = {
				savedSearchId: this.currentSearch && this.currentSearch.savedSearchId,
				savedSearchName: this.savedSearchName,
				notificationFrequency: (this.currentSearch && this.currentSearch.notificationFrequency) || 'off',
				type: this.currentSearch && this.currentSearch.type,
				search: appStore.searchDocumentsBody,
			};

			// When saving the search, we only want to include search terms that have actually been selected,
			// and not terms that have come from the config or the filters section
			// Values from filters must be added separately to the search object
			if (typeof savedSearch.search !== 'string') {
				savedSearch.search.search = appStore.getSearch(true, false, true);
				savedSearch.search.filters = appStore.getSearch(true, true, false);
			}

			const isValid = await this.validateSearchBeforeUpsert();
			if (!isValid) {
				const message = translationStore.translate(this.feedback.duplicateNameError, savedSearch);
				feedbackStore.error(message);
				return;
			}
			const response = await this.upsertSavedSearch(savedSearch);
			await this.fetchSavedSearches();
			const message = translationStore.translate(
				this.currentSearch ? this.feedback.updateSearchSuccess : this.feedback.createSearchSuccess,
				savedSearch,
			);
			feedbackStore.success(message);
			this.currentSearch = response;
		}
	}

	@action
	onToggle = async (isOpen: boolean) => {
		this.showInput = isOpen;
		if (!isOpen) {
			this.currentSearch = undefined;
			this.savedSearchName = '';
		}
	}

	@action
	onClick = (savedSearch: IDocumentSavedSearch) => {
		this.loadSearch(savedSearch);
		this.showInput = true;
	}

	@action
	onDelete = async (savedSearch: IDocumentSavedSearch) => {
		const { feedbackStore, translationStore, appStore } = this.storeContext;
		feedbackStore.clear();
		if (savedSearch.savedSearchId) {
			await this.deleteSavedSearch(savedSearch.savedSearchId);
			if (appStore.analyticsHelper) {
				appStore.analyticsHelper.logEvent({
					event: 'saved_search_delete',
					eventType: 'click',
					context: {
						searchName: savedSearch.savedSearchName,
						searchId: savedSearch.savedSearchId,
					},
				});
			}
			await this.fetchSavedSearches();
			const maxPage = Math.ceil(this.listPagingTotal / this.listPagingSize);
			if (this.listPagingStart > maxPage) {
				this.listPagingStart = 0;
			}
			const message = translationStore.translate(this.feedback.deleteSearchSuccess, savedSearch);
			feedbackStore.success(message);
			this.currentSearch = undefined;
			this.savedSearchName = '';
		}
	}

	@action
	onToggleFrequency = async (savedSearch: IDocumentSavedSearch, notificationFrequency: TNotificationFrequency) => {
		const { feedbackStore, translationStore } = this.storeContext;
		feedbackStore.clear();
		const response = await this.upsertSavedSearch({ ...savedSearch, notificationFrequency });
		if (this.currentSearch && this.currentSearch.savedSearchId === savedSearch.savedSearchId) {
			this.currentSearch = response;
		}
		await this.fetchSavedSearches();
		const message = translationStore.translate(this.feedback.updateSearchSuccess, savedSearch);
		feedbackStore.success(message);
	}

	@action
	loadSearch = (savedSearch: IDocumentSavedSearch) => {
		const { search } = savedSearch;
		if (typeof search !== 'string') {
			const { facetedSearchStore, filtersStore } = this.storeContext;
			filtersStore.handleClearFilters(false);
			facetedSearchStore.loadSelectedResults(search);
			this.currentSearch = savedSearch;
			this.savedSearchName = savedSearch.savedSearchName;
		}
	}

	@action
	deleteSavedSearch = async (savedSearchId: number) => {
		const { kurtosysApiStore } = this.storeContext;
		const overrideOptions = {
			disableCachableRequests: true,
			body: {
				savedSearchId,
			},
		};
		return await kurtosysApiStore.deleteSavedSearch.execute(overrideOptions);
	}

	@action
	async validateSearchBeforeUpsert() {
		const { kurtosysApiStore } = this.storeContext;
		const savedSearchResponse = await kurtosysApiStore.listSavedSearches.execute({
			body: {
				savedSearchName: this.savedSearchName,
			},
		});
		const match = savedSearchResponse.results.find((item) => item.savedSearchName === this.savedSearchName);
		const currentSearchId = this.currentSearch && this.currentSearch.savedSearchId;
		return !(match && match.savedSearchId !== currentSearchId);
	}

	@action
	upsertSavedSearch = async (savedSearch: IDocumentSavedSearch) => {
		const { kurtosysApiStore, appStore } = this.storeContext;
		const overrideOptions = {
			disableCachableRequests: true,
			body: savedSearch,
		};
		const response = await kurtosysApiStore.upsertSavedSearch.execute(overrideOptions);
		if (appStore.analyticsHelper) {
			const search = savedSearch.search;
			let context: object = {
				searchName: savedSearch.savedSearchName,
				searchId: response.savedSearchId,
			};
			if (typeof (search) === 'string') {
				context = {
					...context,
					filters: [],
					search: [search],
				};
			}
			else {
				context = {
					...context,
					filters: this.describeSearchClauses(search.filters || []),
					search: this.describeSearchClauses(search.search),
				};
			}
			appStore.analyticsHelper.logEvent({
				context,
				event: 'saved_search_upsert',
				eventType: 'click',
			});
		}
		return response;
	}

	describeSearchClauses = (search: IDocumentSearchClause[]): string[] => {
		return search.map(clause => {
			let description = `${ clause.property } ${ clause.matchtype } `;
			if (clause.matchtype === 'RANGE') {
				description += '"' + clause.values.join(' - ') + '"';
			} else if (clause.values.length === 1) {
				description += '"' + clause.values[0] + '"';
			} else {
				description += '("' + clause.values.join('", "') + '")';
			}
			return description;
		});
	}

}
