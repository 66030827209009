import { IIconProps } from '@kurtosys/ksys-app-components/dist/components/base/Icon/models';
import { IIconInputProps } from '@kurtosys/ksys-app-components/dist/components/base/IconInput/models';
import { computed, action, observable } from 'mobx';
import { StoreBase } from '../../../common/StoreBase';
import { IQueryStringObject } from '../../../models/app/IQueryStringObject';
import { IQueryStringOptions } from '../../../models/app/IQueryStringOptions';
import { TIdentifierSearch } from '../../../models/commonTypes';
import { QueryString } from '../../../utils/QueryString';
import { IIdentifierSearchConfiguration, TIdentifierSearchDefinition } from '../models';

export class IdentifierSearchStore extends StoreBase {
	static componentKey: 'identifierSearch' = 'identifierSearch';

	@observable searchTerm: string = '';
	queryString: QueryString | undefined;
	@observable.ref hasLoadedQueryString = false;
	timeout: NodeJS.Timeout | undefined;

	@computed
	get configuration(): IIdentifierSearchConfiguration | undefined {
		if (this.storeContext && this.storeContext.appStore) {
			return this.storeContext.appStore.getComponentConfiguration(IdentifierSearchStore.componentKey);
		}
	}

	@computed
	get hide(): boolean {
		return !!(this.configuration && this.configuration.hide);
	}

	@computed
	get pillLabel(): string {
		return (this.configuration && this.configuration.pillLabel) || '{total} documents';
	}

	@action
	async initialize(): Promise<void> {
		this.queryString = new QueryString(this.queryStringOptions, {
			enabled: false,
			prefix: 'identifier_',
		});
		this.setInitialSearchTerm();
	}

	@action
	setInitialSearchTerm = () => {
		if (!this.hasLoadedQueryString && this.queryString && this.queryString.enabled) {
			const searchTermFromQueryString = this.queryString.fetch(true);
			if (searchTermFromQueryString && typeof searchTermFromQueryString.searchTerm === 'string') {
				this.setSearchTerm(searchTermFromQueryString.searchTerm);
			}
		}
	}

	@computed
	get iconProps(): IIconProps | undefined {
		return this.configuration && this.configuration.iconProps;
	}

	@computed
	get queryStringOptions(): IQueryStringOptions {
		return (this.configuration && this.configuration.queryString) || {};
	}

	@computed
	get onChangeMillisecondTimeout(): number {
		return (this.configuration && this.configuration.onChangeMillisecondTimeout) || 1000;
	}

	@computed
	get minCharacterTrigger(): number {
		return (this.configuration && this.configuration.minCharacterTrigger) || 3;
	}

	@computed
	get placeholder(): string {
		return (this.configuration && this.configuration.placeholder) || 'Search...';
	}

	@computed
	get showClear(): boolean {
		return !!(this.configuration && this.configuration.showClear);
	}

	@computed
	get definitions(): TIdentifierSearchDefinition[] {
		return (this.configuration && this.configuration.definitions) || [];
	}

	@computed
	get identifierSearches(): TIdentifierSearch[] {
		return this.definitions.map((definition) => {
			return {
				...definition,
				value: this.searchTerm,
			};
		});
	}

	@computed
	get hasSearch(): boolean {
		return !this.hide && this.searchTerm.length > 0;
	}

	@action
	updateQueryString = () => {
		if (this.queryString && this.queryString.enabled) {
			const filtersQueryStringObject: IQueryStringObject = this.hasSearch ? { searchTerm: this.searchTerm } : {};
			this.queryString.update(filtersQueryStringObject);
		}
	}

	@action
	setSearchTerm(searchTerm: string) {
		this.searchTerm = searchTerm;
	}

	@action
	onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const searchTerm = e.target.value;
		this.changeSearchTerm(searchTerm);
	}

	@action
	changeSearchTerm = (searchTerm: string) => {
		this.setSearchTerm(searchTerm);
		this.updateQueryString();

		if (searchTerm.length >= this.minCharacterTrigger || searchTerm.length === 0) {
			const { appStore, tableStore, pagingStore, filtersStore } = this.storeContext;
			// debounce
			if (this.timeout) {
				clearTimeout(this.timeout);
				this.timeout = undefined;
			}
			this.timeout = setTimeout(() => {
				tableStore.clearSelectedFields();
				pagingStore.resetToFirstPage(false);
				filtersStore.getFilters();
				appStore.fetchDocuments();
				if (appStore.analyticsHelper) {
					appStore.analyticsHelper.logEvent({
						event: 'search',
						eventType: 'click',
						context: {
							searchTerms: searchTerm === '' ? {} : { identifier: searchTerm },
						},
					});
				}
			}, this.onChangeMillisecondTimeout);
		}
	}

	@action
	clear() {
		this.searchTerm = '';
	}

	@computed
	get inputProps(): IIconInputProps {
		const { appStore, translationStore } = this.storeContext;
		return {
			placeholder: this.placeholder,
			value: this.searchTerm,
			iconProps: this.iconProps,
			onChange: this.onChange,
			showClear: this.showClear,
			onClear: () => {
				this.changeSearchTerm('');
			},
			pillProps: {
				pillPosition: 'right',
				value: translationStore.translate(this.pillLabel, { total: appStore.total }),
			},
		};
	}
}