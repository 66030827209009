import { computed, action } from 'mobx';
import { StoreBase } from '../../../common/StoreBase';
import { IFilterConfiguration } from '../models';
import { IDropdownItem } from '@kurtosys/ksys-app-components/dist/components/base/Dropdown/models/IDropdownItem';

export class FilterStore extends StoreBase {
	static componentKey: 'filter' = 'filter';

	@computed
	get configuration(): IFilterConfiguration | undefined {
		if (this.storeContext && this.storeContext.appStore) {
			return this.storeContext.appStore.getComponentConfiguration(FilterStore.componentKey);
		}
	}

	@action
	async initialize(): Promise<void> {

	}

	@action
	setSelectedFilters = (property: string, selection: IDropdownItem | IDropdownItem[]) => {
		const { filtersStore } = this.storeContext;
		filtersStore.setSelectedFilters(property, selection);
	}
}