import { computed, action } from 'mobx';
import { StoreBase } from '../../../common/StoreBase';
import { IAccordionFilterConfiguration, IClearFilterProps, IHeaderPillProps, ISearchProps } from '../models';

export class AccordionFilterStore extends StoreBase {
	static componentKey: 'accordionFilter' = 'accordionFilter';

	@computed
	get configuration(): IAccordionFilterConfiguration | undefined {
		if (this.storeContext && this.storeContext.appStore) {
			return this.storeContext.appStore.getComponentConfiguration(AccordionFilterStore.componentKey);
		}
	}

	@action
	async initialize(): Promise<void> {}

	@computed
	get searchProps(): Required<ISearchProps> {
		const defaultValue: Required<ISearchProps> = { show: true, placeholder: 'Search Query' };
		return { ...defaultValue, ...(this.configuration && this.configuration.search) };
	}

	@computed
	get clearFilterProps(): Required<IClearFilterProps> {
		const defaultValue: Required<IClearFilterProps> = {
			show: false,
			iconProps: {
				asset: 'base.dialog.close',
			},
		};
		return { ...defaultValue, ...(this.configuration && this.configuration.clearFilter) };
	}

	@computed
	get headerPillProps(): Required<IHeaderPillProps> {
		const defaultValue: Required<IHeaderPillProps> = { show: true, label: '{selected}/{total}' };
		return { ...defaultValue, ...(this.configuration && this.configuration.headerPill) };
	}
}
