import { observable, action, computed } from 'mobx';
import { query, utils } from '@kurtosys/ksys-app-template';
import { QueryContext } from '@kurtosys/ksys-api-client/dist/query/QueryContext';
import { QueryStore as QueryStoreBase } from '@kurtosys/ksys-app-components/dist/shared/QueryStore';
import { IQueryContextClient } from '@kurtosys/ksys-api-client/dist/models/query/IQueryContextClient';
import { StoreContext } from '../../../configuration/StoreContext';
import { IEntitiesRequestOptions } from '../../../models/app/IEntitiesRequestOptions';
import { IQueryOptions, IExecutionOptions, IQueryContext, IQueryContextCulture, IBatchPromiseElement, IBatchOptions, IFundManager, IStatistics, IQueryContextConfiguration } from '../../../models/commonTypes';
import { IConfigurationComponentOrchestration } from '../../../models/app/IConfigurationComponentOrchestration';
const Query = query.Query;
export class QueryStore extends QueryStoreBase {
	storeContext: StoreContext;
	@observable.ref context: IQueryContext | undefined = undefined;
	@observable.ref fundManagerContext: IFundManager | undefined = undefined;
	@observable.ref responseContext: object | undefined = undefined;
	@observable.ref statisticContext: IStatistics | undefined = undefined;
	@observable.ref private updatedInputs: Record<string, any> = {};

	constructor(storeContext: StoreContext) {
		super();
		this.storeContext = storeContext;
	}

	@computed
	get client(): IQueryContextClient {
		const { kurtosysApiStore } = this.storeContext;
		return kurtosysApiStore.client;
	}

	@computed
	get inputs(): Record<string, any> {
		const { appStore } = this.storeContext;
		const { appParamsHelper } = appStore;
		return { ...appParamsHelper.inputs, ...this.updatedInputs };
	}

	@computed
	get executionOptions(): IExecutionOptions {
		const { appStore, translationStore } = this.storeContext;
		return {
			applicationGuid: appStore.applicationGuid,
			defaultContextParentKey: this.defaultContextParentKey,
			context: this.context,
			responseContext: this.responseContext,
			fundManagerContext: this.fundManagerContext,
			statisticContext: this.statisticContext,
			culture: appStore.getInput('culture'),
			fallbackCulture: appStore.configuration && appStore.configuration.culture && appStore.configuration.culture.default,
			translate: translationStore.translate,
			inputs: this.inputs,
			serviceUrl: appStore.serviceUrl,
			globalFormatsByKey: appStore.globalFormatsByKey,
		};
	}
	get defaultContextParentKey(): string {
		const { appStore } = this.storeContext;
		const { configuration, storeKey } = appStore;
		let response: string = storeKey;
		if (configuration) {
			const { data } = configuration;
			if (data) {
				const { key } = data;
				if (key) {
					response = key;
				}
			}
		}
		return response;
	}
	@action async initialize() {
		const { appStore, inputStore } = this.storeContext;
		const { configuration } = appStore;
		if (configuration && configuration.data && configuration.data.context) {
			const contexts = Array.isArray(configuration.data.context) ? configuration.data.context : [configuration.data.context];
			const globalInputs = await inputStore.getAllFromStorage();
			const inputs = { ...this.inputs, ...globalInputs };
			await this.loadContexts(contexts, inputs);
		}
	}

	@action async updateInputs(inputOverrides?: any) {
		const { appStore } = this.storeContext;
		const { configuration } = appStore;
		const inputs = { ...this.inputs, ...inputOverrides };
		appStore.logDebug('Query Store > Update Inputs', inputs);
		if (configuration && configuration.data && configuration.data.context) {
			const contexts = Array.isArray(configuration.data.context) ? configuration.data.context : [configuration.data.context];
			await this.loadContexts(contexts, inputs);
		}
		this.updatedInputs = inputs;
		appStore.contextsDidUpdate();
	}

	@action
	async loadContexts(contexts: IQueryContextConfiguration[], inputs?: Record<string, any>) {
		const { appStore, kurtosysApiStore } = this.storeContext;
		const { configuration, dataContextSeed } = appStore;
		const batchPromises: IBatchPromiseElement[] = [];
		const queryContextCulture: IQueryContextCulture = {
			sourceCulture: (configuration && configuration.culture && configuration.culture.base) || 'en-GB',
			targetCulture: (inputs && inputs.culture) || (configuration && configuration.culture && configuration.culture.default) || 'en-GB',
		};
		let contextCounter = 0;
		for (const context of contexts) {
			const queryContext = new QueryContext(context, dataContextSeed);
			const defaultContextKey = `default${ contextCounter > 0 ? contextCounter + 1 : '' }`;
			if (!context.contextKey) {
				context.contextKey = defaultContextKey;
			}
			batchPromises.push({
				identifier: {
					contextParentKey: (configuration && configuration.data && configuration.data.key) || this.defaultContextParentKey,
					contextKey: context.contextKey,
				},
				promiseFunc: () => queryContext.fetch(kurtosysApiStore.client, inputs || {}, queryContextCulture),
				responseFunc: (response: any, identifier: any) => {
					appStore.logDebug('Query Store > new query context', response);
					query.Query.contextStore.set({
						...identifier,
						value: {
							context: response,
							contextConfiguration: context,
						},
					});
				},
			});
			contextCounter++;
		}
		const batchOptions: IBatchOptions = {
			maxBatchSize: 10,
		};
		await utils.promise.batchExecutePromises(batchPromises, batchOptions);
	}

	query(options?: IQueryOptions, overrideExecutionOptions?: Partial<IExecutionOptions>) {
		if (options) {
			const query = this.createQuery(options);
			const workingExecutionOptions = query.mergeExecutionOptions(this.executionOptions, overrideExecutionOptions || {});
			const queryResponse = query.execute(workingExecutionOptions);
			return queryResponse;
		}
	}

	getMergedExecutionOptions = (overrideExecutionOptions?: Partial<IExecutionOptions>, queryInstance?: query.Query): IExecutionOptions => {
		if (!queryInstance) {
			queryInstance = this.createQuery();
		}
		return queryInstance.mergeExecutionOptions(this.executionOptions, overrideExecutionOptions || {});
	}

	createQuery = (options: IQueryOptions = { queryOptionsType: "none" }) => {
		const { translationStore } = this.storeContext;
		const translationHelper = translationStore && translationStore.translationHelper;
		return new Query(options, translationHelper);
	}


	async entitiesRequest(id: string, inputs: object, contextParentKey: string = this.defaultContextParentKey, contextKey: string = 'default', options?: IEntitiesRequestOptions) {
		const contextAndConfiguration = query.Query.contextStore.get({ contextParentKey, contextKey });
		const { contextConfiguration } = contextAndConfiguration;
		if (contextConfiguration) {
			const { appStore } = this.storeContext;
			const { dataContextSeed } = appStore;
			const queryContext = new QueryContext(contextConfiguration, dataContextSeed);
			if (queryContext && contextConfiguration) {
				let fetchEntitiesOptions;
				const executionOptions = this.executionOptions;
				if (options) {
					fetchEntitiesOptions = options.fetchEntitiesOptions;
					if (options.overrideDynamicInputs === true) {
						if (!executionOptions.inputs) {
							executionOptions.inputs = {};
						}
						Object.assign(executionOptions.inputs, inputs);
					}
				}
				return await queryContext.fetchEntities(this.client, id, inputs, contextConfiguration, executionOptions, fetchEntitiesOptions);
			}
		}
	}

	mergeQueriesAndProps<T>(componentOrchestration?: IConfigurationComponentOrchestration, responseContext?: any): T | undefined {
		if (componentOrchestration) {
			const { translationStore } = this.storeContext;
			const { props, queries } = componentOrchestration;
			const translationHelper = translationStore && translationStore.translationHelper;
			let workingExecutionOptions = this.executionOptions;
			if (responseContext) {
				workingExecutionOptions = {
					...workingExecutionOptions,
					responseContext,
				};
			}
			const queryResults = queries ? query.Query.convertQueryMime(queries, workingExecutionOptions, { translationHelper }) : {};
			const response: T = utils.object.deepMergeObjectsWithOptions({ arrayMergeStrategy: 'DeepMerge' }, props || {}, queryResults);
			return response;
		}
	}
}