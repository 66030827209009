import { ToggleButtonTargetPosition } from '../components/Filters/models';
import { IConfiguration } from '../models/app/IConfiguration';

export const CONFIGURATION: IConfiguration = {
	components: {
		accordionFilter: { clearFilter: { iconProps: { asset: 'base.dialog.close' }, show: true } },
		app: {
			cardProps: {
				props: {
					subtitle:
						'Search and download your documents here. You can filter by type, date, and create saved searches.',
					title: 'Document Hub',
				},
			},
			documentSearchRequest: {
				include: { entity: { joinSpecs: [{ entityType: 'CLSS', joinFrom: 'isin' }] } },
				limit: 50,
				search: [],
				identifierSearchOperator: 'OR',
				sort: {
					direction: 'ASC',
					key: 'document_type',
				},
			},
			filename: {
				queryOptionsType: 'none',
				subQueries: [
					{
						key: 'document.not_filename',
						queryId: 'filename',
						queryOptionsType: 'response',
						transforms: [{ splitSeparator: '.', transformOptionsType: 'split' }],
					},
				],
				value: '{sub:filename[0]}',
			},
			noDataOptions: {
				noDataPlaceholder: 'Found 0 results - please try another search term and/or filter selection',
			},
			searchType: 'faceted', // 'faceted' or 'identifier'
			applicationId: 'ksys-document-explorer',
			listenFor: [{
				appId: 'ksys-global-inputs',
				eventTypes: [{
					name: 'ksys-global-inputs-change',
					requiredForInitialLoad: true
				}],
			}]
		},
		bulkDownload: { badge: { show: true } },
		bulkUrlCopy: {
			badge: { show: true },
			buttonText: 'Copy URL',
			copyUrlOptions: {
				query: {
					queryOptionsType: 'none',
					subQueries: [{ key: 'URL', queryId: 'clientCode', queryOptionsType: 'document' }],
					value: 'https://www.nordea.lu/documents/{sub:clientCode}',
				},
			},
			separator: '\n',
			show: true,
		},
		clearSearchAndFilters: { buttonText: 'Clear Search & Filters', show: true },
		dateFilter: {
			dateRangeInputProps: {
				formatting: {
					dateInputFormat: { day: '2-digit', month: 'short', year: 'numeric' },
					locale: 'en-GB',
					selectedDateFormat: { dateFormatString: 'ddd, MMM DD' },
				},
			},
		},
		dateSearch: {
			dateRangeInputProps: {
				dateFieldItems: [
					{ label: 'Created By', value: 'created' },
					{ label: 'Last Modified By', value: 'lastModified' },
				],
				formatting: {
					dateInputFormat: { day: '2-digit', month: 'short', year: 'numeric' },
					locale: 'en-GB',
					selectedDateFormat: { dateFormatString: 'ddd, MMM DD' },
				},
			},
		},
		// documentPreview: { type: 'newTab' },
		exportToCsv: {
			columns: [
				{
					format: {
						options: {
							day: '2-digit',
							month: '2-digit',
							placeholderKey: 'date',
							placeholderText: 'as at {date}',
							year: 'numeric',
						},
						type: 'date',
					},
					header: 'Created2',
					id: 'created',
					key: 'created',
					tableColumnId: 'created',
				},
				{ id: 'inferredTest', key: 'inferredTest', tableColumnId: 'inferredTest' },
				{ id: 'inferredTest2', key: 'inferredTest2', tableColumnId: 'inferredTest2' },
				{ id: 'clientCode', key: 'clientCode', tableColumnId: 'clientCode' },
				{ id: 'title', key: 'title', tableColumnId: 'title' },
				{ id: 'documentType', key: 'documentType', tableColumnId: 'documentType' },
				{ id: 'document_category', key: 'document_category', tableColumnId: 'document_category' },
				{ id: 'cultureCode', key: 'cultureCode', tableColumnId: 'cultureCode' },
				{
					header: 'ISIN2',
					id: 'isin',
					key: 'isin',
					valueQuery: { childKey: 'isin', key: 'meta', queryOptionsType: 'document' },
				},
			],
			filenameQuery: {
				queryOptionsType: 'none',
				subQueries: [{ options: { dynamic: { key: 'date' } }, queryId: 'date', queryOptionsType: 'dynamic' }],
				value: 'Document Meta {sub:date}',
			},
			separator: ',',
			show: true,
		},
		facetedSearch: {
			allowedFields: ['filename', 'document_type', 'title', 'cultureCode', 'regions'],
			queryString: { enabled: true },
			searchDocumentsLimits: { excludeFacetedSearch: true },
		},
		feedback: { displaySeconds: 1, mode: 'toast' },
		filters: {
			activeFiltersProps: { heading: 'Active filters', show: true },
			childFilters: [
				{ code: 'document_category', label: 'Category' },
				{
					code: 'document_type',
					label: 'Document Type',
					searchInput: { caseSensitive: true, enable: true, minItems: 0, placeholder: 'Filter items' },
				},
				{
					code: 'created',
					label: 'Date Created',
					dateFilter: true,
				},
				{
					code: 'regions',
					label: 'Regions',
					searchInput: { caseSensitive: true, enable: true, minItems: 3, placeholder: 'Filter items' },
				},
			],
			clearFilters: {
				label: 'Clear Filters',
			},
			filterToggleButton: {
				closeDialogIcon: { iconOptions: { filename: { src: 'x.svg' } } },
				closeFiltersIcon: { iconOptions: { filename: { src: 'SuccessIcon.svg' } } },
				closeFiltersIconPosition: 'left',
				openFiltersIcon: { iconOptions: { filename: { src: 'SuccessIcon.svg' } } },
				buttonPosition: ToggleButtonTargetPosition.SearchBarRow,
				openFiltersIconPosition: 'right',
				mode: 'InlineToggle',
				showButton: true,
				selectedFilterCount: true,
				selectedFilterCountPosition: 'right',
				expandByDefault: true
			},
			indicatorText: { multipleFilters: '{total} filter(s) selected', noFilters: 'No filters selected' },
			pinSelected: true,
			queryString: { enabled: true },
			useCounts: true,
		},
		identifierSearch: {
			definitions: [
				{ properties: ['document_type', 'document_category'], target: 'document' },
				{ entityType: 'CLSS', properties: ['short_name', 'fund_name'], target: 'entity' },
				{ properties: ['test'], target: 'document' },
			],
			iconProps: { asset: 'base.search.magnificationGlass' },
			placeholder: 'Search document type or category...',
		},
		paging: { initialPageSize: 25 },
		savedSearch: { listPagingSize: 2 },
		searchRow: {
			searchRowOrder: [
				'IdentifierSearch',
				'FacetedSearch',
				'DateSearch',
				'SavedSearch',
				'ClearSearchAndFilters',
				'BulkDownload',
				'BulkCopyUrl',
				'FilterToggle',
				'ExportToCsv',
			],
		},
		table: {
			actions: {
				copyUrl: { key: 'copy-url', label: 'Copy URL' },
				download: { key: 'download', label: 'Download' },
				preview: { key: 'preview', label: 'Preview' },
			},
			actionsType: 'buttons',
			columns: [
				{
					header: 'Year',
					id: 'year',
					key: 'year',
					valueQuery: {
						format: { options: { dateFormatString: 'YYYY', year: 'numeric' }, type: 'date' },
						key: 'lastModified',
						queryOptionsType: 'document',
					},
				},
				{
					header: 'Client Code',
					id: 'clientCode',
					key: 'clientCode',
					tooltips: [
						{
							content: 'Internal Use Only',
							fixedTooltip: false,
							iconProps: { asset: 'base.tooltip.defaultIcon' },
							position: 'top',
							showTooltipArrow: true,
							target: 'row',
							trigger: 'click',
						},
					],
					valueQuery: { key: 'clientCode', queryOptionsType: 'document' },
				},
				{
					header: 'Title',
					id: 'title',
					key: 'title',
					sort: { enabled: true },
					valueQuery: { key: 'title', queryOptionsType: 'document' },
				},
				{
					header: 'Document Type',
					id: 'documentType',
					key: 'documentType',
					sort: { enabled: true },
					valueQuery: { childKey: 'document_type', key: 'meta', queryOptionsType: 'document' },
				},
				{
					header: 'Test (Inferred)',
					id: 'test',
					key: 'test',
					sort: { enabled: true },
					valueQuery: { childKey: 'test', key: 'inferred', queryOptionsType: 'document' },
				},
				{
					header: 'Short Name',
					id: 'short_name',
					key: 'short_name',
					valueQuery: {
						key: 'short_name',
						options: { documentEntity: { property: { code: 'short_name' } } },
						queryOptionsType: 'documentEntity',
					},
				},
				{
					header: 'Fund Name',
					id: 'fund_name',
					key: 'fund_name',
					valueQuery: {
						key: 'fund_name',
						options: { documentEntity: { property: { code: 'fund_name' } } },
						queryOptionsType: 'documentEntity',
					},
				},
				{
					header: 'Culture',
					id: 'cultureCode',
					key: 'cultureCode',
					valueQuery: { key: 'cultureCode', queryOptionsType: 'document' },
				},
			],
			copyUrlOptions: {
				query: {
					queryOptionsType: 'none',
					subQueries: [{ key: 'URL', queryId: 'clientCode', queryOptionsType: 'document' }],
					value: 'https://www.nordea.lu/documents/{sub:clientCode}',
				},
			},
			grouping: {
				autoExpandAllGroups: true,
				autoExpandGroupsByField: ['KIID'],
				field: 'documentType',
				sort: { direction: 'ASC', key: 'documentType' },
				tableGrouping: {
					alignExpander: 'right',
					cardGroupingStyle: { background: { color: '#0000A0' }, color: 'white' },
					getRowsQuery: {
						options: {
							documents: {
								collectionItemValueConditional: {
									conditions: [
										{
											field: 'meta.document_type.value',
											valueQuery: {
												options: { response: { key: 'row.documentType' } },
												queryOptionsType: 'response',
											},
										},
									],
								},
							},
						},
						queryOptionsType: 'documents',
					},
					headingKey: 'documentType',
					id: 'documents',
					rowStyle: { background: { color: '#efefef' } },
				},
			},
			showGroupCheckboxes: true,
		},
	},
	breakpoints: [
		{
			size: 'medium',
			modifier: 'down',
			props: {
				paging: { initialPageSize: 5 },
				filters: {
					filterToggleButton: {
						closeDialogIcon: { iconOptions: { filename: { src: 'x.svg' } } },
						closeFiltersIcon: { iconOptions: { filename: { src: 'chevron-left.svg' } } },
						closeFiltersIconPosition: 'left',
						openFiltersIcon: {},
						openFiltersIconPosition: 'right',
						showButton: true,
						buttonPosition: ToggleButtonTargetPosition.FilterRow,
						selectedFilterCount: true,
						selectedFilterCountPosition: 'right',
						mode: 'Modal',
					},
					mode: 'accordion',
				},
				table: {
					columns: [
						{
							header: 'Title',
							id: 'title',
							key: 'title',
							sort: { enabled: true },
							valueQuery: { key: 'title', queryOptionsType: 'document' },
						},
					],
				},
			},
		},
		{
			size: 'large',
			modifier: 'up',
			props: {
				app: {
					stickyProps: {
						searchFilters: {
							enabled: true,
							offset: 0,
						},
						searchFiltersExpanded: {
							enabled: true,
							offset: 94,
						},
						tableHeader: {
							enabled: true,
							offset: 198,
							zIndex: 3,
						},
						group: {
							enabled: true,
							offset: 239,
							zIndex: 3,
						},
					},
				},
				filters: {
					mode: 'dropdown',
					filterToggleButton: {
						closeDialogIcon: { iconOptions: { filename: { src: 'x.svg' } } },
						closeFiltersIcon: { iconOptions: { filename: { src: 'SuccessIcon.svg' } } },
						closeFiltersIconPosition: 'left',
						openFiltersIcon: { iconOptions: { filename: { src: 'SuccessIcon.svg' } } },
						buttonPosition: ToggleButtonTargetPosition.SearchBarRow,
						openFiltersIconPosition: 'right',
						mode: 'InlineToggle',
						showButton: true,
						selectedFilterCount: true,
						selectedFilterCountPosition: 'right',
					},
				},
			},
		},
	],
	core: { endpointsConfiguration: { endpoints: { documents: {} } } },
	culture: { base: 'en-GB', default: 'en-GB' },
	token: '064d6d3f-8366-4388-ae35-fe6f200fa2d8',
};
