import React, { Component } from 'react';
import {
	IExportToCsvProps,
	IExportToCsvState,
} from './models';
import { inject, observer } from 'mobx-react';
import { InjectedStyledComponent } from '../shared/InjectedStyledComponent';
import Wrapper from './styledComponents/Wrapper';
import Button from './styledComponents/Button';
import Translate from '@kurtosys/ksys-app-components/dist/components/base/Translate';
import LoadingIndicator from '@kurtosys/ksys-app-components/dist/components/base/LoadingIndicator';

@inject('appStore', 'exportToCsvStore')
@observer
export class ExportToCsv extends Component<IExportToCsvProps, IExportToCsvState> {
	static configurationKey: 'exportToCsv' = 'exportToCsv';
	static styleKey: 'exportToCsv' = 'exportToCsv';
	constructor(props: IExportToCsvProps) {
		super(props);
	}
	render() {
		const { className, exportToCsvStore } = this.props;

		if (!exportToCsvStore) {
			return null;
		}

		const { buttonText, disabled, exportToCsv, isLoading } = exportToCsvStore;

		return (
			<Wrapper className={ className }>
				<Button onClick={ exportToCsv } disabled={ disabled } suppressAnalytics={ true }>
					{ isLoading && <LoadingIndicator /> }
					{ !isLoading && <Translate>{ buttonText }</Translate> }
				</Button>
			</Wrapper>
		);
	}
}

export default InjectedStyledComponent(ExportToCsv, 'exportToCsv');

