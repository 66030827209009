import { models, utils } from '@kurtosys/ksys-app-template';
import { computed, action, observable, isArrayLike } from 'mobx';
import { StoreBase } from '../../../common/StoreBase';
import {
	IActiveFiltersProps,
	IDropdownItemsByValue,
	IFiltersBreakpointProps,
	IFiltersConfiguration,
	IFiltersIndicatorText,
	TFilterMode,
} from '../models';
import { IFilterProps } from '../../Filter/models';
import { IDocumentGetFiltersResponse } from '@kurtosys/ksys-api-client/dist/models/commonTypes';
import { IDropdownItem } from '@kurtosys/ksys-app-components/dist/components/base/Dropdown/models/IDropdownItem';
import { QueryString } from '../../../utils/QueryString';
import { IQueryStringOptions } from '../../../models/app/IQueryStringOptions';
import { IQueryStringObject } from '../../../models/app/IQueryStringObject';
import { IDocumentSearchRequest, IEntityJoinSpec } from '../../../models/commonTypes';
import { IBreakpointProps } from '@kurtosys/ksys-app-components/dist/models/IBreakpointProps';
import { IPillProps } from '@kurtosys/ksys-app-components/dist/components/base/Pill/models';
import { THorizontalAlign } from '@kurtosys/ksys-app-components/dist/models/THorizontalAlign';
import { IButtonProps } from '@kurtosys/ksys-app-components/dist/components/base/Button/models';
import { IDropdownSearchInput } from '../models/IDropdownSearchInput';
import { UserValuesStorage } from '../../../utils/UserValuesStorage';
import { IEntitySearchClause } from '../../../models/app/IEntitySearchClause';
import { IFacetedSearchDocumentRequest } from '../../../models/app/IFacetedSearchDocumentRequest';
import { isNullOrEmpty } from '@kurtosys/ksys-app-template/dist/utils/typeChecks';
import { IClearFilters } from '../models/IClearFilters';
import { IFilterToggleButtonProps } from '../models/IFilterToggleButtonProps';

export class FiltersStore extends StoreBase {
	static componentKey: 'filters' = 'filters';

	@observable.ref hasLoadedQueryString = false;
	@observable userFilterValues: Record<string, string[]> | undefined;
	storageHelper: UserValuesStorage | undefined;

	@computed
	get configuration(): IFiltersConfiguration | undefined {
		if (this.storeContext && this.storeContext.appStore) {
			return this.storeContext.appStore.getComponentConfiguration(FiltersStore.componentKey);
		}
	}

	@computed
	get hide(): boolean {
		return !!(this.configuration && this.configuration.hide);
	}

	@computed
	get useCounts(): boolean {
		return !!(this.configuration && this.configuration.useCounts);
	}

	@computed
	get filterStringFormat(): string {
		return (this.configuration && this.configuration.filterStringFormat) || '{label} ({count})';
	}

	@computed
	get availableFiltersOnTop(): boolean {
		return !!(this.configuration && this.configuration.availableFiltersOnTop);
	}

	@computed
	get filterToggleButton(): IFilterToggleButtonProps {
		const filterToggleButton = (this.configuration && this.configuration.filterToggleButton) || {}
		if (filterToggleButton.expandByDefault) {
			this.showFilters = true;
		}
		return filterToggleButton;
	}

	@computed
	get activeFiltersProps(): Required<IActiveFiltersProps> {
		const defaultProps: Required<IActiveFiltersProps> = {
			show: true,
			heading: 'Active Filters',
			labelFormat: '{label} > {value}',
			closeButtonProps: {
				suppressAnalytics: true,
				iconProps: {
					asset: 'base.dialog.close',
					accessibilityText: 'Clear Filter',
				},
			},
		};
		return { ...defaultProps, ...(this.configuration && this.configuration.activeFiltersProps) };
	}

	get pinSelected(): boolean {
		return (this.configuration && this.configuration.pinSelected) || false;
	}

	get searchInput(): IDropdownSearchInput {
		return (this.configuration && this.configuration.searchInput) || {};
	}

	queryString: QueryString | undefined;

	@computed
	get storageHelperConfig(): models.helpers.StorageHelper.IStorageHelperConfigurationOptions | undefined {
		return this.configuration && this.configuration.storageConfiguration;
	}

	@computed
	get hasQueryString(): boolean {
		return !!(this.queryString && this.queryString.enabled);
	}

	@action
	async initialize(): Promise<void> {
		const { kurtosysApiStore } = this.storeContext;
		this.getFilters();
		this.queryString = new QueryString(this.queryStringOptions, {
			enabled: false,
			prefix: 'filter_',
		});
		if (this.storageHelperConfig && kurtosysApiStore) {
			this.storageHelper = new UserValuesStorage(kurtosysApiStore, this.storageHelperConfig, 'user_filters');
			await this.getUserFilters();
		}
	}

	@action
	async getUserFilters(): Promise<void> {
		if (this.storageHelper) {
			this.userFilterValues = (await this.storageHelper.getValues()) as Record<string, string[]>;
		}
	}

	@action
	async setUserFilters(): Promise<void> {
		if (this.storageHelper) {
			await this.storageHelper.setValues(this.userFilterValues);
		}
	}

	@action
	setInitialFilters = () => {
		if (this.hasFilters && !this.hasLoadedQueryString) {
			let initialFilterValues;
			if (this.queryString && this.queryString.enabled) {
				initialFilterValues = this.queryString.fetch(true);
			}
			if (!utils.typeChecks.isNullOrEmpty(this.userFilterValues)) {
				initialFilterValues = {
					...initialFilterValues,
					...this.userFilterValues,
				};
			}
			if (initialFilterValues) {
				const selectionsByCode: { [code: string]: IDropdownItem | IDropdownItem[] } = {};
				const childFilters = this.childFilters;
				for (const childFilter of childFilters) {
					const { code, items = [] } = childFilter;
					const values = initialFilterValues[code];
					if (values && values.length > 0) {
						const selectedOptions = items.filter((item) => {
							return (
								(this.queryString && this.queryString.hasValue(values, item.value)) ||
								values.includes(item.value)
							);
						});
						selectionsByCode[code] = selectedOptions;
					}
				}
				this.selectionsByCode = selectionsByCode;
				this.hasLoadedQueryString = true;
				this.handleFilterChange();
			}
		}
	}

	@computed
	get mode(): TFilterMode {
		return (this.configuration && this.configuration.mode) || 'dropdown';
	}

	@computed
	get hasFilters(): boolean {
		return this.childFilters && this.childFilters.length > 0;
	}

	@computed
	get totalFiltersSelected(): number {
		const total = Object.keys(this.selectionsByCode || {}).reduce((acc, key) => {
			const selections = this.selectionsByCode[key];
			if (selections) {
				if (isArrayLike(selections)) {
					return acc + selections.length;
				}
				return acc + 1;
			}
			return acc;
		}, 0);
		return total;
	}

	@computed
	get filterToggleText(): string {
		const text = 'Filters';
		return this.storeContext.translationStore.translate(text);
	}
	@computed
	get showToggleButton(): boolean {
		return true;
	}
	@computed
	get filterIndicatorText(): string {
		const total = this.totalFiltersSelected;
		const defaultFiltersIndicatorText: Required<IFiltersIndicatorText> = {
			hide: false,
			multipleFilters: '{total} filters selected',
			singleFilter: '{total} filter selected',
			noFilters: '',
		};
		const indicatorText: Required<IFiltersIndicatorText> = {
			...defaultFiltersIndicatorText,
			...((this.configuration && this.configuration.indicatorText) || {}),
		};
		let text: string | undefined = indicatorText.noFilters;
		if (total > 0) {
			if (total === 1) {
				text = indicatorText.singleFilter;
			}
			else {
				text = indicatorText.multipleFilters;
			}
		}
		if (text) {
			return this.storeContext.translationStore.translate(text, { total });
		}
		return '';
	}

	@computed
	get rawChildFilters(): IFilterProps[] {
		return (this.configuration && this.configuration.childFilters) || [];
	}

	@computed
	get rawChildEntityFilters(): IFilterProps[] {
		return (this.configuration && this.configuration.childEntityFilters) || [];
	}

	addDropdownItem = (
		documentItemsByValue: IDropdownItemsByValue,
		label: string,
		value: string,
		highlight?: boolean,
	) => {
		if (!documentItemsByValue[value]) {
			documentItemsByValue[value] = {
				label,
				value,
				highlight,
			};
		}
		return documentItemsByValue;
	}

	@computed
	get childFilters(): IFilterProps[] {
		const { translationStore } = this.storeContext;
		const rawFilters = [...this.rawChildFilters, ...this.rawChildEntityFilters];
		return rawFilters.map((rawChildFilter) => {
			const { code: key, sort = { order: 'ASC' } } = rawChildFilter;
			const filterValuesForKey = (this.filters && this.filters[key]) || [];

			// Get unique label values if there are arrays returned
			let dropdownItemsByValue: IDropdownItemsByValue = {};
			let availableFilters: IDropdownItemsByValue = {};
			filterValuesForKey.forEach((filterValue) => {
				const { value, label, count = 0 } = filterValue;
				const dropdownItemLabel = this.useCounts
					? translationStore.translate(this.filterStringFormat, { label, count })
					: label;
				const highlight = this.useCounts && count > 0;
				if (Array.isArray(value) && Array.isArray(label)) {
					for (let i = 0; i < value.length; i++) {
						if (count === 0 && this.availableFiltersOnTop) {
							availableFilters = this.addDropdownItem(availableFilters, label[i], value[i], highlight);
						}
						else {
							dropdownItemsByValue = this.addDropdownItem(
								dropdownItemsByValue,
								label[i],
								value[i],
								highlight,
							);
						}
					}
				}
				else if (typeof value === 'string' || typeof value === 'number') {
					if (count === 0 && this.availableFiltersOnTop) {
						availableFilters = this.addDropdownItem(availableFilters, dropdownItemLabel, value, highlight);
					}
					else {
						dropdownItemsByValue = this.addDropdownItem(
							dropdownItemsByValue,
							dropdownItemLabel,
							value.toString(),
							highlight,
						);
					}
				}
			});

			let available: IDropdownItem[] = Object.values(availableFilters);
			let items: IDropdownItem[] = Object.values(dropdownItemsByValue);
			const sortOptions = {
				...sort,
				culture: this.storeContext.translationStore.culture,
				translate: this.storeContext.translationStore.translate,
			};

			available = utils.collection.sortByType(available, item => item.label, sortOptions);

			items = utils.collection.sortByType(items, item => item.label, sortOptions);

			items.push(...available);
			return {
				...rawChildFilter,
				items,
				selectedItems: this.selectionsByCode[key],
			};
		});
	}

	@computed
	get activeFilterPills(): IPillProps[] {
		const { translationStore } = this.storeContext;
		return this.childFilters.reduce((acc, childFilter) => {
			const dateSelections = this.dateSelectionsByCode[childFilter.code];
			let selections = this.selectionsByCode[childFilter.code];
			if (!utils.typeChecks.isNullOrEmpty(selections)) {
				selections = Array.isArray(selections) ? selections : [selections];
				const { code, label } = childFilter;
				acc.push(
					...selections.map((selection) => {
						const pillValue = translationStore.translate(this.activeFiltersProps.labelFormat, {
							code,
							label,
							value: selection.value,
						});
						const closeButton: IButtonProps = {
							...this.activeFiltersProps.closeButtonProps,
							onClick: () => {
								this.clearFilterValue(code, selection.value);
							},
						};
						return {
							label,
							closeButton,
							key: pillValue,
							value: pillValue,
							iconPosition: 'right' as THorizontalAlign,
						};
					}),
				);
			}
			if (!utils.typeChecks.isNullOrEmpty(dateSelections)) {
				const { code, label } = childFilter;
				const pillValue = translationStore.translate(this.activeFiltersProps.labelFormat, {
					code,
					label,
					value: `${ dateSelections.startDate } - ${ dateSelections.endDate }`,
				});
				const closeButton: IButtonProps = {
					...this.activeFiltersProps.closeButtonProps,
					onClick: () => {
						this.clearFiltersForCode(code);
					},
				};
				const filterPill = {
					label,
					closeButton,
					key: pillValue,
					value: pillValue,
					iconPosition: 'right' as THorizontalAlign,
				};
				acc.push(filterPill);
			}
			return acc;
		}, [] as IPillProps[]);
	}

	// Document Filter Properties
	@computed
	get filterProperties(): string[] {
		return this.rawChildFilters.map(childFilter => childFilter.code);
	}

	// Entity Filter Properties
	@computed
	get entityFilterProperties(): string[] {
		return this.rawChildEntityFilters.map(childFilter => childFilter.code);
	}

	@observable.ref
	filters: IDocumentGetFiltersResponse = {};

	@computed
	get queryStringOptions(): IQueryStringOptions {
		return (this.configuration && this.configuration.queryString) || {};
	}

	@computed
	get entityFiltersJoinSpecs(): IEntityJoinSpec[] {
		return (this.configuration && this.configuration.entityFiltersJoinSpecs) || [];
	}

	@computed
	get hideIndicatorText(): boolean {
		return !!(this.configuration && this.configuration.indicatorText && this.configuration.indicatorText.hide);
	}

	get clearFilters(): IClearFilters {
		return (this.configuration && this.configuration.clearFilters) || {
			label: 'Clear Filters',
		};
	}

	@action
	getFilters = async () => {
		const loadingKey = 'getFilters';
		this.startLoading(loadingKey);

		const promises = [
			this.getDocumentFilters(true),
			this.getEntityFilters(true),
		];
		if (this.useCounts) {
			promises.push(this.getDocumentFilters(false));
			promises.push(this.getEntityFilters(false));
		}
		const [filters, entityFilters, filtersCount, entityFiltersCount] = await Promise.all(promises);
		// Reduce the filter options available based on the search criteria
		const combinedFilters = { ...filters, ...entityFilters };
		if (combinedFilters) {
			this.filters = this.formatFilters(combinedFilters);
		}
		// Add count values to the available filter options without reducing the list
		if (this.useCounts) {
			const combinedFilterCounts = { ...filtersCount, ...entityFiltersCount };
			if (combinedFilterCounts) {
				this.orchestrateFilters(this.formatFilters(combinedFilterCounts));
			}
		}
		this.setInitialFilters();
		this.stopLoading(loadingKey);
	}

	getDocumentFilters = async (excludeFilters: boolean = false) => {
		if (!utils.typeChecks.isNullOrEmpty(this.filterProperties)) {
			const { kurtosysApiStore, appStore, identifierSearchStore, translationStore } = this.storeContext;
			const body: models.api.document.IDocumentGetFiltersRequest = {
				search: appStore.getSearch(false, false, excludeFilters),
				entitySearch: appStore.getEntitySearch(excludeFilters),
				codes: this.filterProperties,
				spreadArrays: true,
				uniqueOptionsOnly: true,
				counts: true,
				searchEntityRequest: appStore.searchEntityRequest,
				translation: {
					sourceCulture: translationStore.baseCulture,
					culture: translationStore.culture,
				},
			};
			appStore.applySpecialPropertiesToBody(body);
			if (identifierSearchStore.hasSearch) {
				body.identifierSearches = identifierSearchStore.identifierSearches;
				body.identifierSearchOperator = appStore.identifierSearchOperator;
			}

			return kurtosysApiStore.getDocumentFilters.execute({
				body,
			});
		}
	}

	getEntityFilters = async (excludeFilters: boolean = false) => {
		if (!utils.typeChecks.isNullOrEmpty(this.entityFilterProperties)) {
			const { kurtosysApiStore, appStore, identifierSearchStore, translationStore } = this.storeContext;
			const body: models.api.document.IDocumentGetEntityFiltersRequest = {
				search: appStore.getSearch(false, false, excludeFilters),
				joinSpecs: this.entityFiltersJoinSpecs,
				entitySearch: appStore.getEntitySearch(excludeFilters),
				codes: this.entityFilterProperties,
				spreadArrays: true,
				uniqueOptionsOnly: true,
				counts: true,
				searchEntityRequest: appStore.searchEntityRequest,
				translation: {
					sourceCulture: translationStore.baseCulture,
					culture: translationStore.culture,
				},
			};
			appStore.applySpecialPropertiesToBody(body);
			if (identifierSearchStore.hasSearch) {
				body.identifierSearches = identifierSearchStore.identifierSearches;
				body.identifierSearchOperator = appStore.identifierSearchOperator;
			}

			return kurtosysApiStore.getEntityFilters.execute({
				body,
			});
		}
	}

	orchestrateFilters = (filters: IDocumentGetFiltersResponse) => {
		const codes = Object.keys(this.filters);
		const newCodes = Object.keys(filters);

		codes.forEach((code) => {
			const existingOptions = this.filters[code];
			const newOptions = filters[code];
			// If code not present in the new filters, set all counts to zero
			if (utils.typeChecks.isNullOrEmpty(newOptions)) {
				existingOptions.forEach(option => (option.count = 0));
			}
			else {
				// Set items to new count values
				newOptions.forEach((newOption) => {
					const existingOption = existingOptions.find(item => item.value === newOption.value);
					if (existingOption) {
						existingOption.count = newOption.count;
					}
					else {
						this.filters[code].push(newOption);
					}
				});

				// Set counts of any missing items to zero
				utils.collection
					.except(existingOptions, newOptions, (item) => {
						return item.value.toString();
					})
					.forEach((option) => {
						option.count = 0;
					});
			}
		});

		newCodes.forEach((newCode) => {
			if (!this.filters[newCode]) {
				this.filters[newCode] = filters[newCode];
			}
		});
		this.filters = JSON.parse(JSON.stringify(this.filters));
	}

	formatFilters = (filters: IDocumentGetFiltersResponse): IDocumentGetFiltersResponse => {
		const results: IDocumentGetFiltersResponse = {};
		Object.keys(filters).forEach((code) => {
			const optionsForCode = filters[code];
			results[code] = [];
			if (optionsForCode && optionsForCode.length > 0) {
				optionsForCode.forEach((option) => {
					const { value, count } = option;
					if (isArrayLike(value)) {
						value.forEach((v) => {
							results[code].push({
								count,
								value: v,
								label: v,
							});
						});
					}
					else {
						results[code].push(option);
					}
				});
			}
		});
		return results;
	}

	@observable.ref
	selectionsByCode: { [code: string]: IDropdownItem | IDropdownItem[] } = {};

	@observable.ref
	dateSelectionsByCode: { [code: string]: { startDate: string; endDate: string } } = {};

	@action
	handleFilterChange = (mustFetchDocuments: boolean = true) => {
		const { appStore, pagingStore, tableStore } = this.storeContext;
		tableStore.clearSelectedFields();
		pagingStore.resetToFirstPage(false);
		this.getFilters();
		if (mustFetchDocuments) {
			appStore.fetchDocuments();
		}
		this.updateQueryString();
	}

	@computed
	get queryStringPrefix(): string {
		return this.queryStringOptions.prefix || 'filter_';
	}

	@action
	updateQueryString = () => {
		if (this.queryString && this.queryString.enabled) {
			const filtersQueryStringObject: IQueryStringObject = Object.keys(this.selectionsByCode).reduce(
				(acc: IQueryStringObject, code: string) => {
					const values = this.selectionsByCode[code];
					if (isArrayLike(values)) {
						acc[code] = values.map(value => value.value);
					}
					else {
						acc[code] = values.value;
					}
					return acc;
				},
				{},
			);
			this.queryString.update(filtersQueryStringObject);
		}
	}

	@action
	setSelectedFilters = async (code: string, selection: IDropdownItem | IDropdownItem[]) => {
		this.selectionsByCode = {
			...this.selectionsByCode,
			[code]: selection,
		};
		const selectionValues = isArrayLike(selection) ? selection.map(element => element.value) : [selection.value];
		const filterConfig = this.childFilters.find(filter => filter.code === code);
		if (filterConfig && filterConfig.saveValueAsUserPreference) {
			if (this.userFilterValues) {
				this.userFilterValues[code] = selectionValues;
				if (utils.typeChecks.isNullOrEmpty(this.userFilterValues[code])) {
					delete this.userFilterValues[code];
				}
			}
			else {
				this.userFilterValues = { [code]: selectionValues };
			}
			await this.setUserFilters();
		}
		this.handleFilterChange();
	}

	@action
	setSelectedDateFilters = async (code: string, selection: { startDate: string; endDate: string }) => {
		this.dateSelectionsByCode = {
			...this.dateSelectionsByCode,
			[code]: selection,
		};
		const startDate = selection.startDate;
		const endDate = selection.endDate;
		const selectionValues = [startDate, endDate];
		const filterConfig = this.childFilters.find(filter => filter.code === code);
		if (filterConfig && filterConfig.saveValueAsUserPreference) {
			if (this.userFilterValues) {
				this.userFilterValues[code] = selectionValues;
				if (utils.typeChecks.isNullOrEmpty(this.userFilterValues[code])) {
					delete this.userFilterValues[code];
				}
			}
			else {
				this.userFilterValues = { [code]: selectionValues };
			}
			await this.setUserFilters();
		}
		this.handleFilterChange();
	}

	@action
	handleClearFilters = async (mustFetchDocuments: boolean = true) => {
		const {
			tableStore: { clearSelectedFields },
		} = this.storeContext;
		this.selectionsByCode = {};
		this.dateSelectionsByCode = {};
		// We don't want to clear the user filter values on page load
		if (mustFetchDocuments) {
			this.userFilterValues = {};
			await this.setUserFilters();
		}
		this.handleFilterChange(mustFetchDocuments);
		clearSelectedFields();
	}

	@observable showFilters: boolean = false;

	@action
	toggleShowFilters = () => {
		this.showFilters = !this.showFilters;
	}

	@action
	clearFiltersForCode = async (code: string, mustFetchDocuments: boolean = true) => {
		const {
			tableStore: { clearSelectedFields },
		} = this.storeContext;

		if (this.selectionsByCode[code]) {
			delete this.selectionsByCode[code];
		}
		else if (this.dateSelectionsByCode[code]) {
			delete this.dateSelectionsByCode[code];
			const { dateFilterStore } = this.storeContext;
			if (dateFilterStore && dateFilterStore.selectedDate && dateFilterStore.selectedDate[code]) {
				delete dateFilterStore.selectedDate[code];
			}
		}
		// Force observable value to "refresh"
		this.selectionsByCode = { ...this.selectionsByCode };
		this.dateSelectionsByCode = { ...this.dateSelectionsByCode };
		const filterConfig = this.childFilters.find(filter => filter.code === code);
		if (filterConfig && filterConfig.saveValueAsUserPreference) {
			if (this.userFilterValues && this.userFilterValues[code]) {
				delete this.userFilterValues[code];
				await this.setUserFilters();
			}
		}
		this.handleFilterChange(mustFetchDocuments);
		clearSelectedFields();
	}

	@action
	clearFilterValue = async (code: string, value: string, mustFetchDocuments: boolean = true) => {
		const {
			tableStore: { clearSelectedFields },
		} = this.storeContext;

		const selection = this.selectionsByCode[code];
		if (selection) {
			if (Array.isArray(selection)) {
				this.selectionsByCode[code] = selection.filter(item => item.value !== value);
			}
			else if (selection.value === value) {
				delete this.selectionsByCode[code];
			}
		}
		// Force observable value to "refresh"
		this.selectionsByCode = { ...this.selectionsByCode };
		const filterConfig = this.childFilters.find(filter => filter.code === code);
		if (filterConfig && filterConfig.saveValueAsUserPreference) {
			if (this.userFilterValues && this.userFilterValues[code]) {
				const userValues = this.userFilterValues[code];
				this.userFilterValues[code] = userValues.filter(userValue => userValue !== value);
				if (utils.typeChecks.isNullOrEmpty(this.userFilterValues[code])) {
					delete this.userFilterValues[code];
				}
				await this.setUserFilters();
			}
		}
		this.handleFilterChange(mustFetchDocuments);
		clearSelectedFields();
	}

	@action
	loadSelectedResults = async (search: IFacetedSearchDocumentRequest) => {
		this.handleClearFilters(false);
		if (search && !utils.typeChecks.isNullOrEmpty(search.filters)) {
			await this.getFilters();
			const filtersObj = search.filters.reduce((acc, filter) => {
				acc[filter.property] = filter.values;
				return acc;
			}, {} as any);
			if (filtersObj) {
				const selectionsByCode: { [code: string]: IDropdownItem | IDropdownItem[] } = {};
				const childFilters = this.childFilters;
				for (const childFilter of childFilters) {
					const { code, items = [] } = childFilter;
					const values = filtersObj[code];
					if (values && values.length > 0) {
						const selectedOptions = items.filter(item => values.includes(item.value));
						selectionsByCode[code] = selectedOptions;
					}
				}
				this.selectionsByCode = selectionsByCode;
				this.handleFilterChange(false);
			}
		}
	}

	@computed
	get searchForFilters(): models.api.document.IDocumentSearchClause[] {
		const search: models.api.document.IDocumentSearchClause[] = [];
		const properties = Object.keys(this.selectionsByCode);
		const dateProperties = Object.keys(this.dateSelectionsByCode);
		const documentFilterProperties = properties.filter(p => this.filterProperties.includes(p));
		const documentDateFilterProperties = dateProperties.filter(p => this.filterProperties.includes(p));
		documentFilterProperties.forEach((property) => {
			let values: string[] = [];
			const rawValues = this.selectionsByCode[property];
			if (isArrayLike(rawValues)) {
				values = rawValues.map(rawValue => rawValue.value);
			}
			else {
				values = [rawValues.value];
			}
			const searchItem: models.api.document.IDocumentSearchClause = {
				values,
				property,
				matchtype: 'MATCH',
				// meta: true,
			};
			if (values && values.length > 0) {
				search.push(searchItem);
			}
		});
		documentDateFilterProperties.forEach((property) => {
			const filterTerm = this.getFilterTerm(property);
			if (filterTerm) {
				search.push(filterTerm);
			}
		});
		return search;
	}

	@action
	formattedStartDate = (code: string) => {
		return utils.formatter.formatDateTime(
			this.storeContext.appStore.culture,
			{
				dateFormatString: 'YYYY-MM-DDT00:00:00Z',
			},
			this.dateSelectionsByCode[code].startDate || '',
		);
	}

	@action
	formattedEndDate = (code: string) => {
		return utils.formatter.formatDateTime(
			this.storeContext.appStore.culture,
			{
				dateFormatString: 'YYYY-MM-DDT23:59:59.999Z',
			},
			this.dateSelectionsByCode[code].endDate || '',
		);
	}

	@action
	getFilterTerm = (code: string): models.api.document.IDocumentSearchClause | undefined => {
		if (
			!isNullOrEmpty(this.dateSelectionsByCode[code].startDate) &&
			!isNullOrEmpty(this.dateSelectionsByCode[code].endDate)
		) {
			return {
				property: code,
				matchtype: 'RANGE',
				values: [this.formattedStartDate(code), this.formattedEndDate(code)],
				// meta: true,
			};
		}
		return undefined;
	}

	@computed
	get entitySearchForFilters(): IEntitySearchClause[] {
		const search: IEntitySearchClause[] = [];
		const properties = Object.keys(this.selectionsByCode);
		const entityFilterProperties = properties.filter(p => this.entityFilterProperties.includes(p));

		entityFilterProperties.forEach((property) => {
			let values: string[] = [];
			const rawValues = this.selectionsByCode[property];
			if (isArrayLike(rawValues)) {
				values = rawValues.map(rawValue => rawValue.value);
			}
			else {
				values = [rawValues.value];
			}
			const searchItem: IEntitySearchClause = {
				values,
				property,
				entityType: 'CLSS',
			};
			if (values && values.length > 0) {
				search.push(searchItem);
			}
		});
		return search;
	}
}
