import React, { Component } from 'react';
import { IAccordionFilterProps, IAccordionFilterState } from './models';
import { inject, observer } from 'mobx-react';
import { InjectedStyledComponent } from '../shared/InjectedStyledComponent';
import SelectedCheckboxWrapper from './styledComponents/SelectedCheckboxWrapper';
import UnselectedCheckboxWrapper from './styledComponents/UnselectedCheckboxWrapper';
import Checkbox from '@kurtosys/ksys-app-components/dist/components/base/Checkbox';
import AccordionPanel from '@kurtosys/ksys-app-components/dist/components/base/AccordionPanel';
import Input from '@kurtosys/ksys-app-components/dist/components/base/Input';

import { IDropdownItem } from '@kurtosys/ksys-app-components/dist/components/base/Dropdown/models/IDropdownItem';
import { computed, observable } from 'mobx';
import { utils } from '@kurtosys/ksys-app-template';
import { IPillProps } from '@kurtosys/ksys-app-components/dist/components/base/Pill/models';
import { IActionsProps } from '@kurtosys/ksys-app-components/dist/components/base/Actions/models';
import { IInputProps } from '@kurtosys/ksys-app-components/dist/components/base/Input/models';

@inject('appStore', 'accordionFilterStore', 'filtersStore')
@observer
export class AccordionFilter extends Component<IAccordionFilterProps, IAccordionFilterState> {
	static configurationKey: 'accordionFilter' = 'accordionFilter';
	static styleKey: 'accordionFilter' = 'accordionFilter';
	constructor(props: IAccordionFilterProps) {
		super(props);
	}

	@observable searchTerm: string = '';

	@computed
	get selections(): IDropdownItem[] {
		const { filtersStore, code } = this.props;
		if (!utils.typeChecks.isNullOrUndefined(filtersStore)) {
			const { selectionsByCode } = filtersStore;
			const selections = selectionsByCode[code];
			if (selections) {
				return Array.isArray(selections) ? selections : [selections];
			}
		}
		return [];
	}

	handleCheckboxSelect(item: IDropdownItem, checked: boolean) {
		const checkboxSelections = this.selections.filter((c) => c.value !== item.value);
		if (checked) {
			checkboxSelections.push(item);
		}
		this.handleItemSelect(checkboxSelections);
	}

	handleItemSelect = (item: IDropdownItem | IDropdownItem[]) => {
		const { code, filtersStore } = this.props;
		if (filtersStore) {
			filtersStore.setSelectedFilters(code, item);
		}
	};

	@computed
	get items(): IDropdownItem[] {
		const items = this.props.items || [];
		if (this.searchTerm && this.searchTerm.length > 0) {
			const lowercaseSearchTerm = this.searchTerm.toLowerCase();
			return items.filter((item) => item.label.toLowerCase().includes(lowercaseSearchTerm));
		}
		return items;
	}

	@computed
	get checkboxComponents() {
		const { accordionFilterStore } = this.props;
		const selectedItems: any[] = [];
		const unselectedItems: any[] = [];

		if (accordionFilterStore && this.items) {
			this.items.forEach((item) => {
				const value = this.selections.some((i) => i.value === item.value);
				const checkboxControl = (
					<Checkbox
						key={ `${ item.label }_${ item.value }` }
						label={ item.label }
						labelId={ item.label }
						onChange={ (event) => {
							this.handleCheckboxSelect(item, event.target.checked);
						} }
						value={ value }
					/>
				);
				if (value) {
					selectedItems.push(checkboxControl);
				} else {
					unselectedItems.push(checkboxControl);
				}
			});
		}
		return { selectedItems, unselectedItems };
	}

	@computed
	get totals(): { total: number; selected: number } {
		const { items } = this.props;
		return {
			selected: this.selections.length,
			total: (items && (Array.isArray(items) ? items.length : 1)) || 0,
		};
	}

	@computed
	get pillProps(): IPillProps | undefined {
		const headerPillProps = this.props.accordionFilterStore && this.props.accordionFilterStore.headerPillProps;
		if (headerPillProps && headerPillProps.show) {
			return {
				value: utils.replacePlaceholders({
					values: this.totals,
					text: headerPillProps.label,
				}),
				hide: this.totals.selected === 0,
			};
		}
	}

	@computed
	get actionsProps(): IActionsProps | undefined {
		const { accordionFilterStore, filtersStore, code } = this.props;
		const clearFilterProps = accordionFilterStore && accordionFilterStore.clearFilterProps;
		if (clearFilterProps && clearFilterProps.show) {
			const clearFiltersForCode = filtersStore && filtersStore.clearFiltersForCode;
			return {
				buttons: [
					{
						hide: this.totals.selected === 0,
						iconProps: clearFilterProps.iconProps,
						onClick: (e) => {
							e.stopPropagation();
							if (clearFiltersForCode) {
								clearFiltersForCode(code);
							}
						},
					},
				],
			};
		}
	}

	@computed
	get inputProps(): IInputProps {
		const searchProps = this.props.accordionFilterStore && this.props.accordionFilterStore.searchProps;
		const { searchInput } = this.props;
		return {
			hide: !(searchProps && searchProps.show),
			placeholder: (searchInput && searchInput.placeholder) || (searchProps && searchProps.placeholder),
			value: this.searchTerm,
			onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
				this.searchTerm = e.target.value;
			},
		};
	}

	render() {
		const { className, accordionFilterStore, code, label } = this.props;
		if (!accordionFilterStore) {
			return null;
		}

		const { selectedItems, unselectedItems } = this.checkboxComponents;

		return (
			<AccordionPanel
				className={ className }
				panelKey={ code }
				heading={ label }
				headingPill={ this.pillProps }
				headingActions={ this.actionsProps }
			>
				<Input { ...this.inputProps } />
				{ selectedItems.length > 0 && (
					<SelectedCheckboxWrapper data-all={ this.totals.selected === this.totals.total }>
						{ selectedItems }
					</SelectedCheckboxWrapper>
				) }
				{ unselectedItems.length > 0 && <UnselectedCheckboxWrapper items={ unselectedItems } /> }
			</AccordionPanel>
		);
	}
}

export default InjectedStyledComponent(AccordionFilter, 'accordionFilter');
