import { ISearchDocumentsRequestBody } from '@kurtosys/ksys-api-client/dist/models/requests/documents/ISearchDocumentsRequestBody';
import { helpers, utils } from '@kurtosys/ksys-app-template';
import { models } from '@kurtosys/ksys-app-template';
import { computed, action } from 'mobx';
import { StoreBase } from '../../../common/StoreBase';
import { IDocumentSearchResponse, IQueryOptions } from '../../../models/commonTypes';
import { IExportToCsvConfiguration, IExportToCsvConfigurationColumn } from '../models';

export class ExportToCsvStore extends StoreBase {
	static componentKey: 'exportToCsv' = 'exportToCsv';

	@computed
	get configuration(): IExportToCsvConfiguration | undefined {
		if (this.storeContext && this.storeContext.appStore) {
			return this.storeContext.appStore.getComponentConfiguration(ExportToCsvStore.componentKey);
		}
		return undefined;
	}

	@action
	async initialize(): Promise<void> {}

	@computed
	get filename(): string {
		return (this.configuration && this.configuration.filename) || 'Document Meta';
	}

	@computed
	get filenameQuery(): IQueryOptions | undefined {
		return this.configuration && this.configuration.filenameQuery;
	}

	@computed
	get buttonText(): string {
		return (this.configuration && this.configuration.buttonText) || 'Export to CSV';
	}

	@computed
	get separator(): string | undefined {
		return this.configuration && this.configuration.separator;
	}

	@computed
	get show(): boolean {
		let message: string = '';
		let show: boolean = !!this.configuration;
		if (this.configuration) {
			show = this.configuration.show || false;
			if (show) {
				show = !!(this.configuration.columns && this.configuration.columns.length > 0);
				if (show) {
					message = 'will show';
				} else {
					message = 'no columns defined';
				}
			} else {
				message = 'show is undefined or false';
			}
		} else {
			message = 'configuration missing';
		}
		console.info('exportToCsv: ', { show, message });
		return show;
	}

	@computed
	get disabled(): boolean {
		const { tableStore } = this.storeContext;
		return this.isLoading || tableStore.rows.length <= 0;
	}

	@computed
	get documentEntityKeys(): models.query.IQueryContextConfigurationDocumentEntityKey | undefined {
		const { appStore } = this.storeContext;
		return appStore.documentEntityKeys;
	}

	@action
	async fetchDocuments() {
		const { kurtosysApiStore, appStore } = this.storeContext;
		const searchDocumentsBody: ISearchDocumentsRequestBody = utils.object.deepMergeObjects(
			{},
			appStore.searchDocumentsBody,
		);
		delete searchDocumentsBody.limit;
		delete searchDocumentsBody.start;
		try {
			const documentResponse: IDocumentSearchResponse = await kurtosysApiStore.searchDocuments.execute({
				body: searchDocumentsBody,
			});
			return documentResponse;
		} catch (ex) {
			console.warn('Problem Fetching Documents for CSV Export:', ex);
		}
		return undefined;
	}

	@action
	getRows = async () => {
		const { tableStore } = this.storeContext;
		const documentsResponse = await this.fetchDocuments();
		if (documentsResponse) {
			const { values, entities, joinMap } = documentsResponse;
			return tableStore.convertDocumentsToRows(
				values,
				this.rawColumns,
				entities,
				joinMap,
				this.documentEntityKeys,
			);
		}
		return [];
	};

	@computed
	get rawColumns(): IExportToCsvConfigurationColumn[] {
		const { tableStore } = this.storeContext;
		const tableColumns = tableStore.columns;
		const columns = (this.configuration && this.configuration.columns) || [];
		return columns.map((column) => {
			const { tableColumnId } = column;
			if (tableColumnId) {
				const tableColumn = tableColumns.find((tableColumn) => tableColumn.id === tableColumnId);
				if (tableColumn) {
					return utils.object.deepMergeObjects(tableColumn, column);
				}
			}
			return column;
		});
	}

	@computed
	get columns(): models.helpers.CsvDownloadHelper.ICsvDownloadHelperOptionsColumn[] {
		const rawColumns = this.rawColumns;
		return rawColumns.map((rawColumn) => {
			const { key, header, format } = rawColumn;
			return {
				key,
				header: this.storeContext.translationStore.translate(header || ''),
				format,
			};
		});
	}

	@action
	exportToCsv = async () => {
		const loadingKey = 'exportToCsv';
		this.startLoading(loadingKey);

		const inputs: models.helpers.CsvDownloadHelper.ICsvDownloadHelperInputs = {
			culture: this.storeContext.appStore.culture,
		};

		const rows = await this.getRows();
		const columns = this.columns;

		const options: models.helpers.CsvDownloadHelper.ICsvDownloadHelperOptions = {
			columns,
			filename: this.filenameQuery || {
				queryOptionsType: 'none',
				value: this.filename,
			},
			rowsQuery: {
				queryOptionsType: 'none',
				value: rows,
			},
			separator: this.separator,
		};

		const csvDownloadHelper = new helpers.CsvDownloadHelper(inputs, options, this.storeContext.queryStore);
		await csvDownloadHelper.execute();

		this.stopLoading(loadingKey);
	};

	sleep = async (interval: number) => {
		return new Promise<void>((resolve) => {
			setTimeout(() => {
				resolve();
			}, interval);
		});
	};
}
