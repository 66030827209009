import { IApplicationAssetRegister, CacheOptions } from '../models/commonTypes';
import { Manifest } from '../configuration/Manifest';

export type TExternalScript =
	| string
	| {
			src: string;
			options?: TExternalScriptOptions;
			preventBaseAddressPrefix?: boolean;
			children?: TExternalScript[];
	  };

export type TExternalScriptOptions = {
	async?: boolean;
	crossOrigin?: string | null;
	defer?: boolean;
	integrity?: string;
	noModule?: boolean;
	referrerPolicy?: string;
	text?: string;
};

export async function loadExternalScripts(
	manifest: Manifest,
	externalScripts: TExternalScript[],
	onComplete: () => void,
	baseAddress?: string,
	ksysAppAssetsCacheOptions?: CacheOptions,
) {
	let hasError = false;
	if (externalScripts && externalScripts.length > 0) {
		const promises = externalScripts.map((externalScript) =>
			loadScript(manifest, externalScript, baseAddress, ksysAppAssetsCacheOptions),
		);
		try {
			await Promise.all(promises);
		} catch (ex) {
			console.log({ ex });
			hasError = true;
		}
	}
	if (!hasError) {
		if (onComplete) {
			onComplete();
		}
	}
}

export async function loadScript(
	manifest: Manifest,
	script: TExternalScript,
	baseAddress?: string,
	ksysAppAssetsCacheOptions?: CacheOptions,
): Promise<HTMLScriptElement> {
	let src = typeof script === 'string' ? script : script.src;
	const options: TExternalScriptOptions = (typeof script === 'string' ? {} : script.options) || {};
	const preventBaseAddressPrefix = (typeof script === 'object' && script.preventBaseAddressPrefix) || false;
	if (src && baseAddress && !src.startsWith('http://') && !src.startsWith('https://') && !preventBaseAddressPrefix) {
		const workingBaseAddress = baseAddress.endsWith('/')
			? baseAddress.substr(0, baseAddress.length - 1)
			: baseAddress;
		const workingSrc = src.startsWith('/') ? src.substr(1) : src;
		src = [workingBaseAddress, workingSrc].join('/');
	}

	if (src && src.indexOf('/applicationManager/apps/ksys-app-assets/') > -1) {
		src = appendCacheQueryString(src, undefined, ksysAppAssetsCacheOptions);
	}

	const currentWindow = window as any;
	const scriptStoreKey = 'ksys-loadScript-store';
	if (currentWindow && !currentWindow[scriptStoreKey]) {
		currentWindow[scriptStoreKey] = {};
	}
	const scriptsLoaded = (currentWindow && currentWindow[scriptStoreKey]) || {};

	for (const documentScript of Array.from(document.scripts)) {
		if (documentScript.dataset.source !== 'ksys') {
			scriptsLoaded[documentScript.src] = true;
		}
	}

	return new Promise(async (resolve, reject) => {
		let scriptElement: HTMLScriptElement | undefined = Array.from(document.scripts).find(
			(script) => script.src === src,
		);
		if (scriptElement && scriptsLoaded[src]) {
			if (typeof script === 'object' && script.children && script.children.length > 0) {
				loadExternalScripts(
					manifest,
					script.children,
					() => {
						resolve(scriptElement);
					},
					baseAddress,
				);
			} else {
				resolve(scriptElement);
			}
		} else {
			let mustInsert = false;
			if (!scriptElement) {
				mustInsert = true;
				scriptElement = document.createElement('script');
				if (options) {
					const optionKeys = Object.keys(options);
					if (optionKeys && optionKeys.length > 0) {
						for (const key of optionKeys) {
							(scriptElement as any)[key] = (options as any)[key];
						}
					}
				}
				// Do this here to stop someone overriding these options
				scriptElement.dataset.source = 'ksys';
				// Adding cfasync data property to ignore Rocket Loader from cloudflare
				scriptElement.dataset.cfasync = 'false';
				scriptElement.type = 'text/javascript';
				scriptElement.src = src;
				scriptElement.crossOrigin = 'crossorigin';
			}
			if (scriptElement.addEventListener) {
				scriptElement.addEventListener(
					'load',
					() => {
						scriptsLoaded[src] = true;
						if (typeof script === 'object' && script.children && script.children.length > 0) {
							loadExternalScripts(
								manifest,
								script.children,
								() => {
									resolve(scriptElement);
								},
								baseAddress,
							);
						} else {
							resolve(scriptElement);
						}
					},
					false,
				);
				scriptElement.addEventListener(
					'error',
					(error) => {
						delete scriptsLoaded[src];
						reject(`Could not load script: ${src}`);
					},
					false,
				);
			} else {
				reject('No addEventListener on scriptElement');
			}

			if (mustInsert) {
				const currentScript = getCurrentScript(manifest);
				if (currentScript && currentScript.parentNode) {
					currentScript.parentNode.insertBefore(scriptElement, currentScript);
				} else {
					reject('Could not find document.currentScript or document.currentScript.parentNode');
				}
			}
		}
	});
}

export function getCurrentScript(manifest: Manifest): HTMLScriptElement | undefined {
	return (document.currentScript ||
		(() => {
			const scripts = document.getElementsByTagName('script');
			const scriptName = `/main-${manifest.ksysAppTemplateId}.js`;
			for (let i = 0; i < scripts.length; i++) {
				const script = scripts[i];
				if (script.src.indexOf(scriptName) > -1) {
					return script;
				}
			}
			return undefined;
		})()) as HTMLScriptElement;
}

interface IQueryStringObj {
	lastModified?: string;
	cache?: string;
}

export function appendCacheQueryString(
	fileName: string,
	assetRegister?: IApplicationAssetRegister,
	cacheOptions?: CacheOptions,
) {
	const queryStringObj: IQueryStringObj = {};
	if (assetRegister) {
		queryStringObj.lastModified = assetRegister.lastModified;
	}
	if (cacheOptions) {
		queryStringObj.cache = JSON.stringify(cacheOptions);
	}
	let counter = 0;
	const queryString = Object.keys(queryStringObj).reduce((acc, key, index) => {
		const value = queryStringObj[key as keyof IQueryStringObj];
		const assignments = [`?${key}=`, `&${key}=`];
		if (
			typeof value !== 'undefined' &&
			assignments.filter((assignment) => fileName.indexOf(assignment) > -1).length === 0
		) {
			acc = `${acc}${counter > 0 ? '&' : ''}${key}=${encodeURIComponent(value)}`;
			counter++;
		}
		return acc;
	}, '');
	if (queryString && queryString.length > 0) {
		const separator = fileName.includes('?') ? '&' : '?';
		fileName = `${fileName}${separator}${queryString}`;
	}
	return fileName;
}
