import { computed, action, observable } from 'mobx';
import { StoreBase } from '../../../common/StoreBase';
import { IBulkUrlCopyConfiguration } from '../models';
import { THorizontalAlign } from '@kurtosys/ksys-app-components/dist/models/THorizontalAlign';
import { utils } from '@kurtosys/ksys-app-template';
import { IQueryOptions } from '../../../models/commonTypes';
import { ICopyUrlFeedback } from '../../Table/models';

export class BulkUrlCopyStore extends StoreBase {
	static componentKey: 'bulkUrlCopy' = 'bulkUrlCopy';

	@computed
	get configuration(): IBulkUrlCopyConfiguration | undefined {
		if (this.storeContext && this.storeContext.appStore) {
			return this.storeContext.appStore.getComponentConfiguration(BulkUrlCopyStore.componentKey);
		}
	}

	@action
	async initialize(): Promise<void> { }

	@computed
	get show(): boolean {
		return !!(this.configuration && this.configuration.show);
	}

	@computed
	get separator(): string {
		return (this.configuration && this.configuration.separator) || '\n';
	}

	@computed
	get buttonText(): string {
		return (this.configuration && this.configuration.buttonText) || 'Copy URLs';
	}

	@computed
	get selectedDocumentCount(): number {
		const { tableStore } = this.storeContext;
		return tableStore.selectedDocumentClientCodes.length;
	}

	@computed
	get disabled(): boolean {
		const { tableStore } = this.storeContext;
		return tableStore.selectedDocumentClientCodes.length <= 0;
	}

	@computed
	get showBadge(): boolean {
		return (
			(this.configuration &&
				this.configuration.badge &&
				this.configuration.badge.show &&
				this.selectedDocumentCount > 0) ||
			false
		);
	}

	@computed
	get badgeAlign(): THorizontalAlign {
		return (this.configuration && this.configuration.badge && this.configuration.badge.align) || 'left';
	}

	@computed
	get selectedDocumentRows(): any[] {
		const { tableStore } = this.storeContext;
		return tableStore.selectedDocumentClientCodes;
	}

	@computed
	get copyUrlQuery(): IQueryOptions | undefined {
		return this.configuration && this.configuration.copyUrlOptions && this.configuration.copyUrlOptions.query;
	}

	@computed
	get copyUrlFeedback(): Required<ICopyUrlFeedback> {
		const defaultFeedback: Required<ICopyUrlFeedback> = {
			success: 'Copied {total} selected documents to clipboard',
			error: 'Could not copy url',
		};

		return {
			...defaultFeedback,
			...(this.configuration && this.configuration.copyUrlOptions && this.configuration.copyUrlOptions.query),
		};
	}

	// Copy selected documents to clipboard
	copyUrlsToClipboard = () => {
		const { feedbackStore, translationStore, appStore } = this.storeContext;
		feedbackStore.clear();
		if (this.copyUrlQuery) {
			try {
				const selectedDocuments = [];
				const selectedDocAnalytics = [];

				for (const row of this.selectedDocumentRows) {
					const url = this.getQueryValue(this.copyUrlQuery, row, {
						context: {
							response: row,
							document: row.document,
						},
					});

					selectedDocuments.push(url);
					selectedDocAnalytics.push({
						documentURL: url,
						documentType: row.documentType,
						documentTitle: row.title,
					});
				}

				navigator.clipboard.writeText(selectedDocuments.join(this.separator));
				const message = translationStore.translate(this.copyUrlFeedback.success, {
					total: this.selectedDocumentCount,
				});
				if (appStore && appStore.analyticsHelper) {
					appStore.analyticsHelper.logEvent({
						event: 'document_bulk_copy_url',
						eventType: 'click',
						context: {
							documents: selectedDocAnalytics,
						},
					});
				}

				feedbackStore.success(message);
			}
			catch (e) {
				feedbackStore.error(this.copyUrlFeedback.error);
			}
		}
	}
}
