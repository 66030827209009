import { computed, action, observable } from 'mobx';
import { StoreBase } from '../../../common/StoreBase';
import { ICopyUrlFeedback, ITableConfiguration, RowAction, TActionsType } from '../models';
import { ITableColumn } from '@kurtosys/ksys-app-components/dist/components/base/Table/models/ITableColumn';
import { IQueryOptions, IDocument, IExecutionOptions, IQueryContext } from '../../../models/commonTypes';
import {
	ITableEvents,
	ITableProps as ITableComponentProps,
} from '@kurtosys/ksys-app-components/dist/components/base/Table/models';
import { ITableGroupingProps } from '@kurtosys/ksys-app-components/dist/components/base/TableGrouping/models';
import { IContextMenuAction } from '@kurtosys/ksys-app-components/dist/components/base/ContextMenu/models';
import { models, query, utils } from '@kurtosys/ksys-app-template';
import { SortButtonDirection } from '@kurtosys/ksys-app-components/dist/components/base/SortButton/models';
import { ISearchDocumentsRequestBody } from '@kurtosys/ksys-api-client/dist/models/requests/documents/ISearchDocumentsRequestBody';
import { ITableGroup } from '@kurtosys/ksys-app-components/dist/components/base/Table/models/ITableGroup';
import { ITableRow } from '@kurtosys/ksys-app-components/dist/components/base/Table/models/ITableRow';
import { QueryStore } from '@kurtosys/ksys-app-components/dist/shared/QueryStore';
import { IActionsProps } from '@kurtosys/ksys-app-components/dist/components/base/Actions/models';
import { IButtonProps } from '@kurtosys/ksys-app-components/dist/components/base/Button/models';
import { IRedirectButtonProps } from '@kurtosys/ksys-app-components/dist/components/base/RedirectButton/models';
import { ITableGrouping } from '@kurtosys/ksys-app-components/dist/components/base/TableGrouping/models/ITableGrouping';
import { IIconOptions } from '@kurtosys/ksys-app-components/dist/components/base/Icon/models';
import { IStickyOption } from '../../../models/app/IStickyConfig';

export type TColumn = ITableColumn & {
	valueQuery?: IQueryOptions;
	tooltipInputQueries?: IQueryOptions[];
};

export interface ISelectedGroupsByPage {
	[key: number]: any[];
}

export class TableStore extends StoreBase {
	static componentKey: 'table' = 'table';

	@observable.ref selectedDocumentClientCodes: any[] = [];
	@observable.ref expandedRowKeys: Map<string, boolean> = new Map();
	@observable.ref selectedGroupsByPage: ISelectedGroupsByPage = {};
	@observable.ref tableBreakpointStickyProps: Record<string, { expanded: string, closed: string }> = {};
	@observable filtersOpen: boolean = false;

	@computed
	get selectedGroupsByCurrentPage(): any[] {
		const { pagingStore } = this.storeContext;
		return this.selectedGroupsByPage[pagingStore.currentPage] || [];
	}

	@action
	setSelectedGroupsByCurrentPage(groups: any[]) {
		const { pagingStore } = this.storeContext;
		this.selectedGroupsByPage = {
			...this.selectedGroupsByPage,
			[pagingStore.currentPage]: groups,
		};
	}

	@computed
	get configuration(): ITableConfiguration | undefined {
		if (this.storeContext && this.storeContext.appStore) {
			return this.storeContext.appStore.getComponentConfiguration(TableStore.componentKey);
		}
	}

	@computed
	get rowClickAction(): RowAction | undefined {
		return this.configuration && this.configuration.rowClickAction;
	}

	@computed
	get groupingField(): string | undefined {
		return this.configuration && this.configuration.grouping && this.configuration.grouping.field;
	}

	@computed
	get groupingSort(): any | undefined {
		return this.configuration && this.configuration.grouping && this.configuration.grouping.sort;
	}

	@computed
	get actionsType(): TActionsType {
		return (this.configuration && this.configuration.actionsType) || 'context-menu';
	}
	@computed
	get expandGroupsByField(): string[] {
		return (
			(this.configuration &&
				this.configuration.grouping &&
				this.configuration.grouping.autoExpandGroupsByField) ||
			[]
		);
	}

	@computed
	get showGroupCheckboxes(): boolean {
		return !!(this.configuration && this.configuration.showGroupCheckboxes);
	}

	@action
	onToggleChange = (value: boolean, row: ITableRow): void => {
		const groupingKey = this.groupingField;

		if (groupingKey) {
			const rowKey = row[groupingKey];

			if (value) {
				this.expandedRowKeys.set(rowKey, true);
			} else {
				this.expandedRowKeys.set(rowKey, false);
			}
		}
	}

	logRowExpand = (row: ITableRow, columns?: ITableColumn[]): void => {
		const { appStore } = this.storeContext;
		if (appStore && appStore.analyticsHelper) {
			const rowKey = this.groupingField ? row[this.groupingField] : '';
			const columnInfo = columns ? columns.map(x => { return { id: x.id, key: x.key, header: x.header }; }) : [];
			appStore.analyticsHelper.logEvent({
				event: 'table_row_expanded',
				eventType: 'click',
				context: {
					title: rowKey,
					columns: columnInfo,
				},
			});
		}

	}

	@computed
	get grouping(): ITableGroup | undefined {
		const { appStore, pagingStore } = this.storeContext;
		const grouping = this.configuration && this.configuration.grouping && this.configuration.grouping.tableGrouping;
		if (!utils.typeChecks.isNullOrUndefined(grouping)) {
			grouping.onFetchChildren = (row: ITableRow, queryStore: QueryStore) => {
				// sets up the context for the getRowsQuery config so that child querys/conditionals function correctly within Table.Rows.fetchChildren
				if (queryStore.executionOptions) {
					queryStore.executionOptions.context = {
						globalDocuments: appStore.documents,
						documentEntities: appStore.documentEntities,
						documentJoinMap: appStore.documentJoinMap
					};

					queryStore.executionOptions.contextConfiguration = {
						documentEntityKeys: appStore.documentEntityKeys,
					};
				}
			};
			grouping.onToggleChange = this.onToggleChange;
			grouping.onExpand = this.logRowExpand;

			if (this.showGroupCheckboxes) {
				grouping.cardGroupingCheckbox = {
					show: true,
					onChange: this.handleRowSelect,
				};
			}

			const autoExpandFirstGroup =
				grouping && grouping.autoExpandFirstGroup && pagingStore.start === 0 && this.expandedRowKeys.size === 0;
			return {
				...grouping,
				autoExpandFirstGroup,
				orchestrateChildRows: this.orchestrateChildRows as any,
			};
		}
	}

	orchestrateChildRows = async (
		rawChildRows: any[],
		group: ITableGroup,
		parentRow: ITableRow,
	): Promise<ITableRow[]> => {
		let response = this.convertDocumentsToRows(
			rawChildRows,
			this.columns,
			this.documentEntities,
			this.documentJoinMap,
			this.documentEntityKeys,
		);
		const { orchestrateChildRowsOptions = {} } = group;
		const { distinctRows } = orchestrateChildRowsOptions;
		if (distinctRows) {
			const distinctResponse: Map<string, ITableRow> = new Map();
			const childGroup = group.childGroup;
			const columns = (childGroup && childGroup.columns) || this.columns;
			response.forEach((row) => {
				const keys: {
					key: string;
					value: string;
				}[] = [];
				columns.forEach((column) => {
					keys.push({
						key: column.key,
						value: row[column.key],
					});
				});
				const key = keys.map((keyValue) => `${ keyValue.key }=${ keyValue.value }`).join('|');
				distinctResponse.set(key, row);
			});
			response = Array.from(distinctResponse.values());
		}
		return response;
	}

	isValidSortColumn = (column: TColumn): boolean => {
		return !!(
			column.valueQuery &&
			column.valueQuery.queryOptionsType === 'document' &&
			column.valueQuery.key &&
			column.valueQuery.key !== 'inferred'
		);
	}

	@action
	async initialize(): Promise<void> {
		for (const groupByField of this.expandGroupsByField) {
			this.expandedRowKeys.set(groupByField, true);
		}
		let counter = 0;
		for (const rawColumn of this.rawColumns) {
			if (
				rawColumn.sort &&
				rawColumn.sort.direction &&
				rawColumn.sort.direction !== SortButtonDirection.unset &&
				this.isValidSortColumn(rawColumn)
			) {
				const { appStore } = this.storeContext;
				if (counter === 0) {
					appStore.sort = this.orchestrateSort(rawColumn);
				} else {
					rawColumn.sort.direction = SortButtonDirection.unset;
				}
				counter++;
			}
		}
	}

	@action
	handleRowClick = (row: any) => {
		console.log({ row });
	}

	@action
	clearSelectedFields = () => {
		this.selectedDocumentClientCodes = [];
		this.selectedGroupsByPage = {};
	}

	@action
	handleRowSelect = (row: any, isSelected: boolean) => {
		const groupingKey = this.groupingField;
		const { clientCode } = row;
		const childRows =
			row.groupedRow && groupingKey ? this.rawRows.filter((r) => r[groupingKey] === row[groupingKey]) : [];

		if (isSelected) {
			// Handle select of non grouped row
			if (
				!row.groupedRow &&
				!this.selectedDocumentClientCodes.some(
					({ clientCode: selectedRowClientCode }) => selectedRowClientCode === clientCode,
				)
			) {
				this.selectedDocumentClientCodes = [...this.selectedDocumentClientCodes, row];
			}
			// Handle select of grouped row
			if (row.groupedRow && groupingKey) {
				if (!this.selectedGroupsByCurrentPage.some((groupedValue) => groupedValue === row[groupingKey])) {
					this.setSelectedGroupsByCurrentPage([...this.selectedGroupsByCurrentPage, row[groupingKey]]);
				}
				this.selectedDocumentClientCodes = utils.collection.union(
					this.selectedDocumentClientCodes,
					childRows,
					(item) => {
						return item.clientCode;
					},
				);
			}
		} else {
			this.selectedDocumentClientCodes = this.selectedDocumentClientCodes.filter(
				({ clientCode: selectedClientCode }) => selectedClientCode !== clientCode,
			);
			// Handle deselect of grouped row/row in group
			if (groupingKey) {
				if (row.groupedRow) {
					this.selectedDocumentClientCodes = utils.collection.except(
						this.selectedDocumentClientCodes,
						childRows,
						(item) => {
							return item.clientCode;
						},
					);
				}
				this.setSelectedGroupsByCurrentPage(
					this.selectedGroupsByCurrentPage.filter((groupedValue) => groupedValue !== row[groupingKey]),
				);
			}
		}
	}

	@computed
	get contextMenuActions(): IContextMenuAction[] {
		const { documentPreviewStore, appStore } = this.storeContext;
		const result: IContextMenuAction[] = [];
		const actions = this.configuration && this.configuration.actions;
		if (actions && actions.preview) {
			result.push({
				...actions.preview,
				onClick: (row: any) => {
					documentPreviewStore.setDocumentPreview(row.documentId, row);
				},
			});
		}
		if (actions && actions.download) {
			result.push({
				...actions.download,
				onClick: (row: any) => {
					appStore.saveDocument(row);
				},
			});
		}
		if (actions && actions.copyUrl) {
			result.push({
				...actions.copyUrl,
				onClick: (row: any) => {
					this.copyUrl(row);
				},
			});
		}
		return result;
	}

	@computed
	get actionsProps(): IActionsProps {
		const { documentPreviewStore, appStore } = this.storeContext;
		const buttons: (IButtonProps | IRedirectButtonProps)[] = [];
		const actions = this.configuration && this.configuration.actions;
		// We handle analytics for all the actions when the action is carried out, so we suppress the
		// default logging by the Button component
		if (actions && actions.preview) {
			buttons.push({
				...actions.preview,
				suppressAnalytics: true,
				text: actions.preview.label,
				onClick: (event, row: any) => {
					documentPreviewStore.setDocumentPreview(row.documentId, row);
				},
			});
		}
		if (actions && actions.download) {
			buttons.push({
				...actions.download,
				suppressAnalytics: true,
				text: actions.download.label,
				onClick: (event, row: any) => {
					appStore.saveDocument(row);
				},
			});
		}
		if (actions && actions.copyUrl) {
			const iconOptions = this.copyUrlIconOptions;

			if (iconOptions && iconOptions.filename) {
				buttons.push({
					...actions.copyUrl,
					suppressAnalytics: true,
					iconProps: {
						iconOptions,
					},
					onClick: (event, row: any) => {
						this.copyUrl(row);
					},
				});
			} else {
				buttons.push({
					...actions.copyUrl,
					suppressAnalytics: true,
					text: actions.copyUrl.label,
					onClick: (event, row: any) => {
						this.copyUrl(row);
					},
				});
			}
		}
		return {
			buttons,
		};
	}

	orchestrateSort = (column: TColumn, direction?: SortButtonDirection) => {
		if (!direction) {
			direction = (column.sort && column.sort.direction) || SortButtonDirection.unset;
		}
		if (!this.isValidSortColumn(column) || direction === SortButtonDirection.unset || !column.valueQuery) {
			return undefined;
		}
		let key = column.valueQuery.key;
		if (key === 'meta') {
			key = column.valueQuery.childKey;
		}
		return {
			key,
			direction: direction === SortButtonDirection.ascending ? 'ASC' : 'DESC',
		} as ISearchDocumentsRequestBody['sort'];
	}

	@action
	handleSort = (column: TColumn, direction: SortButtonDirection) => {
		const { appStore, pagingStore } = this.storeContext;
		appStore.sort = this.orchestrateSort(column, direction);
		this.rawColumns.forEach((rawColumn) => {
			if (rawColumn.sort) {
				const rawColumnDirection = column.id === rawColumn.id ? direction : SortButtonDirection.unset;
				rawColumn.sort.direction = rawColumnDirection;
			}
		});
		pagingStore.resetToFirstPage(false);
		appStore.fetchDocuments();
	}

	@computed
	get rawColumns(): TColumn[] {
		const rawColumns = (this.configuration && this.configuration.columns) || [];
		return rawColumns;
	}

	@computed
	get columns(): TColumn[] {
		const rawColumns = this.rawColumns;
		const output: TColumn[] = [];
		output.push({
			id: '_selected',
			key: '_selected',
			valueQuery: {
				queryOptionsType: 'none',
				value: '',
			},
			checkboxProps: {
				onChange: this.handleRowSelect,
			},
			hideValue: (row: any, value: any) => {
				return !this.showGroupCheckboxes;
			},
		});
		rawColumns.forEach((rawColumn) => {
			if (rawColumn.sort && rawColumn.sort.enabled) {
				rawColumn.sort.onChange = async (payload: any, direction: SortButtonDirection) => {
					this.handleSort(rawColumn, direction);
				};
				if (!rawColumn.valueQuery || rawColumn.valueQuery.queryOptionsType !== 'document') {
					delete rawColumn.sort;
				}
			}
			output.push(rawColumn);
		});
		if (this.actionsType === 'context-menu' && !utils.typeChecks.isNullOrEmpty(this.contextMenuActions)) {
			const contextMenuColumn: TColumn = {
				id: '_contextMenu',
				key: '_contextMenu',
				valueQuery: {
					queryOptionsType: 'none',
					value: '',
				},
				contextMenuProps: {
					label: '...',
					hideAsset: true,
					actions: this.contextMenuActions,
				},
				hideValue: (row: any, value: any) => {
					return row.groupedRow;
				},
			};
			output.push(contextMenuColumn);
		}
		if (this.actionsType === 'buttons' && !utils.typeChecks.isNullOrEmpty(this.actionsProps.buttons)) {
			const actionsColumn: TColumn = {
				id: '_actions',
				key: '_actions',
				valueQuery: {
					queryOptionsType: 'none',
					value: '',
				},
				actionsProps: this.actionsProps,
				hideValue: (row: any, value: any) => {
					return row.groupedRow;
				},
			};
			output.push(actionsColumn);
		}
		return output;
	}

	@computed
	get rawRows(): any[] {
		const documents = this.documents || [];
		return this.convertDocumentsToRows(
			documents,
			this.columns,
			this.documentEntities,
			this.documentJoinMap,
			this.documentEntityKeys,
		);
	}

	convertDocumentsToRows = (
		documents: IDocument[],
		columns: TColumn[],
		documentEntities?: models.api.document.IEntities,
		documentJoinMap?: models.api.document.IJoinMap,
		documentEntityKeys?: models.query.IQueryContextConfigurationDocumentEntityKey,
	) => {
		const results = documents.map((document, index) => {
			const row: { [key: string]: any; } = {
				document,
				documentId: document.documentId,
				clientCode: document.clientCode,
				_rowKey: document.documentId,
			};

			const currentExecutionOptions = this.storeContext.queryStore.executionOptions;
			const mergedExecutionOptions: IExecutionOptions = {
				...currentExecutionOptions,
				context: {
					...currentExecutionOptions.context,
					document,
					documentEntities,
					documentJoinMap,
				},
				contextConfiguration: {
					...currentExecutionOptions.contextConfiguration,
					documentEntityKeys,
				},
			};

			for (const column of columns) {
				const { key, valueQuery, tooltipInputQueries } = column;
				if (valueQuery) {
					const queryInstance = this.storeContext.queryStore.createQuery(valueQuery);
					const value = queryInstance.execute(mergedExecutionOptions);
					row[key] = value;
				}
				if (tooltipInputQueries && tooltipInputQueries.length > 0) {
					const tooltipInputsKey = '_tooltipInputs';
					const tooltipInputs: Record<string, any> = row[tooltipInputsKey] || {};
					tooltipInputQueries.forEach((tooltipInputQuery) => {
						if (tooltipInputQuery && tooltipInputQuery.queryId) {
							const queryInstance = this.storeContext.queryStore.createQuery(tooltipInputQuery);
							const tooltipInputValue = queryInstance.execute(mergedExecutionOptions);
							const { queryId } = tooltipInputQuery;
							tooltipInputs[queryId] = tooltipInputValue;
						} else {
							console.warn(
								`No query id on the tooltip input query defined on column with key of "${ column.key }". Query: `,
								tooltipInputQuery,
							);
						}
					});
					row[tooltipInputsKey] = tooltipInputs;
				}
			}
			row._selected = this.selectedDocumentClientCodes.some(
				({ clientCode: selectedClientCode }) => selectedClientCode === document.clientCode,
			);
			return row;
		});
		return results;
	}

	@computed
	get expandAllGroups(): boolean {
		return (
			(this.configuration && this.configuration.grouping && this.configuration.grouping.autoExpandAllGroups) ||
			false
		);
	}

	@computed
	get expandFirstGroup(): boolean {
		return (
			(this.configuration &&
				this.configuration.grouping &&
				this.grouping &&
				this.grouping.autoExpandFirstGroup) ||
			false
		);
	}

	@computed
	get rows(): any[] {
		const groupingKey = this.groupingField;
		const expandedRows = this.expandedRowKeys;
		const rawRows = this.rawRows;

		if (utils.typeChecks.isNullOrEmpty(groupingKey)) {
			return rawRows;
		}

		const groupRows: any[] = [];
		const groupedValue: any = { groupedRow: true };

		this.columns.forEach((c) => {
			groupedValue[c.key] = '';
		});
		for (const row of rawRows) {
			const rowGroupingValue = row[groupingKey];
			if (!groupRows.some((d) => d[groupingKey] === rowGroupingValue)) {
				let isExpanded: boolean | undefined = false;
				// Check if we have user invoked row expand/collapse state before we evaluate defaults when a change occurs
				if (expandedRows.has(rowGroupingValue)) {
					isExpanded = expandedRows.get(rowGroupingValue);
				} else {
					isExpanded = this.expandAllGroups;
				}
				groupRows.push({
					...groupedValue,
					[groupingKey]: rowGroupingValue,
					_expanded: isExpanded || undefined,
					_selected: this.selectedGroupsByCurrentPage.some(
						(groupedValue) => groupedValue === row[groupingKey],
					),
				});
			}
		}
		return groupRows;
	}

	@computed
	get documents(): IDocument[] {
		const { appStore } = this.storeContext;
		return appStore.documents;
	}

	@computed
	get documentEntityKeys(): models.query.IQueryContextConfigurationDocumentEntityKey | undefined {
		const { appStore } = this.storeContext;
		return appStore.documentEntityKeys;
	}

	@computed
	get documentEntities(): models.api.document.IEntities | undefined {
		const { appStore } = this.storeContext;
		return appStore.documentEntities;
	}

	@computed
	get documentJoinMap(): models.api.document.IJoinMap | undefined {
		const { appStore } = this.storeContext;
		return appStore.documentJoinMap;
	}

	@computed
	get events(): ITableEvents {
		const events: ITableEvents = {};
		if (this.rowClickAction === RowAction.preview) {
			events.row = {
				onClick: (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, row?: any) => {
					const tagName = (event.target as HTMLButtonElement).tagName;
					if (tagName === 'TD') {
						const { documentPreviewStore } = this.storeContext;
						documentPreviewStore.setDocumentPreview(row.documentId, row);
					}
				},
			};
		} else if (this.rowClickAction === RowAction.download) {
			events.row = {
				onClick: (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, row?: any) => {
					const tagName = (event.target as HTMLButtonElement).tagName;
					if (tagName === 'TD') {
						const { appStore } = this.storeContext;
						appStore.saveDocument(row);
					}
				},
			};
		} else if (this.rowClickAction === RowAction.copyUrl) {
			events.row = {
				onClick: (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, row?: any) => {
					const tagName = (event.target as HTMLButtonElement).tagName;
					if (tagName === 'TD') {
						this.copyUrl(row);
					}
				},
			};
		}
		return events;
	}

	@computed
	get tableGroupingProps(): ITableGroupingProps {
		const tableProps: ITableComponentProps = {
			columns: this.columns,
			events: this.events,
			grouping: this.grouping,
			placeholder: '-',
			cardListProps: {
				itemStyle: 'justified',
			},
			responsiveMode: 'pivotToCard',
			sort: {
				mode: 'single',
			},
			queryStore: this.storeContext.queryStore,
		};
		return {
			tableProps,
			rows: this.rows,
			grouping: this.externalGrouping,
		};
	}

	@computed
	get externalGrouping(): ITableGrouping | undefined {
		return this.configuration && this.configuration.externalGrouping;
	}

	@computed
	get showLoading(): boolean {
		const { facetedSearchStore, appStore, filtersStore } = this.storeContext;
		return this.isLoading || facetedSearchStore.isLoading || appStore.isLoading || filtersStore.isLoading;
	}

	@computed
	get copyUrlQuery(): IQueryOptions | undefined {
		return this.configuration && this.configuration.copyUrlOptions && this.configuration.copyUrlOptions.query;
	}

	@computed
	get copyUrlIconOptions(): IIconOptions | undefined {
		return this.configuration && this.configuration.copyUrlOptions && this.configuration.copyUrlOptions.iconOptions;
	}

	@computed
	get copyUrlFeedback(): Required<ICopyUrlFeedback> {
		const defaultFeedback: Required<ICopyUrlFeedback> = {
			success: 'Copied {url} to clipboard',
			error: 'Could not copy url',
		};

		return {
			...defaultFeedback,
			...(this.configuration && this.configuration.copyUrlOptions && this.configuration.copyUrlOptions.query),
		};
	}

	@action
	async copyUrl(row: any) {
		const { feedbackStore, appStore } = this.storeContext;
		feedbackStore.clear();
		if (this.copyUrlQuery) {
			try {
				const url = this.getQueryValue(this.copyUrlQuery, row, {
					context: {
						response: row,
						document: row.document,
					},
				});
				await navigator.clipboard.writeText(url);

				const message = utils.replacePlaceholders({
					values: { url },
					text: this.copyUrlFeedback.success,
				});
				if (appStore && appStore.analyticsHelper) {
					appStore.analyticsHelper.logEvent({
						event: 'document_copy_url',
						eventType: 'click',
						context: {
							document: {
								documentURL: url,
								documentType: row.documentType,
								documentTitle: row.title,
							},
						},
					});
				}
				feedbackStore.success(message);
			}
			catch (ex) {
				feedbackStore.error(this.copyUrlFeedback.error);
			}
		}
	}

	@computed
	get showFilters(): boolean {
		return this.storeContext.filtersStore.showFilters;
	}

	@action
	getStickyOffset(offset: number = 0, stickyOption: IStickyOption | undefined, filtersOpen: boolean): string | undefined {
		let offsetResult;
		if (stickyOption && stickyOption.enabled && stickyOption.offset) {
			if (!filtersOpen) {
				let offsetValue = stickyOption.offset - offset;
				offsetValue = offsetValue < 0 ? 0 : offsetValue;
				offsetResult = `${ offsetValue }px`;
			}
			else {
				offsetResult = `${ stickyOption.offset }px`;
			}
		}
		return offsetResult;
	}
}
