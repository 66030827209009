import React, { Component, Fragment } from 'react';
import { IAppProps } from './models/IAppProps';
import { IAppState } from './models/IAppState';
import { inject, observer } from 'mobx-react';
import Wrapper from './styledComponents/Wrapper';
import StickyWrapper from './styledComponents/StickyWrapper';
import DebugInfoAction from '../shared/DebugInfoAction';
import NoDataPlaceholder from '@kurtosys/ksys-app-components/dist/components/base/NoDataPlaceholder';
import Disclaimer from '@kurtosys/ksys-app-components/dist/components/overview/Disclaimer';
import { InjectedStyledComponent } from '../shared/InjectedStyledComponent';
import Loading from '../shared/Loading';
import SkeletonLoader from '../shared/SkeletonLoader';
import Application from '@kurtosys/ksys-app-components/dist/components/base/Application';
import Card from '@kurtosys/ksys-app-components/dist/components/base/Card';
import DocumentPreview from '../DocumentPreview';
import Table from '../Table';
import Paging from '../Paging';
import Feedback from '../Feedback';
import Filters from '../Filters';
import SearchRow from '../SearchRow';
import SearchWrapper from './styledComponents/SearchWrapper';
import { CSSProperties } from 'styled-components';
import { injectDialogPlaceholder } from '../../utils/dialogPlaceholder';
import { useBreakpointValue } from '../shared/useBreakpointValue';

@inject('appStore', 'queryStore')
@observer
export class App extends Component<IAppProps, IAppState> {
	static configurationKey: 'app' = 'app';
	static styleKey: 'app' = 'app';
	constructor(props: IAppProps) {
		super(props);
		if (props.appStore) {
			injectDialogPlaceholder(props.appStore.dialogPlaceholderId);
		}
	}

	render() {
		const { className, appStore, queryStore } = this.props;
		if (!appStore || !queryStore) {
			return null;
		}
		const { isBootstrapped, skeletonLoader, skeletonLoaders } = appStore;
		if (!isBootstrapped) {
			const { embedLoadingElement } = this.props;

			if (skeletonLoader && !embedLoadingElement) {
				return <SkeletonLoader config={ skeletonLoader } variables={ skeletonLoaders && skeletonLoaders.variables } />;
			}
			return <Loading embedLoadingElement={ embedLoadingElement } />;
		}
		const {
			theme,
			showPlaceholder,
			noDataPlaceholder: noDataPlaceholderText,
			noDataPlaceholderDisclaimer,
			libraryComponentsConfiguration,
			appCardProps,
			getTranslateFunction,
			culture,
			assets,
			assetCacheOptions,
			assetRegisters,
			getAccessibilityTextFunction,
			appComponentConfiguration,
		} = appStore;

		const noDataPlaceholder =
			(noDataPlaceholderDisclaimer && <Disclaimer { ...noDataPlaceholderDisclaimer } />) ||
			(noDataPlaceholderText && <NoDataPlaceholder>{ noDataPlaceholderText }</NoDataPlaceholder>);

		const translate = getTranslateFunction();
		const accessibilityText = getAccessibilityTextFunction();

		const stickyProps = appComponentConfiguration && appComponentConfiguration.stickyProps || {};
		const { searchFilters } = stickyProps;
		const stickyWrapperStickyStyle: CSSProperties = (searchFilters && searchFilters.enabled && searchFilters.offset != undefined) ? { position: 'sticky', top: `${ searchFilters.offset }px` } : {};

		return (
			<Application
				theme={ theme }
				configuration={ libraryComponentsConfiguration }
				translate={ translate }
				culture={ culture }
				assets={ assets }
				assetCacheOptions={ assetCacheOptions }
				assetRegisters={ assetRegisters }
				accessibilityText={ accessibilityText }
				analyticsHelper={ appStore.analyticsHelper }
			>
				<DebugInfoAction appStore={ appStore } />
				<Wrapper className={ className }>
					<Card { ...appCardProps }>
						<StickyWrapper style={ stickyWrapperStickyStyle }>
							<SearchWrapper>
								<SearchRow />
							</SearchWrapper>
							<Filters />
						</StickyWrapper>
						{ showPlaceholder && noDataPlaceholder }
						{
							!showPlaceholder && (
								<Fragment>
									<Table />
									<Paging />
								</Fragment>
							)
						}
					</Card>
					<Feedback />
				</Wrapper>
				<DocumentPreview />
			</Application>
		);
	}
}

export default InjectedStyledComponent(App, 'app');
