import { action, observable } from 'mobx';

export class MediaQuery {
	@observable
	public matches: boolean = false;
	public query: string;

	private mediaQuery: MediaQueryList;

	constructor(query: string) {
		this.query = query;
		this.mediaQuery = window.matchMedia(this.query);
		// validate current screen size
		this.matches = this.mediaQuery.matches;
		// monitor screen size changes
		this.mediaQuery.addEventListener('change', this.handler);
	}

	public cleanup = () => this.mediaQuery.removeEventListener('change', this.handler);

	@action
	private handler = (event: any) => this.matches = event.matches
}