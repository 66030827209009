import React, { Component } from 'react';
import {
	IPagingProps,
	IPagingState,
} from './models';
import { inject, observer } from 'mobx-react';
import { InjectedStyledComponent } from '../shared/InjectedStyledComponent';
import Wrapper from './styledComponents/Wrapper';
import PagingComponent from '@kurtosys/ksys-app-components/dist/components/base/Paging';
import Loading from '../shared/Loading';

@inject('appStore', 'pagingStore')
@observer
export class Paging extends Component<IPagingProps, IPagingState> {
	static configurationKey: 'paging' = 'paging';
	static styleKey: 'paging' = 'paging';
	constructor(props: IPagingProps) {
		super(props);
	}
	render() {
		const { className, pagingStore } = this.props;

		if (!pagingStore) {
			return null;
		}

		if (pagingStore.showLoading) {
			return <Loading />;
		}

		return (
			<Wrapper className={ className }>
				<PagingComponent { ...pagingStore.pagingComponentProps } />
			</Wrapper>
		);
	}
}

export default InjectedStyledComponent(Paging, 'paging');

