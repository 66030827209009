import { helpers } from '@kurtosys/ksys-app-template';
import { ITranslation, ITranslations } from '../../../models/commonTypes';
import { observable, action, computed } from 'mobx';
import { StoreBase } from '../../../common/StoreBase';
import { getHydrationPoint } from '../../../initialize';

export class TranslationStore extends StoreBase {
	@observable cultureRaw?: string;
	@action
	async initialize(): Promise<void> {

	}

	@observable.ref translationRecords: ITranslation[] = [];
	@computed
	get translationHelper(): helpers.TranslationHelper {
		return new helpers.TranslationHelper(this.targetTranslations, this.fallbackTranslations);
	}

	@computed
	get appConfiguration() {
		return this.storeContext.appStore && this.storeContext.appStore.configuration;
	}

	@computed
	get appParamsHelper() {
		return this.storeContext.appStore && this.storeContext.appStore.appParamsHelper;
	}

	@computed
	get baseCulture() {
		let baseCulture = 'Default';
		const appConfig = this.appConfiguration;
		if (
			appConfig &&
			appConfig.culture &&
			appConfig.culture.base
		) {
			baseCulture = appConfig.culture.base;
		}
		return baseCulture;
	}

	@computed
	get defaultCulture() {
		let defaultCulture = 'en-GB';
		const appConfig = this.appConfiguration;
		if (
			appConfig &&
			appConfig.culture &&
			appConfig.culture.default
		) {
			defaultCulture = appConfig.culture.default;
		}
		return defaultCulture;
	}

	@computed
	get culture() {
		if (this.cultureRaw) {
			return this.cultureRaw;
		}
		let culture = this.defaultCulture;
		const appParams = this.appParamsHelper;
		if (
			appParams &&
			appParams.inputs &&
			appParams.inputs.culture
		) {
			culture = appParams.inputs.culture;
		}
		return culture.split('-').map((segment, index) => index === 0 ? segment.toLowerCase() : segment.toUpperCase()).join('-');
	}


	@action
	async setCulture(value: string) {
		this.cultureRaw = value;
		await this.loadTranslations();
	}

	// Builds a translation dictionary object for the current culture from all translation data. E.g:
	// { 'phrase': 'translation', 'phrase': 'translation', etc }
	@computed
	get targetTranslations() {
		return this.getTranslationsDictionary(this.translationRecords, this.culture);
	}

	@computed
	get fallbackTranslations() {
		return this.getTranslationsDictionary(this.translationRecords, this.defaultCulture);
	}

	getTranslationsDictionary(translationRecords: ITranslation[], targetCulture: string) {
		const response: ITranslations = translationRecords
			.filter((translationRecord) => {
				return (
					translationRecord.translationCulture === targetCulture &&
					translationRecord.culture === this.baseCulture
				);
			})
			.reduce((acc: ITranslations, translationRecord) => {
				const { phrase, translation } = translationRecord;
				acc[phrase] = translation;
				return acc;
			}, {});

		return response;
	}

	@action
	async loadTranslations() {
		if (this.storeContext) {
			const { kurtosysApiStore, appStore } = this.storeContext;
			const { coreConfigurations = {} } = appStore;
			const { hydration = {} } = coreConfigurations;
			const { ignoreTranslations: ignoreTranslationsRaw = false } = hydration;
			const ignoreTranslations = (typeof ignoreTranslationsRaw !== 'boolean' || ignoreTranslationsRaw);
			const hydrationPoint = getHydrationPoint();
			if (!ignoreTranslations && hydrationPoint.translations && hydrationPoint.translations.length > 0) {
				this.translationRecords = hydrationPoint.translations;
			}
			else {
				// Prefer spread however some apps seem to have build errors with the tsconfig.preset (Around the target of es5). 
				// TODO: Update once we have figured out the problem. Not consistent across apps
				// const translationCulture = [...new Set([this.culture, this.defaultCulture])];
				const translationCulture = Array.from(new Set([this.culture, this.defaultCulture]));
				this.translationRecords = await kurtosysApiStore.listTranslations.execute({
					body: { translationCulture },
				});
			}
		}
	}

	translate = (key: string, placeholders?: any) => {
		return this.translationHelper.translate(key, placeholders);
	}
}
