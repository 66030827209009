import { IToastProps } from '@kurtosys/ksys-app-components/dist/components/portal/Toast/models';
import { IDialogProps } from '@kurtosys/ksys-app-components/dist/components/base/Dialog/models';
import { computed, action, observable } from 'mobx';
import { StoreBase } from '../../../common/StoreBase';
import { IFeedbackConfiguration, TFeedbackMode, TFeedbackType } from '../models';
import { utils } from '@kurtosys/ksys-app-template';
import { ToastType } from '@kurtosys/ksys-app-components/dist/components/portal/Toast/models/ToastType';

export class FeedbackStore extends StoreBase {
	static componentKey: 'feedback' = 'feedback';

	@observable text: string = '';
	@observable type: TFeedbackType | undefined;

	@computed
	get configuration(): IFeedbackConfiguration | undefined {
		if (this.storeContext && this.storeContext.appStore) {
			return this.storeContext.appStore.getComponentConfiguration(FeedbackStore.componentKey);
		}
	}

	@computed
	get mode(): TFeedbackMode {
		return (this.configuration && this.configuration.mode) || 'none';
	}

	@computed
	get displaySeconds(): number {
		return (this.configuration && this.configuration.displaySeconds) || 3;
	}

	@computed
	get toastProps(): IToastProps {
		return {
			toastId: utils.guid.generateGuid(),
			type: this.type as ToastType,
			text: this.text,
			canClose: true,
			displaySeconds: this.displaySeconds,
			onClose: () => {
				this.clear();
			},
		};
	}

	@computed
	get dialogProps(): IDialogProps {
		return {
			title: this.type,
			showCloseButton: true,
			onClose: () => {
				this.clear();
			},
		};
	}

	@action
	async initialize(): Promise<void> {

	}

	@action clear() {
		this.text = '';
		this.type = undefined;
	}

	@action success(text: string) {
		this.text = text;
		this.type = 'success';
	}

	@action error(text: string) {
		this.text = text;
		this.type = 'error';
	}

	@action warning(text: string) {
		this.text = text;
		this.type = 'warning';
	}

	@action info(text: string) {
		this.text = text;
		this.type = 'info';
	}
}