import { models } from '@kurtosys/ksys-app-template';
import { IAppConfigurationBase } from '../models/app/IAppConfigurationBase';

export const APPLICATION_CLIENT_CONFIGURATIONS: models.api.applicationManager.IApplicationClientConfiguration[] = [
	{
		applicationClientConfigurationId: 1,
		configuration: {
			culture: {
				base: 'en-GB',
				default: 'en-GB',
			},
					data: {
				context: {
					entityByType: {
						FUND: {
							inputKey: 'urlFundId',
							clientCodeProperty: 'fund_code',
							include: {
								commentaries: {},
							},
						},
						CLSS: {
							inputKey: 'isin',
							clientCodeProperty: 'isin',
							include: {
								commentaries: {},
							},
						},
					},
				},
			},
		} as IAppConfigurationBase,
		name: 'root',
		applicationClientConfigurationTypeId: 1,
	},
];
