import React, { Component } from 'react';
import { IFilterProps, IFilterState } from './models';
import { inject, observer } from 'mobx-react';
import { InjectedStyledComponent } from '../shared/InjectedStyledComponent';
import Wrapper from './styledComponents/Wrapper';
import Label from './styledComponents/Label';
import Dropdown from './styledComponents/Dropdown';

import { IDropdownItem } from '@kurtosys/ksys-app-components/dist/components/base/Dropdown/models/IDropdownItem';
import { DateFilter } from '../DateFilter/DateFilter';

@inject('appStore', 'filterStore')
@observer
export class Filter extends Component<IFilterProps, IFilterState> {
	static configurationKey: 'filter' = 'filter';
	static styleKey: 'filter' = 'filter';
	constructor(props: IFilterProps) {
		super(props);
	}

	handleItemSelect = (item: IDropdownItem | IDropdownItem[]) => {
		const { code, filterStore } = this.props;
		if (filterStore) {
			filterStore.setSelectedFilters(code, item);
		}
	};

	render() {
		const {
			className,
			filterStore,
			label,
			code,
			dateFilter,
			items,
			selectedItems,
			virtualize,
			searchInput: searchInputOverride = {},
		} = this.props;

		if (!filterStore) {
			return null;
		}

		const { pinSelected, searchInput } = filterStore.storeContext.filtersStore;

		return (
			<Wrapper className={className}>
				<Label>{label}</Label>
				{(dateFilter && <DateFilter code={code} />) || (
					<Dropdown
						pinSelected={pinSelected}
						items={items || []}
						virtualize={virtualize}
						placeholder={'Select an item'}
						selectedPlaceholder={'{selected} Items'}
						isMultiSelect={true}
						checkboxPosition={'left'}
						selectedItem={selectedItems}
						onChange={this.handleItemSelect}
						searchInput={{ ...searchInput, ...searchInputOverride }}
					/>
				)}
			</Wrapper>
		);
	}
}

export default InjectedStyledComponent(Filter, 'filter');
