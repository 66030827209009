import { utils } from '@kurtosys/ksys-app-template';
import { computed, isArrayLike } from 'mobx';
import { IQueryStringObject } from "../models/app/IQueryStringObject";
import { IQueryStringOptions } from '../models/app/IQueryStringOptions';

export class QueryString {
	options: IQueryStringOptions;

	constructor(options: IQueryStringOptions, defaultOptions: IQueryStringOptions = {}) {
		this.options = { ...defaultOptions, ...(options || {}) };
	}

	get raw(): string {
		return window.location.search;
	}

	@computed
	get current(): IQueryStringObject {
		return utils.url.getQueryStringAsObject(this.raw);
	}

	hasValue = (values: string | string[], value: string) => {
		return typeof values === "string" ? values === value : values.includes(value);
	};

	fetch = (stripPrefix: boolean = false): IQueryStringObject => {
		const response: IQueryStringObject = {};
		if (this.enabled) {
			const current = this.current;
			Object.keys(current).filter(key => key.startsWith(this.prefix)).forEach((key) => {
				const responseKey = stripPrefix ? key.replace(this.prefix, '') : key;
				response[responseKey] = current[key];
			});
		}
		return response;
	}

	getKey = (suffix: string) => {
		return `${ this.prefix }${ suffix }`;
	}

	get enabled(): boolean {
		return this.options.enabled || false;
	}

	get prefix(): string {
		return this.options.prefix || '';
	}

	update = (replacementValues: IQueryStringObject) => {
		const queryStringObj: IQueryStringObject = this.current;
		// Loop over, replace all prefixed values, combine arrays with separator from config
		let newQueryStringObj: IQueryStringObject = Object.keys(queryStringObj).reduce((acc: IQueryStringObject, key) => {
			if (!key.startsWith(this.prefix)) {
				acc[key] = queryStringObj[key];
			}
			return acc;
		}, {});
		Object.keys(replacementValues).forEach((key) => {
			const responseKey = !key.startsWith(this.prefix) ? `${ this.prefix }${ key }` : key;
			newQueryStringObj[responseKey] = replacementValues[key];
		});

		const newQueryString = Object.keys(newQueryStringObj).reduce((acc: string[], key) => {
			let values = newQueryStringObj[key];
			if (!isArrayLike(values)) {
				values = [values];
			}
			values.forEach(value => {
				acc.push(`${ key }=${ encodeURIComponent(value) }`);
			});
			return acc;
		}, []).join('&');
		// eslint-disable-next-line no-restricted-globals
		history.replaceState(newQueryStringObj, "updateQueryString", `?${ newQueryString }`);
	}
}