import { computed, action } from 'mobx';
import { StoreBase } from '../../../common/StoreBase';
import { ISearchRowConfiguration } from '../models';

export class SearchRowStore extends StoreBase {
	static componentKey: 'searchRow' = 'searchRow';

	@computed
	get configuration(): ISearchRowConfiguration | undefined {
		if (this.storeContext && this.storeContext.appStore) {
			return this.storeContext.appStore.getComponentConfiguration(SearchRowStore.componentKey);
		}
	}

	@action
	async initialize() : Promise<void> {

	}

	@computed
	get searchRowOrder() {
		return (this.configuration && this.configuration.searchRowOrder) || ["IdentifierSearch", "FacetedSearch", "DateSearch", "SavedSearch", "ClearSearchAndFilters", "ExportToCsv", "BulkCopyUrl", "BulkDownload", "FilterToggle"]
	}
}