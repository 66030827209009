import React, { Component } from 'react';
import { IFeedbackProps, IFeedbackState } from './models';
import { inject, observer } from 'mobx-react';
import { InjectedStyledComponent } from '../shared/InjectedStyledComponent';
import Wrapper from './styledComponents/Wrapper';
import Toaster from '@kurtosys/ksys-app-components/dist/components/portal/Toaster';
import Dialog from '@kurtosys/ksys-app-components/dist/components/base/Dialog';
import { utils } from '@kurtosys/ksys-app-template';

@inject('appStore', 'feedbackStore')
@observer
export class Feedback extends Component<IFeedbackProps, IFeedbackState> {
	static configurationKey: 'feedback' = 'feedback';
	static styleKey: 'feedback' = 'feedback';
	constructor(props: IFeedbackProps) {
		super(props);
	}
	render() {
		const { className, feedbackStore } = this.props;

		if (!feedbackStore) {
			return null;
		}

		const { mode, toastProps, dialogProps, text } = feedbackStore;

		if (mode === 'none' || utils.typeChecks.isNullOrEmpty(text)) {
			return null;
		}

		return (
			<Wrapper className={ className }>
				{ mode === 'toast' && <Toaster items={ [toastProps] }></Toaster> }
				{ mode === 'alert' && <Dialog { ...dialogProps }>{ text }</Dialog> }
			</Wrapper>
		);
	}
}

export default InjectedStyledComponent(Feedback, 'feedback');
