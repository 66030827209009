import React, { Component, Fragment, ReactNode } from 'react';
import {
	ISearchRowProps,
	ISearchRowState,
} from './models';
import { inject, observer } from 'mobx-react';
import { computed } from 'mobx';
import { utils } from '@kurtosys/ksys-app-template';
import Breakpoint from '@kurtosys/ksys-app-components/dist/components/base/Breakpoint';
import FacetedSearch from '../FacetedSearch';
import IdentifierSearch from '../IdentifierSearch';
import DateSearch from '../DateSearch';
import ExportToCsv from '../ExportToCsv';
import BulkUrlCopy from '../BulkUrlCopy';
import BulkDownload from '../BulkDownload';
import ClearSearchAndFilters from '../ClearSearchAndFilters';
import SavedSearch from '../SavedSearch';
import SearchWrapperInner from '../App/styledComponents/SearchWrapperInner';
import { InjectedStyledComponent } from '../shared/InjectedStyledComponent';
import FiltersHeaderToggle from '../Filters/styledComponents/FiltersHeaderToggle';
import FilterIndicator from '../Filters/styledComponents/FilterIndicator';
import { FiltersStore } from '../Filters/stores/FiltersStore';
import { IFilterToggleButtonProps } from '../Filters/models/IFilterToggleButtonProps';
import { IIconProps } from '@kurtosys/ksys-app-components/dist/components/base/Icon/models';
import { THorizontalAlign } from '@kurtosys/ksys-app-components/dist/models/THorizontalAlign';

@inject('appStore', 'searchRowStore')
@observer
export class SearchRow extends Component<ISearchRowProps, ISearchRowState> {
	static configurationKey: 'searchRow' = 'searchRow';
	static styleKey: 'searchRow' = 'searchRow';
	constructor(props: ISearchRowProps) {
		super(props);
	}

	renderSelectedCount(totalFiltersSelected: number) {
		return (
			<FilterIndicator>{ totalFiltersSelected }</FilterIndicator>
		);
	}

	renderFiltersToggleButton(filtersStore: FiltersStore, toggleButtonProps: IFilterToggleButtonProps) {
		const { toggleShowFilters, filterToggleText, totalFiltersSelected } = filtersStore;
		const { selectedFilterCount, selectedFilterCountPosition } = toggleButtonProps;
		if (!toggleButtonProps || !this.showToggleButton(toggleButtonProps) || toggleButtonProps.buttonPosition !== 'SearchBarRow') {
			return null;
		}
		return (
			<FiltersHeaderToggle
				onClick={ toggleShowFilters }
				iconProps={ toggleButtonProps.openFiltersIcon }
				iconPosition={ toggleButtonProps.openFiltersIconPosition }
			>
				{ (selectedFilterCount && selectedFilterCountPosition === 'left') && this.renderSelectedCount(totalFiltersSelected) }
				{ filterToggleText }
				{ (selectedFilterCount && selectedFilterCountPosition === 'right') && this.renderSelectedCount(totalFiltersSelected) }
			</FiltersHeaderToggle>
		);
	}

	showToggleButton(filtersHeaderToggle: IFilterToggleButtonProps) {
		return filtersHeaderToggle.showButton;
	}

	@computed
	get searchRowComponentsOrderOptions(): Record<string, ReactNode> {

		const { appStore } = this.props;
		if (!appStore) {
			return {};
		}

		const {
			searchType,
		} = appStore;

		const { exportToCsvStore, bulkUrlCopyStore, clearSearchAndFiltersStore, filtersStore } = appStore.storeContext;

		const { filterToggleButton } = filtersStore;

		return {
			'FacetedSearch': (
				<Fragment>
					{ searchType === 'faceted' && (
						<SearchWrapperInner>
							<FacetedSearch />
						</SearchWrapperInner>
					) }
				</Fragment>
			),
			'IdentifierSearch': (
				<Fragment>
					{ searchType === 'identifier' && (
						<SearchWrapperInner>
							<IdentifierSearch />
						</SearchWrapperInner>
					) }
				</Fragment>
			),
			'DateSearch': (
				<SearchWrapperInner>
					<DateSearch />
				</SearchWrapperInner>
			),
			'SavedSearch': (
				<SearchWrapperInner>
					<SavedSearch />
				</SearchWrapperInner>
			),
			'ClearSearchAndFilters': (
				<Fragment>
					{ clearSearchAndFiltersStore.show && (
						<SearchWrapperInner>
							<ClearSearchAndFilters />
						</SearchWrapperInner>
					) }
				</Fragment>
			),
			'ExportToCsv': (
				<Fragment>
					{ exportToCsvStore.show && (
						<SearchWrapperInner>
							<ExportToCsv />
						</SearchWrapperInner>
					) }
				</Fragment>
			),
			'BulkCopyUrl': (
				<Fragment>
					{ bulkUrlCopyStore.show && (
						<SearchWrapperInner>
							<BulkUrlCopy />
						</SearchWrapperInner>
					) }
				</Fragment>
			),
			'BulkDownload': (
				<SearchWrapperInner>
					<BulkDownload />
				</SearchWrapperInner>
			),
			'FilterToggle': (
				<SearchWrapperInner>
					{ this.renderFiltersToggleButton(filtersStore, filterToggleButton) }
				</SearchWrapperInner>
			),
		};
	}
	render() {
		const { searchRowStore } = this.props;

		if (!searchRowStore) {
			return null;
		}

		const { searchRowOrder } = searchRowStore;

		return (
			<Fragment>
				{
					searchRowOrder.map((searchRowComponentKey) => {
						const Node = this.searchRowComponentsOrderOptions[searchRowComponentKey] as React.Component;
						return (<Fragment key={ searchRowComponentKey }>{ Node }</Fragment>);
					})
				}
			</Fragment>
		);
	}
}

export default InjectedStyledComponent(SearchRow, 'searchRow');

