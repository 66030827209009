import React from 'react';
import styled from 'styled-components';
import { utils } from '@kurtosys/ksys-app-template';
import { withSelector } from '@kurtosys/ksys-app-components/dist/shared/withSelector';
import { getSelector } from '@kurtosys/ksys-app-components/dist/shared/selector';
import { inject, observer } from 'mobx-react';
import { IAppStoreInjection } from '../../models/app/IAppStoreInjection';
import { IComponentStyles } from '../../models/app/IComponentStyles';
import { Manifest } from '../../configuration/Manifest';

const manifest = new Manifest();

const getCss = utils.componentStyle.getCss;

// tslint:disable-next-line: function-name
export function InjectedStyledComponent<K extends keyof IComponentStyles>(
	ComponentOrElement: any,
	componentStyleKey: K,
	childStyleKeys: string[] = [],
	storeKeys: string[] = ['appStore'],
	rawCssPrefix?: string,
	rawCssSuffix?: string,
) {
	const appName = (manifest.packageName || '').replace('ksys-app-', '');
	const selector = getSelector(appName, componentStyleKey, ...childStyleKeys);
	const styles = (props: IAppStoreInjection) => {
		const { appStore } = props;
		const componentStyle = appStore && appStore.getComponentStyle(componentStyleKey, ...(childStyleKeys || []));
		return getCss(componentStyle, props);
	};
	return (
		inject(...storeKeys)(
			observer(
				withSelector(
					styled((props) => {
						// strip stores
						const newProps = Object.keys(props).reduce((newProps: Record<string, any>, propKey: string) => {
							if (storeKeys.find(key => key === propKey) === undefined && propKey !== 'componentStyle') {
								newProps[propKey] = props[propKey];
							}
							return newProps;
						}, {});

						return <ComponentOrElement { ...newProps } />;
					})`
						${ rawCssPrefix || '' }
						${ styles }
						${ rawCssSuffix || '' }
					`,
					selector,
				),
			),
		)
	);
}
