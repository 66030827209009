import { computed, action, observable } from 'mobx';
import { StoreBase } from '../../../common/StoreBase';
import { IDocumentSearchClause } from '../../../models/commonTypes';
import { IDateSearchConfiguration } from '../models';
import { utils } from '@kurtosys/ksys-app-template';
import { IDateRangeInputProps } from '@kurtosys/ksys-app-components/dist/components/base/DateRangeInput/models';
import { IDropdownItem } from '@kurtosys/ksys-app-components/dist/components/base/Dropdown/models/IDropdownItem';
import { IActionsProps } from '@kurtosys/ksys-app-components/dist/components/base/Actions/models/IActionsProps';
import { IIconProps } from '@kurtosys/ksys-app-components/dist/components/base/Icon/models/IIconProps';

const { isNullOrEmpty, isNullOrUndefined } = utils.typeChecks;

export class DateSearchStore extends StoreBase {
	static componentKey: 'dateSearch' = 'dateSearch';

	@observable property: IDropdownItem | undefined;
	@observable startDate: string = '';
	@observable endDate: string = '';
	@observable showWindow: boolean = false;

	@computed
	get configuration(): IDateSearchConfiguration | undefined {
		if (this.storeContext && this.storeContext.appStore) {
			return this.storeContext.appStore.getComponentConfiguration(DateSearchStore.componentKey);
		}
	}

	@computed
	get iconProps(): IIconProps {
		return (this.configuration && this.configuration.iconProps) || {
			asset: 'base.dateDropdown.calendar',
		};
	}

	@computed
	get closeButtonText(): string {
		return (this.configuration && this.configuration.closeButtonText) || 'Close';
	}

	@computed
	get submitButtonText(): string {
		return (this.configuration && this.configuration.submitButtonText) || 'Add Date';
	}

	@computed
	get hide(): boolean {
		return !!(this.configuration && this.configuration.hide);
	}

	@computed
	get metaFields(): string[] {
		return (this.configuration && this.configuration.metaFields) || [];
	}


	@action
	async initialize(): Promise<void> {

	}

	toggleWindow = (mustShow?: boolean) => {
		if (isNullOrUndefined(mustShow)) {
			mustShow = !this.showWindow;
		}
		this.showWindow = mustShow;
	}

	@computed
	get formattedStartDate() {
		return utils.formatter.formatDateTime(this.storeContext.appStore.culture, {
			dateFormatString: 'YYYY-MM-DDT00:00:00Z',
		}, this.startDate, undefined);
	}

	@computed
	get formattedEndDate() {
		return utils.formatter.formatDateTime(this.storeContext.appStore.culture, {
			dateFormatString: 'YYYY-MM-DDT23:59:59.999Z',
		}, this.endDate, undefined);
	}

	@computed
	get searchTerm(): IDocumentSearchClause | undefined {
		if (!isNullOrEmpty(this.startDate) && !isNullOrEmpty(this.endDate) && !isNullOrEmpty(this.property)) {
			return {
				property: this.property.value,
				matchtype: 'RANGE',
				values: [this.formattedStartDate, this.formattedEndDate],
				meta: this.metaFields.includes(this.property.value),
			};
		}
		return undefined;
	}

	@computed
	get dateFieldKeys(): string[] {
		const { dateFieldItems } = this.dateRangeInputProps;
		if (dateFieldItems) {
			return dateFieldItems.map((item) => item.value);
		}
		return [];
	}

	@computed
	get dateRangeInputProps(): IDateRangeInputProps {
		const configProps = (this.configuration && this.configuration.dateRangeInputProps) || {};
		return {
			...configProps,
			actionsProps: this.actionsProps,
			startDate: this.startDate,
			endDate: this.endDate,
			selectedDateField: this.property,
			onChange: this.onChange,
		};
	}

	@action
	onSubmit = () => {
		const { tableStore, facetedSearchStore } = this.storeContext;
		if (this.searchTerm) {
			tableStore.clearSelectedFields();
			facetedSearchStore.addDateSearchTerm(this.searchTerm);
		}
		this.onClose();
	}

	@action
	onClose = () => {
		this.property = undefined;
		this.startDate = '';
		this.endDate = '';
		this.toggleWindow(false);
	}

	@computed
	get submitButtonDisabled() {
		return utils.typeChecks.isNullOrUndefined(this.property) || utils.typeChecks.isNullOrEmpty(this.startDate) || utils.typeChecks.isNullOrEmpty(this.endDate);
	}

	@computed
	get actionsProps(): IActionsProps {
		return {
			suppressAnalytics: true,
			buttons: [
				{
					onClick: this.onSubmit,
					text: this.submitButtonText,
					className: 'submit-button',
					disabled: this.submitButtonDisabled,
				},
				{
					onClick: this.onClose,
					text: this.closeButtonText,
					className: 'close-button',
				},
			],
		};
	}

	@action
	onChange = (startDate?: string, endDate?: string, dateField?: IDropdownItem) => {
		this.startDate = startDate || '';
		this.endDate = endDate || '';
		this.property = dateField;
	}

}