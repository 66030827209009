import { computed, action, observable } from 'mobx';
import { StoreBase } from '../../../common/StoreBase';
import { IBulkDownloadConfiguration } from '../models';
import * as FileSaver from 'file-saver';
import { getExtension } from 'mime';
import { THorizontalAlign } from '@kurtosys/ksys-app-components/dist/models/THorizontalAlign';

export class BulkDownloadStore extends StoreBase {
	static componentKey: 'bulkDownload' = 'bulkDownload';

	@observable isLoading: boolean = false;

	@computed
	get configuration(): IBulkDownloadConfiguration | undefined {
		if (this.storeContext && this.storeContext.appStore) {
			return this.storeContext.appStore.getComponentConfiguration(BulkDownloadStore.componentKey);
		}
		return undefined;
	}

	@action
	async initialize(): Promise<void> { }

	@computed
	get filename(): string {
		return (this.configuration && this.configuration.filename) || 'DocumentDownload';
	}

	@computed
	get hide(): boolean {
		return !!(this.configuration && this.configuration.hide);
	}

	@computed
	get buttonText(): string {
		if (this.isLoading) {
			return 'Downloading...';
		}
		return (this.configuration && this.configuration.buttonText) || 'Download';
	}

	@computed
	get selectedDocumentCount(): number {
		const { tableStore } = this.storeContext;
		return tableStore.selectedDocumentClientCodes.length;
	}

	@computed
	get disabled(): boolean {
		const { tableStore } = this.storeContext;
		return tableStore.selectedDocumentClientCodes.length <= 0;
	}

	@computed
	get showBadge(): boolean {
		return (
			(this.configuration &&
				this.configuration.badge &&
				this.configuration.badge.show &&
				this.selectedDocumentCount > 0) ||
			false
		);
	}

	@computed
	get badgeAlign(): THorizontalAlign {
		return (this.configuration && this.configuration.badge && this.configuration.badge.align) || 'left';
	}

	@computed
	get selectedClientCodes(): string[] {
		const { tableStore } = this.storeContext;
		return tableStore.selectedDocumentClientCodes.map(({ clientCode }) => clientCode);
	}

	// Downloads
	getDocumentZip = async () => {
		if (this.selectedClientCodes && this.selectedClientCodes.length > 0) {
			const { kurtosysApiStore } = this.storeContext;
			const overrideOptions = {
				disableCachableRequests: true,
				body: { documentCodes: this.selectedClientCodes },
			};
			return await kurtosysApiStore.retrieveDocumentZip.execute(overrideOptions);
		}
		return;
	}

	saveDocumentZip = async () => {
		try {
			this.isLoading = true;
			const result: any = await this.getDocumentZip();
			const savedFilename = this.saveFile(result, this.filename);
			this.logSaveDocumentsEvent(savedFilename);
		}
		catch (e) {
			console.warn(e);
		}
		finally {
			this.isLoading = false;
		}
	}

	saveFile = (blob: any, filename: string) => {
		const extension = getExtension(blob.type);
		const saveFilename = extension ? `${ filename }.${ extension }` : filename;
		FileSaver.saveAs(blob, saveFilename);
		return saveFilename;
	}

	logSaveDocumentsEvent = (filename: string) => {
		const { appStore, tableStore, bulkUrlCopyStore } = this.storeContext;
		if (!appStore || !appStore.analyticsHelper) {
			return;
		}
		const selectedDocAnalytics = [];
		for (const row of tableStore.selectedDocumentClientCodes) {
			let url;
			if (bulkUrlCopyStore.copyUrlQuery) {
				url = bulkUrlCopyStore.getQueryValue(bulkUrlCopyStore.copyUrlQuery, row, {
					context: {
						response: row,
						document: row.document,
					},
				});
			}

			selectedDocAnalytics.push({
				documentURL: url,
				documentType: row.documentType,
				documentTitle: row.title,
			});
		}
		appStore.analyticsHelper.logEvent({
			event: 'document_bulk_download',
			eventType: 'click',
			context: {
				filename,
				documents: selectedDocAnalytics,
			},
		});
	}
}
