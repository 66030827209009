import { TBreakpointSize } from '@kurtosys/ksys-app-components/dist/models/TBreakpointSize';
import { computed } from 'mobx';
import { MediaQuery } from './MediaQuery';

export class Breakpoints {
	static xsmall: MediaQuery = new MediaQuery('(max-width: 575px)');
	static small: MediaQuery = new MediaQuery('(min-width: 576px) and (max-width: 767px)');
	static medium: MediaQuery = new MediaQuery('(min-width: 768px) and (max-width: 991px)');
	static large: MediaQuery = new MediaQuery('(min-width: 992px) and (max-width: 1199px)');
	static xlarge: MediaQuery = new MediaQuery('(min-width: 1200px)');

	@computed
	public static get active(): TBreakpointSize {
		if (Breakpoints.xsmall.matches) return 'xsmall';
		if (Breakpoints.small.matches) return 'small';
		if (Breakpoints.medium.matches) return 'medium';
		if (Breakpoints.large.matches) return 'large';
		if (Breakpoints.xlarge.matches) return 'xlarge';
		return 'xsmall';
	}
}