import { helpers } from '@kurtosys/ksys-app-template';
import { IStorageHelperConfigurationOptions } from '../models/commonTypes';
import { KurtosysApiStore } from '../components/App/stores/KurtosysApiStore';

export class UserValuesStorage {
	private storageHelper: helpers.StorageHelper;
	private kurtosysApiStore: KurtosysApiStore;
	private storageKey: string;

	constructor(kurtosysApiStore: KurtosysApiStore, storageHelperConfiguration: IStorageHelperConfigurationOptions, storageKey: string) {
		this.storageKey = storageKey;
		this.kurtosysApiStore = kurtosysApiStore;
		storageHelperConfiguration.getUserConfiguration = this.getUserValues;
		storageHelperConfiguration.setUserConfiguration = this.setUserValues;
		this.storageHelper = new helpers.StorageHelper(storageHelperConfiguration);
	}

	async getValues() {
		return await this.storageHelper.getStorageValues(this.storageKey);
	}

	async setValues(values: any) {
		const result = await this.storageHelper.setStorageValues(this.storageKey, values);
		if (!result) {
			console.warn(`StorageHelper - setStorageValues returned false, values were not set. Check for additional logs.`);
		}
	}

	private getUserValues = async (key: string): Promise<object> => {
		try {
			return await this.kurtosysApiStore.retrieveUserConfiguration.execute({
				body: {
					code: key,
				},
			});
		}
		catch (ex) {
			console.warn(ex);
		}
		return {};
	}

	private setUserValues = async (key: string, filterValues: string): Promise<boolean> => {
		try {
			await this.kurtosysApiStore.upsertUserConfiguration.execute({
				body: {
					code: key,
					config: filterValues,
				},
			});
			return true;
		}
		catch (ex) {
			console.warn(ex);
		}
		return false;
	}
}
