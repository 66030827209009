import React, { Component, RefObject } from 'react';
import { IDocumentPreviewProps, IDocumentPreviewState } from './models';
import { inject, observer } from 'mobx-react';
import { InjectedStyledComponent } from '../shared/InjectedStyledComponent';
import Wrapper from './styledComponents/Wrapper';
import Preview from './styledComponents/Preview';
import NoPreview from './styledComponents/NoPreview';
import { utils } from '@kurtosys/ksys-app-template';
import Translate from '@kurtosys/ksys-app-components/dist/components/base/Translate';
import Modal from '@kurtosys/ksys-app-components/dist/components/base/Modal';

@inject('appStore', 'documentPreviewStore')
@observer
export class DocumentPreview extends Component<IDocumentPreviewProps, IDocumentPreviewState> {
	static configurationKey: 'documentPreview' = 'documentPreview';
	static styleKey: 'documentPreview' = 'documentPreview';

	constructor(props: IDocumentPreviewProps) {
		super(props);
	}

	render() {
		const { className, documentPreviewStore, appStore } = this.props;
		if (!documentPreviewStore || utils.typeChecks.isNullOrUndefined(documentPreviewStore.selectedDocument)) {
			return null;
		}

		const {
			documentUrl = '',
			isPreviewable,
			noPreviewMessage,
			clearDocumentPreview,
			isOpen,
		} = documentPreviewStore;

		return (
			<Wrapper className={ className }>
				<Modal
					modalId={ appStore && appStore.dialogPlaceholderId }
					title={ 'Document Preview' }
					isOpen={ isOpen }
					openButtonProps={ {
						disabled: true,
					} }
					closeButtonProps={ {
						iconProps: {
							asset: 'base.toast.close',
						},
					} }
					onClose={ () => {
						clearDocumentPreview();
					} }>
					{ isPreviewable && (
						<Preview type="application/pdf" data={ documentUrl }>
						</Preview>
					) }
					{ !isPreviewable && (
						<NoPreview>
							<Translate>{ noPreviewMessage }</Translate>
						</NoPreview>
					) }
				</Modal>
			</Wrapper>
		);
	}
}

export default InjectedStyledComponent(DocumentPreview, 'documentPreview');
