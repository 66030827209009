/**
 * Target Position is used within the Filters.tsx and not by the configuration
 *
 * @export
 * @enum {number}
 */
export enum ToggleButtonTargetPosition {
	Modal = 'Modal',
	SearchBarRow = 'SearchBarRow',
	FilterRow = 'FilterRow',
}
