import React, { Component } from 'react';
import {
	ISavedSearchProps,
	ISavedSearchState,
} from './models';
import { inject, observer } from 'mobx-react';
import { InjectedStyledComponent } from '../shared/InjectedStyledComponent';
import Wrapper from './styledComponents/Wrapper';
import SavedSearchComponent from '@kurtosys/ksys-app-components/dist/components/base/SavedSearch';

@inject('appStore', 'savedSearchStore')
@observer
export class SavedSearch extends Component<ISavedSearchProps, ISavedSearchState> {
	static configurationKey: 'savedSearch' = 'savedSearch';
	static styleKey: 'savedSearch' = 'savedSearch';
	constructor(props: ISavedSearchProps) {
		super(props);
	}
	render() {
		const { className, savedSearchStore } = this.props;

		if (!savedSearchStore || savedSearchStore.hide) {
			return null;
		}

		const { savedSearchProps } = savedSearchStore;

		return (
			<Wrapper className={ className }>
				<SavedSearchComponent { ...savedSearchProps } />
			</Wrapper>
		);
	}
}

export default InjectedStyledComponent(SavedSearch, 'savedSearch');

