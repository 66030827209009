import { Manifest } from '../configuration/Manifest';
import { isDebug } from './isDebug';
const manifest = new Manifest();

export interface ILogOptions {
	message: string;
	detail?: any;
	configKey?: string;
	additionalContext?: string;
}

export type TLogType = 'debug' | 'info' | 'success' | 'warning' | 'error';

export interface IBaseLogOptions extends ILogOptions {
	type: TLogType;
	color: string;
}

export function log(options: IBaseLogOptions) {
	const { type, color, message, detail, configKey, additionalContext } = options;
	const app = manifest.packageName;
	const configContext = configKey ? `.${ configKey }` : '';
	const extraContext = additionalContext ? `.${ additionalContext }` : '';
	const args = [
		`%c${ type }: %c${ app }%c${ configContext }${ extraContext }%c: ${ message }`,
		`color: ${ color }; font-weight: 500; font-size: 1.3em; text-transform: uppercase;`,
		'color: #FFFFFF; font-weight: 900; text-transform: uppercase;',
		'color: #00FFDD; font-size: 1.1em;',
		'color: #FFFFFF; font-weight: normal; font-size: 0.9em;',
	];
	if (detail) {
		console.group(...args);
		console.log(detail);
		console.groupEnd();
	}
	else {
		console.log(...args);
	}
}

export function warning(options: ILogOptions) {
	log({
		...options,
		type: 'warning',
		color: '#eed202',
	});
}

export function error(options: ILogOptions) {
	log({
		...options,
		type: 'error',
		color: '#FF9494',
	});
}

export function info(options: ILogOptions) {
	log({
		...options,
		type: 'info',
		color: '#77CCFF',
	});
}

export function success(options: ILogOptions) {
	log({
		...options,
		type: 'info',
		color: '#00FF00',
	});
}

export function debug(options: ILogOptions) {
	if (isDebug()) {
		log({
			...options,
			type: 'debug',
			color: '#FF00FF',
		});
	}
}