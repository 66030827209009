import { AppStoreBase } from './AppStoreBase';
import { IAppComponents } from '../models/IAppComponents';
import { computed, observable, action, isArrayLike } from 'mobx';
import { StoreContext } from '../../../configuration/StoreContext';
import { Manifest } from '../../../configuration/Manifest';
import { ICardProps } from '@kurtosys/ksys-app-components/dist/components/base/Card/models';
import {
	IDocument,
	IDocumentSearchClause,
	IDocumentSearchResponse,
	IDocumentSort,
	IGlobalSearchChangeDetail,
	IQueryOptions,
	ISearchEntityRequest,
} from '../../../models/commonTypes';
import { IEntitySearchClause } from '../../../models/app/IEntitySearchClause';
import { ISearchDocumentsRequestBody } from '@kurtosys/ksys-api-client/dist/models/requests/documents/ISearchDocumentsRequestBody';
import { getExtension } from 'mime';
import FileSaver from 'file-saver';
import { models, utils } from '@kurtosys/ksys-app-template';
import { TSearchType } from '../models/TSearchType';
import { TRequestMethod } from '@kurtosys/ksys-app-template/dist/models/api/common';
import { IFacetedSearchDocumentRequest } from '../../../models/app/IFacetedSearchDocumentRequest';
import { IComponentConfigurations } from '../../../models/app/IComponentConfigurations';
import { Breakpoints } from '../../shared/Breakpoints';
/* [Component: appStoreComponentImport] */

export const DISALLOWED_FILTER_PARAMS: string[] = []; // Query string params that should not be handled as a filter
export const SPECIAL_SEARCH_PROPERTIES: string[] = ['filename', 'title', 'cultureCode'];

export class AppStore extends AppStoreBase {
	url: URL;
	@observable.ref documents: IDocument[] = [];
	@observable total: number = 0;
	@observable documentEntities: models.api.document.IEntities | undefined;
	@observable documentJoinMap: models.api.document.IJoinMap | undefined;
	@observable filterParams: any = {}; // Filters from query string parameters
	lastFetchDocumentsKey: string | undefined;
	@observable initialDocumentLoad: boolean = false;
	@observable.ref dialogGuid: string;

	constructor(element: HTMLElement, url: string, storeContext: StoreContext, manifest: Manifest) {
		super(element, url, storeContext, manifest);
		this.url = new URL(url);
		this.dialogGuid = utils.guid.generateGuid();
	}

	async customInitializeBefore() {
		this.registerGlobalSearch();
	}

	async customInitializeAfter() {
		this.storeContext.facetedSearchStore.initialize();
		this.storeContext.pagingStore.initialize();
		this.storeContext.tableStore.initialize();
		this.storeContext.filtersStore.initialize();
		this.storeContext.savedSearchStore.initialize();
		this.storeContext.identifierSearchStore.initialize();
		this.loadSearchFromUrl();
		if (!this.storeContext.filtersStore.hasQueryString) {
			this.fetchDocuments();
		}
	}

	/**
	 * To test the global search, use the following snippet in the terminal:
		let eventBus = document.querySelector('ksys-event-bus');
		if (eventBus) {
				eventBus.dispatchEvent(new CustomEvent('global-search-term', {
					detail: {
					searchTerm: 'factsheet'
				}
			}));
		}
	*/
	@action
	registerGlobalSearch = () => {
		this.storeContext.eventBusStore.initialize({
			[models.eventBus.EventType.globalSearchChange]: async (event: CustomEvent<IGlobalSearchChangeDetail>) => {
				const detail = (event as CustomEvent).detail;
				this.loadSearchFromEvent(detail);
			}
		})
	}

	@action
	loadSearchFromEvent = (eventDetail: IGlobalSearchChangeDetail) => {
		if (this.searchType !== 'faceted') {
			const { identifierSearchStore } = this.storeContext;
			identifierSearchStore.changeSearchTerm(eventDetail.searchTerm);
		}
	}

	@action
	loadSearchFromUrl() {
		this.getFilterParams(this.url.search);
		if (Object.keys(this.filterParams).length > 0) {
			const { facetedSearchStore } = this.storeContext;
			const searchRequest: IFacetedSearchDocumentRequest = {
				search: this.filterSearch,
			};
			facetedSearchStore.loadSelectedResults(searchRequest);
		}
	}

	get cardProps(): ICardProps | undefined {
		const cardProps = this.appComponentConfiguration && this.appComponentConfiguration.cardProps;
		return this.mergeQueriesAndProps(cardProps);
	}

	@observable
	sort: ISearchDocumentsRequestBody['sort'];

	@computed
	get dialogPlaceholderId() {
		return `ksys-de-dialog-placeholder-${ this.dialogGuid }`;
	}

	@computed
	get searchType(): TSearchType {
		const { facetedSearchStore, identifierSearchStore } = this.storeContext;
		const searchType = (this.appComponentConfiguration && this.appComponentConfiguration.searchType) || 'faceted';
		if (searchType === 'faceted' && facetedSearchStore.hide) {
			return 'none';
		}
		if (searchType === 'identifier' && identifierSearchStore.hide) {
			return 'none';
		}
		return searchType;
	}

	@computed
	get documentSearchRequest() {
		return this.replaceInputPlaceholders(
			(this.appComponentConfiguration && this.appComponentConfiguration.documentSearchRequest) || {},
		);
	}

	@computed
	get configSearch(): IDocumentSearchClause[] {
		return this.documentSearchRequest.search || [];
	}

	@computed
	get configSearchFacets(): any {
		const result: any = {};
		const documentSearchRequest: any = this.documentSearchRequest;

		for (const property of SPECIAL_SEARCH_PROPERTIES) {
			if (documentSearchRequest[`${ property }s`]) {
				result[property] = documentSearchRequest[`${ property }s`];
			}
		}
		if (documentSearchRequest.search) {
			for (const searchValue of documentSearchRequest.search) {
				result[searchValue.property] = searchValue.values;
			}
		}
		return result;
	}

	@computed
	get search(): IDocumentSearchClause[] {
		return this.getSearch();
	}

	getSearch(
		excludeConfig: boolean = false,
		excludeFacetedSearch: boolean = false,
		excludeFilters: boolean = false,
		forceIncludeDateFilters: boolean = false,
	) {
		const response: IDocumentSearchClause[] = [];
		const { facetedSearchStore, filtersStore, dateSearchStore } = this.storeContext;
		const restrictedProperties = [...SPECIAL_SEARCH_PROPERTIES];
		if (!excludeConfig) {
			response.push(...this.configSearch);
		}
		if (!excludeFacetedSearch) {
			const facetedSearch = facetedSearchStore.searchTerms.filter(
				(term) => !restrictedProperties.includes(term.property),
			);
			response.push(...facetedSearch);
		}
		if (!excludeFilters) {
			const filtersSearch = filtersStore.searchForFilters;
			response.push(...filtersSearch);
		}
		// If the faceted search has been excluded but the date filters are forced, add the date facets to the response
		// If the faceted search has not been excluded the date filters will already be in the response
		if (excludeFacetedSearch && forceIncludeDateFilters) {
			const dateSearch = facetedSearchStore.searchTerms.filter((term) =>
				dateSearchStore.dateFieldKeys.includes(term.property),
			);
			response.push(...dateSearch);
		}
		return response;
	}

	@computed
	get entitySearch(): IEntitySearchClause[] | undefined {
		return this.getEntitySearch();
	}

	getEntitySearch(excludeFilters: boolean = false): IEntitySearchClause[] | undefined {
		const { filtersStore } = this.storeContext;
		const entitySearch = this.replaceInputPlaceholders(
			this.appComponentConfiguration &&
			this.appComponentConfiguration.documentSearchRequest &&
			this.appComponentConfiguration.documentSearchRequest.entitySearch,
		) || [];
		if (!excludeFilters) {
			entitySearch.push(...filtersStore.entitySearchForFilters);
		}
		return entitySearch.length > 0 && entitySearch || undefined;
	}

	@computed
	get searchEntityRequest(): Omit<ISearchEntityRequest, 'start' | 'limit' | 'include'> | undefined {
		return this.replaceInputPlaceholders(
			this.appComponentConfiguration &&
			this.appComponentConfiguration.documentSearchRequest &&
			this.appComponentConfiguration.documentSearchRequest.searchEntityRequest);
	}

	@computed
	get identifierSearchOperator(): 'OR' | 'AND' | undefined {
		const operator = this.appComponentConfiguration &&
			this.appComponentConfiguration.documentSearchRequest &&
			this.appComponentConfiguration.documentSearchRequest.identifierSearchOperator;
		if (operator === 'OR' || operator === 'AND') {
			return operator;
		}
	}

	@computed
	get specialProperties(): IDocumentSearchClause[] {
		const { facetedSearchStore } = this.storeContext;
		const { searchTerms } = facetedSearchStore;
		return searchTerms.filter((term) => SPECIAL_SEARCH_PROPERTIES.includes(term.property));
	}

	applySpecialPropertiesToBody(body: any) {
		const specialProperties = this.specialProperties;
		specialProperties.forEach((specialProperty) => {
			const key = `${ specialProperty.property }s`;
			if (!(this.documentSearchRequest as any)[key]) {
				if (!body[key]) {
					body[key] = [];
				}
				body[key].push(...specialProperty.values);
			}
		});
	}

	@computed
	get searchDocumentsBody(): ISearchDocumentsRequestBody {
		const { pagingStore, tableStore, identifierSearchStore, translationStore } = this.storeContext;

		const body: any = {
			type: '',
			...this.documentSearchRequest,
			search: this.search,
			entitySearch: this.entitySearch,
			limit: pagingStore.limit,
			start: pagingStore.start,
			sort: [],
		};

		if (translationStore.baseCulture !== translationStore.culture) {
			body.translate = true;
			body.sourceCulture = translationStore.baseCulture;
			body.culture = translationStore.culture;
		}

		this.appendSort(body.sort, tableStore.groupingSort);
		this.appendSort(body.sort, this.sort);
		this.appendSort(body.sort, this.documentSearchRequest.sort);

		this.applySpecialPropertiesToBody(body);

		if (identifierSearchStore.hasSearch) {
			body.identifierSearches = identifierSearchStore.identifierSearches;
			body.identifierSearchOperator = this.identifierSearchOperator;
		}

		return body;
	}

	appendSort = (collection: any[], value: undefined | IDocumentSort | IDocumentSort[]) => {
		if (!utils.typeChecks.isNullOrUndefined(value)) {
			if (utils.isArrayLike.isArrayLike(value)) {
				collection.push(...value);
			} else {
				collection.push(value);
			}
		}
	}

	@action
	async fetchDocuments() {
		const { kurtosysApiStore, tableStore } = this.storeContext;
		const loadingKey = 'fetchDocuments';
		const requestKey = utils.guid.generateGuid();
		this.lastFetchDocumentsKey = requestKey;
		tableStore.startLoading(loadingKey);
		try {
			const documentResponse: IDocumentSearchResponse = await kurtosysApiStore.searchDocuments.execute({
				body: this.searchDocumentsBody,
			});
			if (this.lastFetchDocumentsKey === requestKey) {
				this.initialDocumentLoad = true;
				this.documents = documentResponse.values;
				this.total = documentResponse.total;
				this.documentEntities = documentResponse.entities;
				this.documentJoinMap = documentResponse.joinMap;
			}
		}
		catch (ex) {
			console.warn('Problem Fetching Documents:', ex);
		}
		tableStore.stopLoading(loadingKey);
	}

	replaceInputPlaceholders = (instance: any) => {
		if (!utils.typeChecks.isNullOrUndefined(instance)) {
			const cleanInstance = JSON.parse(JSON.stringify(instance));
			this.primativeWalk(cleanInstance, (instance: any, key: string | number, value: any) => {
				if (typeof value === 'string' && value.length > 0) {
					const cleanValue = value.split('{input:').join('{');
					const newValue = utils.replacePlaceholders({
						values: this.appParamsHelper.inputs || {},
						text: cleanValue,
						appParamsHelper: this.appParamsHelper,
					});
					instance[key] = newValue;
				}
			});
			return cleanInstance;
		}
		return instance;
	}

	primativeWalk = (instance: any, fn: (instance: any, key: string | number, value: any) => void) => {
		if (!utils.typeChecks.isNullOrUndefined(instance)) {
			if (Array.isArray(instance)) {
				for (let i = 0; i < instance.length; i++) {
					let value = instance[i];
					fn(instance, i, value);
					value = instance[i];
					this.primativeWalk(value, fn);
				}
			} else if (typeof instance === 'object') {
				const keys = Object.keys(instance);
				for (let i = 0; i < keys.length; i++) {
					const key = keys[i];
					let value = utils.get(instance, key);
					fn(instance, key, value);
					value = utils.get(instance, key);
					this.primativeWalk(value, fn);
				}
			}
		}
	}

	@computed
	get hasData(): boolean {
		const { tableStore } = this.storeContext;
		return tableStore.rows.length > 0;
	}

	@action
	contextsDidUpdateAfter = async () => {
		// TODO: Each Application should put custom logic here to handle changes to the data context
	}

	@computed
	get components(): IAppComponents {
		return {
			/* [Component: appStoreComponent] */
		};
	}

	getFilterParams = (query: string = '?') => {
		this.filterParams = query
			.slice(1)
			.split('&')
			.reduce((params: any, param) => {
				const [key, value] = param.split('=');
				if (key && key.startsWith('_')) {
					params[key.slice(1)] = value ? decodeURIComponent(value) : undefined;
				}
				return params;
			}, {});
	}

	@computed
	get filterSearch(): any[] {
		return Object.keys(this.filterParams).map((property: string) => {
			if (!DISALLOWED_FILTER_PARAMS.includes(property)) {
				const values = this.filterParams[property] ? this.filterParams[property].split('|') : [''];
				return {
					property,
					values,
					matchtype: 'MATCH',
				};
			}
		});
	}

	@computed
	get documentEntityKeys(): models.query.IQueryContextConfigurationDocumentEntityKey | undefined {
		return this.appComponentConfiguration && this.appComponentConfiguration.documentEntityKeys;
	}

	@computed
	get filename(): IQueryOptions {
		return (
			(this.appComponentConfiguration && this.appComponentConfiguration.filename) || {
				queryOptionsType: 'none',
				value: 'document_download',
			}
		);
	}

	saveDocument = async (payload: any): Promise<void> => {
		try {
			const parsedFilename = this.getQueryValue(this.filename, payload);
			const fileType = payload && payload.document && payload.document.fileType;
			const result: any = await this.getDocument(payload.clientCode, parsedFilename);

			let fallbackFilename = parsedFilename;
			if (!parsedFilename) {
				fallbackFilename = payload.title;
			}
			const savedFilename = this.saveFile(result, fallbackFilename, fileType);
			if (this.analyticsHelper) {
				this.logSaveDocumentEvent(payload, savedFilename);
			}
		}
		catch (e) {
			console.warn(e);
		}
	}

	/**
	 * Create an analytics log entry to record the fact that a document has been downloaded.
	 * NB this is only called (above) if this.analyticsHelper is defined
	 * @param row a row of the table corresponding to the document being saved
	 * @param filename the filename suggested for the download
	 */
	logSaveDocumentEvent = (row: any, filename: string): void => {
		const { tableStore } = this.storeContext;
		let url;
		if (tableStore.copyUrlQuery) {
			url = tableStore.getQueryValue(tableStore.copyUrlQuery, row, {
				context: {
					response: row,
					document: row.document,
				},
			});
		}
		this.analyticsHelper.logEvent({
			event: 'document_download',
			eventType: 'click',
			context: {
				filename,
				document: {
					documentURL: url,
					documentType: row.documentType,
					documentTitle: row.title,
				},
			},
		});
	}

	getFileNameExtension = (fileName: string): string => {
		// gets extension if it exists. Handles cases with no extension and files with '.' at the start of the name.
		// Updated to not include the "." this will align with the mime package getExtension
		const extension = fileName.slice(
			Math.max(0, fileName.lastIndexOf('.') + 1) || Infinity,
		);
		return extension;
	}

	getDocument = async (clientCode: string, parsedFilename: string) => {
		if (clientCode) {
			try {
				const { kurtosysApiStore } = this.storeContext;
				const queryString: any = {
					clientCode,
				};
				if (parsedFilename) {
					queryString.filename = parsedFilename;
				}
				const overrideOptions = {
					queryString,
					disableCachableRequests: true,
					method: 'GET' as TRequestMethod,
				};
				return await kurtosysApiStore.retrieveDocument.callApi(overrideOptions);
			}
			catch (e) {
				console.warn(e);
			}
		}
		return;
	}

	saveFile = (response: any, filename: string, fileType: string) => {
		// Note: that cross domain responses do not have all the headers (this includes different sub domains)
		// So in the case that disposition is not returned we will use the filename param above.
		// --
		// Additionally content-disposition usually contains the filename we would have passed to the retrieve url
		// and if undefined uses the actual filename of the document being downloaded.
		// Thus if all else fails, the filename above would contain the ksys document system property of title.
		const disposition = response.headers.map['content-disposition'];
		if (disposition) {
			filename = disposition.split(/;(.+)/)[1].split(/=(.+)/)[1];
			if (filename.toLowerCase().startsWith('utf-8""')) {
				filename = decodeURIComponent(filename.replace('utf-8""', ''));
			}
			else {
				filename = filename.replace(/['"]/g, '');
			}
		}

		let extension = this.getFileNameExtension(filename);
		if (!extension) {
			extension = getExtension(fileType) || '';
			filename += `.${ extension }`;
		}

		FileSaver.saveAs(response._bodyBlob, filename);
		return filename;
	}
	@computed
	get globalInputIdentifiers(): string[] {
		return (this.appComponentConfiguration && this.appComponentConfiguration.globalInputIdentifiers) || [];
	}
}
