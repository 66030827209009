import { TemplateStore } from '../components/App/stores/TemplateStore';
import { AppStore } from '../components/App/stores/AppStore';
import { KurtosysApiStore } from '../components/App/stores/KurtosysApiStore';
import { TranslationStore } from '../components/App/stores/TranslationStore';
import { QueryStore } from '../components/App/stores/QueryStore';
import { Manifest } from './Manifest';
import { TableStore } from '../components/Table/stores/TableStore';
import { FacetedSearchStore } from '../components/FacetedSearch/stores/FacetedSearchStore';
import { DateSearchStore } from '../components/DateSearch/stores/DateSearchStore';
import { DocumentPreviewStore } from '../components/DocumentPreview/stores/DocumentPreviewStore';
import { BulkDownloadStore } from '../components/BulkDownload/stores/BulkDownloadStore';
import { PagingStore } from '../components/Paging/stores/PagingStore';
import { SavedSearchStore } from '../components/SavedSearch/stores/SavedSearchStore';
import { FeedbackStore } from '../components/Feedback/stores/FeedbackStore';
import { AccessibilityStore } from '../components/App/stores/AccessibilityStore';
import { FiltersStore } from '../components/Filters/stores/FiltersStore';
import { FilterStore } from '../components/Filter/stores/FilterStore';
import { AccordionFilterStore } from '../components/AccordionFilter/stores/AccordionFilterStore';
import { ExportToCsvStore } from '../components/ExportToCsv/stores/ExportToCsvStore';
import { BulkUrlCopyStore } from '../components/BulkUrlCopy/stores/BulkUrlCopyStore';
import { IdentifierSearchStore } from '../components/IdentifierSearch/stores/IdentifierSearchStore';
import { ClearSearchAndFiltersStore } from '../components/ClearSearchAndFilters/stores/ClearSearchAndFiltersStore';
import { DateFilterStore } from '../components/DateFilter/stores/DateFilterStore';
import { SearchRowStore } from '../components/SearchRow/stores/SearchRowStore';
import { InputStore } from '../components/App/stores/InputsStore';
import { EventBusStore } from '../components/App/stores/EventBusStore';
/* [Component: storesImport] */

export class StoreContext {
	templateStore: TemplateStore;
	appStore: AppStore;
	kurtosysApiStore: KurtosysApiStore;
	translationStore: TranslationStore;
	queryStore: QueryStore;
	tableStore: TableStore;
	facetedSearchStore: FacetedSearchStore;
	dateSearchStore: DateSearchStore;
	documentPreviewStore: DocumentPreviewStore;
	bulkDownloadStore: BulkDownloadStore;
	pagingStore: PagingStore;
	savedSearchStore: SavedSearchStore;
	feedbackStore: FeedbackStore;
	accessibilityStore: AccessibilityStore;
	filtersStore: FiltersStore;
	filterStore: FilterStore;
	accordionFilterStore: AccordionFilterStore;
	exportToCsvStore: ExportToCsvStore;
	bulkUrlCopyStore: BulkUrlCopyStore;
	identifierSearchStore: IdentifierSearchStore;
	clearSearchAndFiltersStore: ClearSearchAndFiltersStore;
	dateFilterStore: DateFilterStore;
	searchRowStore: SearchRowStore;
	inputStore: InputStore;
	eventBusStore: EventBusStore;
	/* [Component: storeVariable] */
	constructor(element: HTMLElement, url: string, templateStore: TemplateStore, public manifest: Manifest) {
		this.templateStore = templateStore;
		this.kurtosysApiStore = new KurtosysApiStore(this);
		this.translationStore = new TranslationStore(this);
		this.queryStore = new QueryStore(this);
		this.inputStore = new InputStore(this);
		this.eventBusStore = new EventBusStore(this);
		this.appStore = new AppStore(element, url, this, manifest);
		this.tableStore = new TableStore(this);
		this.facetedSearchStore = new FacetedSearchStore(this);
		this.dateSearchStore = new DateSearchStore(this);
		this.documentPreviewStore = new DocumentPreviewStore(this);
		this.bulkDownloadStore = new BulkDownloadStore(this);
		this.pagingStore = new PagingStore(this);
		this.savedSearchStore = new SavedSearchStore(this);
		this.feedbackStore = new FeedbackStore(this);
		this.accessibilityStore = new AccessibilityStore(this);
		this.filtersStore = new FiltersStore(this);
		this.filterStore = new FilterStore(this);
		this.accordionFilterStore = new AccordionFilterStore(this);
		this.exportToCsvStore = new ExportToCsvStore(this);
		this.bulkUrlCopyStore = new BulkUrlCopyStore(this);
		this.identifierSearchStore = new IdentifierSearchStore(this);
		this.clearSearchAndFiltersStore = new ClearSearchAndFiltersStore(this);
		this.dateFilterStore = new DateFilterStore(this);
		this.searchRowStore = new SearchRowStore(this);
		/* [Component: storeAssignment] */
		this.initialize();
	}
	initialize() {
		this.appStore.initialize();
	}
	reInitialize() {
		this.appStore.reInitialize();
	}
	get providerProps() {
		return {
			appStore: this.appStore,
			kurtosysApiStore: this.kurtosysApiStore,
			translationStore: this.translationStore,
			queryStore: this.queryStore,
			tableStore: this.tableStore,
			facetedSearchStore: this.facetedSearchStore,
			dateSearchStore: this.dateSearchStore,
			documentPreviewStore: this.documentPreviewStore,
			bulkDownloadStore: this.bulkDownloadStore,
			pagingStore: this.pagingStore,
			savedSearchStore: this.savedSearchStore,
			feedbackStore: this.feedbackStore,
			accessibilityStore: this.accessibilityStore,
			filtersStore: this.filtersStore,
			filterStore: this.filterStore,
			accordionFilterStore: this.accordionFilterStore,
			exportToCsvStore: this.exportToCsvStore,
			bulkUrlCopyStore: this.bulkUrlCopyStore,
			identifierSearchStore: this.identifierSearchStore,
			clearSearchAndFiltersStore: this.clearSearchAndFiltersStore,
			dateFilterStore: this.dateFilterStore,
			searchRowStore: this.searchRowStore,
			inputStore: this.inputStore,
			eventBusStore: this.eventBusStore,
			/* [Component: storeProviderProps] */
		};
	}

	// TODO: NEED TO MERGE STORES
	registerStore(key: string, store: any) {}
}