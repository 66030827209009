export const nodeName = 'ksys-dialog-placeholder';
if (customElements.get(nodeName) === undefined) {
	class KsysDialogPlaceholder extends HTMLElement {}
	customElements.define(nodeName, KsysDialogPlaceholder);
}
export function injectDialogPlaceholder(id: string) {
	if (!document.querySelector(`${nodeName}#${ id }`)) {
		const el = document.createElement(nodeName);
		el.id = id;
		document.body.prepend(el);
	}
}
