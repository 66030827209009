import { IIconProps } from '@kurtosys/ksys-app-components/dist/components/base/Icon/models';
import { computed, action, observable } from 'mobx';
import { StoreBase } from '../../../common/StoreBase';
import { IDateFilterConfiguration } from '../models';
import { utils } from '@kurtosys/ksys-app-template';
import { IDateRangeInputProps } from '@kurtosys/ksys-app-components/dist/components/base/DateRangeInput/models';
import { IDropdownItem } from '@kurtosys/ksys-app-components/dist/components/base/Dropdown/models';
import { IActionsProps } from '@kurtosys/ksys-app-components/dist/components/base/Actions/models';
import { IDocumentSearchClause } from '../../../models/commonTypes';
import { Dropdown } from '@kurtosys/ksys-app-components/dist/components/base/Dropdown/Dropdown';

const { isNullOrEmpty, isNullOrUndefined } = utils.typeChecks;

export class DateFilterStore extends StoreBase {
	static componentKey: 'dateFilter' = 'dateFilter';
	@observable currentCode: string = '';
	@observable selectedDate: {
		[key: string]: { startDate: string; endDate: string };
	} = {};
	@observable showWindow: boolean = false;

	@computed
	get configuration(): IDateFilterConfiguration | undefined {
		if (this.storeContext && this.storeContext.appStore) {
			return this.storeContext.appStore.getComponentConfiguration(DateFilterStore.componentKey);
		}
	}

	@computed
	get iconProps(): IIconProps {
		return (
			(this.configuration && this.configuration.iconProps) || {
				asset: 'base.dateDropdown.calendar',
			}
		);
	}

	@computed
	get closeButtonText(): string {
		return (this.configuration && this.configuration.closeButtonText) || 'Close';
	}

	@computed
	get submitButtonText(): string {
		return (this.configuration && this.configuration.submitButtonText) || 'Add Date';
	}

	@computed
	get hide(): boolean {
		return !!(this.configuration && this.configuration.hide);
	}

	@computed
	get metaFields(): string[] {
		return (this.configuration && this.configuration.metaFields) || [];
	}

	@action
	async initialize(): Promise<void> {}

	toggleWindow = (code?: string) => {
		if (code) {
			if (code === this.currentCode) {
				this.currentCode = '';
			} else {
				this.currentCode = code;
			}
		} else {
			this.currentCode = '';
		}
	};

	@computed
	get dateRangeInputProps(): IDateRangeInputProps {
		const configProps = (this.configuration && this.configuration.dateRangeInputProps) || {};
		return {
			...configProps,
			onChange: this.onChange,
			actionsProps: this.actionsProps,
			startDate:
				(this.selectedDate &&
					this.selectedDate[this.currentCode] &&
					this.selectedDate[this.currentCode].startDate) ||
				'',
			endDate:
				(this.selectedDate &&
					this.selectedDate[this.currentCode] &&
					this.selectedDate[this.currentCode].endDate) ||
				'',
		};
	}

	@action
	onSubmit = () => {
		const { filtersStore } = this.storeContext;
		if (filtersStore && this.selectedDate[this.currentCode]) {
			filtersStore.setSelectedDateFilters(this.currentCode, this.selectedDate[this.currentCode]);
		}
		this.onClose();
	};

	@action
	onClose = () => {
		this.toggleWindow();
	};

	@computed
	get submitButtonDisabled() {
		return (
			utils.typeChecks.isNullOrEmpty(
				this.selectedDate &&
					this.selectedDate[this.currentCode] &&
					this.selectedDate[this.currentCode].startDate,
			) ||
			utils.typeChecks.isNullOrEmpty(
				this.selectedDate && this.selectedDate[this.currentCode] && this.selectedDate[this.currentCode].endDate,
			)
		);
	}

	@computed
	get actionsProps(): IActionsProps {
		return {
			buttons: [
				{
					onClick: this.onSubmit,
					text: this.submitButtonText,
					className: 'submit-button',
					disabled: this.submitButtonDisabled,
				},
				{
					onClick: this.onClose,
					text: this.closeButtonText,
					className: 'close-button',
				},
			],
		};
	}

	@action
	onChange = (startDate?: string, endDate?: string, dateField?: IDropdownItem) => {
		if (this.currentCode !== '') {
			this.selectedDate[this.currentCode] = { startDate: startDate || '', endDate: endDate || '' };
			// rebuild this.selectedDate so the comnputed will see the changes
			this.selectedDate = { ...this.selectedDate };
		}
	};
}
