import React, { Component } from 'react';
import { ITableProps, ITableState } from './models';
import { inject, observer } from 'mobx-react';
import { InjectedStyledComponent } from '../shared/InjectedStyledComponent';
import Wrapper from './styledComponents/Wrapper';
import TableGrouping from '@kurtosys/ksys-app-components/dist/components/base/TableGrouping';
import Loading from '../shared/Loading';

@inject('appStore', 'tableStore')
@observer
export class Table extends Component<ITableProps, ITableState> {
	static configurationKey: 'table' = 'table';
	static styleKey: 'table' = 'table';
	constructor(props: ITableProps) {
		super(props);
	}

	render() {
		const { className, tableStore, appStore } = this.props;
		if (!tableStore) {
			return null;
		}
		const { getStickyOffset, showFilters } = tableStore;
		// We dont explicitly remove the TableGrouping so that it keeps state
		const style: React.CSSProperties = {};
		if (tableStore.showLoading) {
			style.display = 'none';
		}

		const stickyProps = appStore && appStore.appComponentConfiguration && appStore.appComponentConfiguration.stickyProps || {};
		const { searchFiltersExpanded = {} } = stickyProps;
		const { enabled, offset } = searchFiltersExpanded;
		const expandedOffset = enabled ? offset || 0 : 0;
		const { group, tableHeader } = stickyProps;
		const groupZIndex = group && group.zIndex || undefined;
		const headerZIndex = tableHeader && tableHeader.zIndex || undefined;

		const groupStickyOffset = getStickyOffset(expandedOffset, group, showFilters);
		const tableHeaderStickyOffset = getStickyOffset(expandedOffset, tableHeader, showFilters);

		const tableGroupingProps = {
			...tableStore.tableGroupingProps,
			tableProps: {
				...tableStore.tableGroupingProps.tableProps,
				stickyProps: {
					groupZIndex,
					headerZIndex,
					groupTop: groupStickyOffset,
					headerTop: tableHeaderStickyOffset,
				},
			},
		};

		return (
			<Wrapper className={ className }>
				{ tableStore.showLoading && <Loading /> }
				< div style={ style } >
					<TableGrouping suppressAnalytics={ true } { ...tableGroupingProps } />
				</div>
			</Wrapper>
		);
	}
}

export default InjectedStyledComponent(Table, 'table');
