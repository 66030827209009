import { utils } from '@kurtosys/ksys-app-template';
import { IBreakpointProps } from '@kurtosys/ksys-app-components/dist/models/IBreakpointProps';
import { TBreakpointSize } from '@kurtosys/ksys-app-components/dist/models/TBreakpointSize';
import { useBreakpointValue } from './useBreakpointValue';
import { StoreContext } from '../../configuration/StoreContext';
import { action, computed } from 'mobx';
import { ILogOptions, TLogType } from '../../utils/log';

/**
 * Considering the following [valid/invalid] scenarios for breakpoint configuration,
 * we should handle warnings & errors accordingly:
 *
 * [valid] XS UP
 * (XS>) ... sm    ... m    ... l ... xl
 * 
 * [valid] XL DOWN
 * xs    ... sm    ... m    ... l ... (<XL)
 * 
 * [valid] XS UP & M UP
 * (XS>) ... sm    ... (M>) ... l ... xl    
 * 
 * [valid] XS UP & XL DOWN: Modifiers clash, resulting in a warning
 * (XS>) ... sm    ... m    ... l ... (<XL)
 * 
 * [invalid] XS ONLY & M UP: No config applied for SM, if no base is supplied this will result in an error
 * (XS-) ... sm    ... (M>) ... l ... xl
 * 
 * [invalid] M DOWN: No config applied for L & XL, if no base is supplied this will result in an error
 * xs    ... sm    ... (<M) ... l ... xl
 *
 * @export
 * @class ResponsiveConfig
 * @template TConfiguration
 */
export class ResponsiveConfig<TConfiguration extends Record<string, any>> {
	private static breakpointOrder: TBreakpointSize[] = ['xsmall', 'small', 'medium', 'large', 'xlarge'];
	private appliedBreakpoints: Map<TBreakpointSize, TConfiguration> = new Map();
	private log: (type: TLogType, options: ILogOptions) => void;

	private get additionalContext() {
		return `Responsive${ this.additionalLogContext ? `.${ this.additionalLogContext }` : '.UnknownConfig' }`;
	}

	constructor(
		private storeContext: StoreContext,
		private base?: TConfiguration,
		private breakpoints?: IBreakpointProps<TConfiguration>[],
		private additionalLogContext?: string,
	) {
		this.log = this.storeContext.appStore.log;
		this.log('debug', {
			additionalContext: this.additionalContext,
			message: 'init',
		});
		this.orchestrateBreakpointConfigurations();
	}

	/**
	 * Returns active breakpoint configuration from the list of orchestrated breakpoint configurations
	 * Note: this method should compute based on the useBreakpointValue which uses the MediaQuery & Breakpoints classes
	 *
	 * @readonly
	 */
	@computed
	get activeConfiguration(): TConfiguration {
		if (this.breakpoints && this.breakpoints.length > 0) {
			const appliedValues = ResponsiveConfig.breakpointOrder.reduce((values, size) => {
				if (!this.appliedBreakpoints.has(size)) {
					if (!this.base) {
						this.log('error', {
							additionalContext: this.additionalContext,
							message: `Breakpoint Configuration Missing for "${ size }"`,
						});
					}
					else {
						values[size] = this.base;
					}
				}
				else {
					values[size] = this.appliedBreakpoints.get(size);
				}
				return values;
			}, {} as Record<TBreakpointSize, any>);
			return useBreakpointValue(appliedValues);
		}
		return this.base || {} as TConfiguration;
	}

	/**
	 * The method, fired by the constructor, should only execute one, 
	 * and the getter activeConfiguration will work with the results from there.
	 *
	 * @private
	 */
	@action
	private orchestrateBreakpointConfigurations() {
		if (this.breakpoints && this.breakpoints.length > 0) {
			ResponsiveConfig.breakpointOrder.forEach((breakpointSize, breakpointSizeIndex) => {
				if (!this.appliedBreakpoints.has(breakpointSize)) {
					const breakpoint = this.getBreakpoint(breakpointSize);
					if (breakpoint) {
						const modifier = breakpoint.modifier;
						const mergedBreakpointConfig = this.mergeBreakpointOverBase(breakpoint);
						this.appliedBreakpoints.set(breakpointSize, mergedBreakpointConfig);
						this.log('debug', {
							additionalContext: this.additionalContext,
							message: `apply ${ breakpointSize } config`,
							detail: {
								breakpointSize,
								modifier,
								mergedBreakpointConfig,
								breakpointSizeIndex,
								orderLength: ResponsiveConfig.breakpointOrder.length,
							},
						});

						if (modifier === 'up' && breakpointSizeIndex < (ResponsiveConfig.breakpointOrder.length - 1)) {
							this.log('debug', {
								additionalContext: this.additionalContext,
								message: `Identify what higher sizes to apply ${ breakpointSize } config`,
							});
							/**
							 * Considering that breakpoint config may only be supplied for a XS UP,
							 * We need to loop forward applying config, unless there is another breakpoint config that will need to be applied
							 */
							for (let i = breakpointSizeIndex + 1; i < ResponsiveConfig.breakpointOrder.length; i++) {
								const nextBreakpointSize = ResponsiveConfig.breakpointOrder[i];
								// confirm if there is config to be applied to the next breakpoint size.
								const nextBreakpoint = this.getBreakpoint(nextBreakpointSize);
								this.log('debug', {
									additionalContext: this.additionalContext,
									message: `Next Breakpoint`,
									detail: {
										nextBreakpointSize,
										nextBreakpoint,
									},
								});
								if (nextBreakpoint) {
									if (nextBreakpoint.modifier === 'down') {
										this.log('warning', {
											additionalContext: this.additionalContext,
											message: `Breakpoint Clash: ${ breakpoint.size } ${ breakpoint.modifier } - ${ nextBreakpoint.size } ${ nextBreakpoint.modifier }`,
										});
									}
									break;
								}
								// otherwise apply the previous breakpoint config.
								this.appliedBreakpoints.set(nextBreakpointSize, mergedBreakpointConfig);
								this.log('debug', {
									additionalContext: this.additionalContext,
									message: `apply ${ breakpointSize } config to ${ nextBreakpointSize }`,
									detail: mergedBreakpointConfig,
								});
							}
						}
						else if (modifier === 'down' && breakpointSizeIndex > 0) {
							this.log('debug', {
								additionalContext: this.additionalContext,
								message: `Identify what lower sizes to apply ${ breakpointSize } config`,
							});
							/** 
							 * considering that breakpoint config may only be supplied for a XL DOWN,
							 * We need to loop backward applying config, unless there is another breakpoint config applied for the target breakpointSize
							 */
							for (let i = breakpointSizeIndex - 1; i >= 0; i--) {
								const prevBreakpointSize = ResponsiveConfig.breakpointOrder[i];
								if (this.appliedBreakpoints.has(prevBreakpointSize)) {
									this.log('debug', {
										additionalContext: this.additionalContext,
										message: `Prev Breakpoint`,
										detail: {
											prevSize: prevBreakpointSize,
											prevBreakpoint: this.appliedBreakpoints.get(prevBreakpointSize),
										},
									});
									break;
								}
								this.appliedBreakpoints.set(prevBreakpointSize, mergedBreakpointConfig);
								this.log('debug', {
									additionalContext: this.additionalContext,
									message: `apply ${ breakpointSize } config to ${ prevBreakpointSize }`,
									detail: mergedBreakpointConfig,
								});
							}
						}
					}
				}
			});
		}
	}

	private getBreakpoint(size: TBreakpointSize) {
		if (!this.breakpoints) {
			return;
		}
		return this.breakpoints.find(bp => bp.size === size);
	}

	private mergeBreakpointOverBase(breakpoint: IBreakpointProps<TConfiguration>): TConfiguration {
		if (!breakpoint.props) {
			return this.base || {} as TConfiguration;
		}
		if (!this.base) {
			return breakpoint.props;
		}
		return utils.object.deepMergeObjectsWithOptions({ arrayMergeStrategy: 'DeepCopy' }, this.base, breakpoint.props);
	}
}
